import { ValuePublisherStatic, } from '@corti/lib/graphs';
import { Observer } from '@corti/observer';
import { CollectorModel } from '../../models/CollectorModel';
import { BlockValueUtils } from '../BlockValueStore';
import { getCollectorNamesFromCustomProperies, getFactIDsFromCustomProperies } from './utils';
export class CollectorStore {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_collectors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._collectors.forEach((it) => it.unsubscriber());
                this._collectors = new Map();
                this.flowStore.graphTraverser.getBlocksByType('FLOW_VALUE_COLLECTOR').forEach((block) => {
                    this.createCollector(block);
                });
            }
        });
        Object.defineProperty(this, "createCollector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockInstance) => {
                const model = new CollectorModel(blockInstance, this.flowStore, {});
                const unsubscriber = model.onCollectorValuesUpdated((col) => {
                    this.observer.fireEvent('collector-values-updated', col);
                });
                this._collectors.set(blockInstance.id, {
                    model,
                    unsubscriber,
                });
            }
        });
        // When critical path changes we want to recalculate values
        // of those collectors that are configured to collect values from critical path only.
        Object.defineProperty(this, "handleActivePathChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (path) => {
                // Find all critical path collectors
                const criticalPathCollectors = this.collectors.filter((col) => col.blockInstance.blockPrototype.collectFromCriticalPath);
                // Mute all collectors
                criticalPathCollectors.forEach((col) => col.mute());
                // Reset all collector to custom and fact values only
                criticalPathCollectors.forEach((col) => col.resetCollectedItems(col.items.filter((i) => i.valueType === 'custom' || i.valueType === 'fact')));
                // Change of business logic 2023.12.04.
                // Team decided that we want to combine critical path values together with pinned step and
                // favorite step values.
                const allSteps = [...path, ...this.flowStore.stepStore.pinnedSteps];
                const stepsWithId = allSteps.map((s) => [s.id, s]);
                const stepsById = new Map(stepsWithId);
                const { favoriteStep } = this.flowStore.stepStore;
                if (favoriteStep) {
                    stepsById.set(favoriteStep.id, favoriteStep);
                }
                const uniqueSteps = [...stepsById.values()];
                // For each unique step, collect values from blocks, but set criticalPathOnly to true
                uniqueSteps.forEach((s) => {
                    s.node.view.blocks.forEach((block) => {
                        const { blockPrototype } = block;
                        const blockIds = blockPrototype.type === 'SELECT'
                            ? blockPrototype.options.map(({ id }) => id)
                            : [blockPrototype.id];
                        const blockValues = blockIds
                            .map((id) => this.flowStore.blockValueStore.getByID(id))
                            .filter((blockValue) => Boolean(blockValue))
                            .filter((blockValue) => BlockValueUtils.isTruthy(blockValue));
                        this.collectBlockValue(blockPrototype.id, blockValues, true);
                    });
                });
                // Unmute all collectors, which will trigger values update if changed
                criticalPathCollectors.forEach((col) => col.unmute());
            }
        });
        Object.defineProperty(this, "collectBlockValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockPrototypeID, blockValues, criticalPathOnly = false) => {
                const collectorsForBlock = this.getCollectorsForBlockProtoID(blockPrototypeID);
                const filterCriticalPath = (col) => criticalPathOnly ? col.blockInstance.blockPrototype.collectFromCriticalPath : true;
                blockValues.forEach((val) => {
                    const { optionID } = val.identifier;
                    if (optionID != undefined) {
                        const collectorsForOption = this.getCollectorsForOptionID(optionID)
                            // we don't want to collect the same value in the same collector twice
                            .filter((col) => !collectorsForBlock.includes(col));
                        collectorsForOption
                            .filter(filterCriticalPath)
                            .forEach((col) => col.collectBlockOptionValue(optionID, val));
                    }
                });
                collectorsForBlock
                    .filter(filterCriticalPath)
                    .forEach((col) => col.collectBlockValues(blockPrototypeID, blockValues));
            }
        });
        Object.defineProperty(this, "handleBlockValuesUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                if (input.blockValues.length === 0)
                    return;
                const blockPrototypeID = input.blockValues[0].identifier.blockPrototypeID;
                this.collectBlockValue(blockPrototypeID, input.blockValues);
            }
        });
        Object.defineProperty(this, "handleFactValuesUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                input.factValues.forEach((val) => {
                    this.getCollectorsForFactID(val.factID).forEach((col) => {
                        col.collectFactValue(val);
                    });
                });
            }
        });
        Object.defineProperty(this, "getCollectorsForFactID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (factID) => {
                const fact = this.flowStore.graphTraverser.getFactByID(factID);
                if (!fact) {
                    return [];
                }
                // ignore old way if new way is configured and used
                if (fact.valuePublisherConfig.collectors.length !== 0) {
                    return fact.valuePublisherConfig.collectors
                        .flatMap((c) => this.getCollectorInstancesByCollectorProtoID(c.prototypeID))
                        .filter((c) => c != null);
                }
                // fallback to old way
                return this.collectors.filter((col) => {
                    return getFactIDsFromCustomProperies(col.blockInstance.blockPrototype.customProperties).includes(factID);
                });
            }
        });
        Object.defineProperty(this, "getCollectorsForBlockProtoID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockProtoID) => {
                const block = this.flowStore.graphTraverser.getBlockProtoByID(blockProtoID);
                if (!block)
                    return [];
                // new way of doing things is configured and used
                // ignore custom property configuration completely
                if (ValuePublisherStatic.isValuePublisher(block) &&
                    block.valuePublisherConfig.collectors.length !== 0) {
                    return block.valuePublisherConfig.collectors
                        .flatMap((c) => this.getCollectorInstancesByCollectorProtoID(c.prototypeID))
                        .filter((c) => c != null);
                }
                // fallback to old way
                return getCollectorNamesFromCustomProperies(block.customProperties).flatMap((name) => {
                    return this.getCollectorsByName(name);
                });
            }
        });
        Object.defineProperty(this, "getCollectorsForOptionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (optionID) => {
                const option = this.flowStore.graphTraverser.getOptionByID(optionID);
                if (!option)
                    return [];
                // new way of doing things is configured and used
                // ignore custom property configuration completely
                if (option.valuePublisherConfig.collectors.length !== 0) {
                    return option.valuePublisherConfig.collectors
                        .flatMap((c) => this.getCollectorInstancesByCollectorProtoID(c.prototypeID))
                        .filter((c) => c != null);
                }
                // fallback to old way
                return getCollectorNamesFromCustomProperies(option.customProperties).flatMap((name) => {
                    return this.getCollectorsByName(name);
                });
            }
        });
        Object.defineProperty(this, "getCollectorsByName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (collectorName) => {
                return this.collectors.filter((c) => c.blockInstance.blockPrototype.name === collectorName);
            }
        });
        Object.defineProperty(this, "getCollectorInstanceByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (collectorID) => {
                var _a;
                return (_a = this._collectors.get(collectorID)) === null || _a === void 0 ? void 0 : _a.model;
            }
        });
        Object.defineProperty(this, "getCollectorInstancesByCollectorProtoID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (collectorProtoID) => {
                return [...this._collectors.values()]
                    .filter((it) => it.model.blockInstance.blockPrototype.id === collectorProtoID)
                    .map((it) => it.model);
            }
        });
        Object.defineProperty(this, "onCollectorValuesUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('collector-values-updated', cb);
            }
        });
        this.observer = new Observer();
        this._collectors = new Map();
        this.flowStore.blockValueStore.onUpdate(this.handleBlockValuesUpdated);
        this.flowStore.factValueStore.onUpdate(this.handleFactValuesUpdated);
        this.flowStore.stepStore.onActivePathChanged(this.handleActivePathChanged);
        this.resetAll();
    }
    get collectors() {
        return Array.from(this._collectors.values()).map((it) => it.model);
    }
}
