import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'browser/containers';
import { observer } from 'browser/stores';
import { authStore } from 'core/auth';
import { CaseView } from '../CaseView';
import { CaseView as CaseViewOld } from './CaseView';
import { CasesView } from './CasesView';
import { ExploreViewContext } from './ExploreViewContext';
import { getCaseFilterFromSearchParams } from './utils/caseFilterSearchParams';
export const ExploreView = observer(function ExploreView({ location, match, store, screenRecorderAPI, }) {
    useEffect(() => {
        const { casesViewState } = store;
        const searchFilters = getCaseFilterFromSearchParams(location.search);
        void casesViewState.loadCaseFilters();
        void casesViewState.loadCasesFilterDescriptor();
        if (!searchFilters && !casesViewState.cases.length) {
            if (!casesViewState.casesTableEditorState.tableColumns.length) {
                casesViewState.casesTableEditorState.onTableColumnsLoaded(casesViewState.loadCases);
            }
            else {
                void casesViewState.loadCases();
            }
        }
        if (searchFilters) {
            if (casesViewState.casesFilterDescriptor) {
                casesViewState.setAdvancedFilter(searchFilters);
                void casesViewState.loadCases();
            }
            else {
                casesViewState.onCasesFilterDescriptorLoaded(() => {
                    casesViewState.setAdvancedFilter(searchFilters);
                    void casesViewState.loadCases();
                });
            }
        }
    }, [location, store]);
    const refreshCasesList = () => {
        void store.casesViewState.loadCases();
        store.casesViewState.saveCasesTableScrollPosition(0);
    };
    return (React.createElement(ExploreViewContext.Provider, { value: {
            refreshCasesList,
            setActiveExploreView: (view) => store.setActiveView(view),
            addCaseToVisitedCases: (id) => store.casesViewState.setCaseAsVisited(id),
            screenRecorderAPI: screenRecorderAPI,
        } },
        React.createElement(Switch, null,
            React.createElement(Route, { path: match.path, exact: true, render: (props) => React.createElement(CasesView, Object.assign({ casesViewState: store.casesViewState }, props)) }),
            React.createElement(Route, { path: `${match.path}/:caseID`, exact: true, render: (props) => authStore.isFeatureEnabled('mission-control-case-view') ? (React.createElement(CaseView, null)) : (React.createElement(CaseViewOld, Object.assign({ caseViewState: store.caseViewState, basePath: match.path }, props))), breadcrumb: ({ match }) => {
                    return `${match.params.caseID.substring(0, 8).toLocaleUpperCase()}`;
                } }))));
});
