import React from 'react';
import { Base } from 'lib/cortiUI';
import { Audio } from './Audio';
import { CallControlProvider } from './CallControlsContext';
import { Transcripts } from './Transcripts';
export const Call = ({ callID, callServiceCallId }) => {
    return (React.createElement(CallControlProvider, null,
        React.createElement(Base, { display: "grid", gridTemplateRows: "1fr auto", overflow: "hidden", gap: 4 },
            React.createElement(Transcripts, { callID: callServiceCallId }),
            React.createElement(Audio, { callID: callID }))));
};
