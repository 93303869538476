import { useLazyQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { orderBy } from 'lodash';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { useDelayedQuery } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Button, Container, DefaultCellRenderer, DefaultRowRenderer, DefaultValueRenderer, Dialog, FallbackView, IconButton, LinearProgress, Menu, MenuItem, Page, PageHeader, PageHeaderActionsSection, PageHeaderDescription, PageHeaderTitle, State, Table, TableColumn, } from 'lib/cortiUI';
import { CreateTeamView, UpdateTeamView } from './CreateUpdateTeamView';
import { DeleteTeamView } from './DeleteTeamView';
import * as GQL from './graphql';
const TABLE_COL_NAME = 'name';
const TABLE_COL_MEMBER_COUNT = 'memberCount';
const TABLE_COL_ACTIONS = 'columnActions';
export function TeamsView() {
    var _a, _b, _c, _d;
    const { t } = useTranslation('teams');
    const { organization } = useAuth();
    const theme = useTheme();
    const getTeamsQuery = useDelayedQuery(GQL.getTeamsQuery, {
        variables: {
            orgID: organization.id,
        },
    });
    const teams = (_b = (_a = getTeamsQuery.data) === null || _a === void 0 ? void 0 : _a.teams) !== null && _b !== void 0 ? _b : [];
    return (React.createElement(Page, { "data-cy": "teams-view" },
        React.createElement(Container, null,
            React.createElement(PageHeader, null,
                React.createElement(PageHeaderActionsSection, null,
                    React.createElement(State, { initialState: false }, ([state, setState]) => {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Button, { variant: "text", sx: { color: theme.palette.text.primary }, onClick: () => setState(true), disabled: !rbacService.teamsCRUD(), "data-cy": "create-new-team-btn", startIcon: React.createElement(AddRoundedIcon, { fontSize: "small" }) }, t('teamsView.createTeamBtn', 'New Team')),
                            React.createElement(Dialog, { open: state, onClose: () => setState(false), title: t('createTeamView.title', 'Create New Team'), helperText: t('teamForm.headerDescription', 'Chose a name that reflects your company or team hierarchy. We recommend you use the slash separated convention e.g. parent team/child team to reflect relations or levels of nestings.') },
                                React.createElement(CreateTeamView, { onSuccess: () => {
                                        setState(false);
                                        void getTeamsQuery.refetch();
                                    } }))));
                    })),
                React.createElement(PageHeaderTitle, null, t('teamsView.title', 'Teams')),
                React.createElement(PageHeaderDescription, null, t('teamsView.description', 'Organize your users into teams for organizational, reporting, and partitioning purposes.')))),
        getTeamsQuery.delayedLoading && (React.createElement(LinearProgress, { variant: "indeterminate", style: { position: 'fixed', top: 0, left: 0, right: 0 } })),
        ((_c = getTeamsQuery.data) === null || _c === void 0 ? void 0 : _c.teams.length) !== 0 ? (React.createElement(Container, null,
            React.createElement(TeamsTable, { teams: teams }))) : ((_d = getTeamsQuery.data) === null || _d === void 0 ? void 0 : _d.teams.length) === 0 ? (React.createElement(State, { initialState: false }, ([state, setState]) => (React.createElement(React.Fragment, null,
            React.createElement(FallbackView, { "data-cy": "no-teams-fallback-view", flexGrow: 1, title: t('teamsView.fallbackView.title', 'No teams created'), text: t('teamsView.fallbackView.text', 'Organize your users into teams for organizational, reporting, and partitioning purposes.'), actionText: rbacService.teamsCRUD()
                    ? t('teamsView.fallbackView.actionBtn', 'Create team')
                    : undefined, action: rbacService.teamsCRUD() ? () => setState(true) : undefined }),
            rbacService.teamsCRUD() && (React.createElement(Dialog, { open: state, onClose: () => setState(false), title: t('createTeamView.title', 'Create New Team'), helperText: t('teamForm.headerDescription', 'Chose a name that reflects your company or team hierarchy. We recommend you use the slash separated convention e.g. parent team/child team to reflect relations or levels of nestings.') },
                React.createElement(CreateTeamView, { onSuccess: () => {
                        setState(false);
                        void getTeamsQuery.refetch();
                    } }))))))) : null));
}
function TeamsTable(props) {
    const { teams } = props;
    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation('teams');
    const columns = [
        { id: TABLE_COL_NAME, label: t('teamsView.table.name', 'Name') },
        { id: TABLE_COL_MEMBER_COUNT, label: t('teamsView.table.memberCount', 'Call-Takers') },
        { id: TABLE_COL_ACTIONS, label: '' },
    ];
    const rowData = orderBy(teams.map((team) => ({
        [TABLE_COL_NAME]: team.name,
        [TABLE_COL_MEMBER_COUNT]: team.members.length,
        __team: team,
    })), 'name', 'asc');
    return (React.createElement(Table, { rows: rowData, height: Infinity, columns: columns, disableSort: true, disableResize: true, rowRenderer: (p) => (React.createElement(DefaultRowRenderer, { "data-cy": "teams-table-row", key: p.index, requiredProps: p, onClick: () => {
                const row = p.rowData;
                history.push(`${match.path}/${row.__team.id}`);
            } })) }, ({ columns }) => {
        return columns.map((col, idx) => {
            return (React.createElement(TableColumn, { key: idx, label: col.label, dataKey: col.id, flexGrow: col.id === TABLE_COL_ACTIONS ? 0 : 1, width: 100, cellRenderer: (p) => {
                    return (React.createElement(DefaultCellRenderer, { requiredProps: p, valueRenderer: (v) => {
                            if (col.id === TABLE_COL_ACTIONS) {
                                if (rbacService.teamsCRUD()) {
                                    const rowData = p.rowData;
                                    return React.createElement(ActionsCellValueRenderer, { team: rowData.__team });
                                }
                                else {
                                    return null;
                                }
                            }
                            return React.createElement(DefaultValueRenderer, { value: v });
                        } }));
                } }));
        });
    }));
}
function ActionsCellValueRenderer(props) {
    const { team } = props;
    const { t } = useTranslation('teams');
    const { organization } = useAuth();
    const [getTeamsQuery] = useLazyQuery(GQL.getTeamsQuery, {
        variables: {
            orgID: organization.id,
        },
    });
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const [editTeamModalOpen, setEditTeamModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { "data-cy": "teams-table-row-action-btn", className: css({ marginLeft: 'auto' }), icon: React.createElement(MoreHorizRoundedIcon, null), onClick: (e) => {
                e.stopPropagation();
                setMenuAnchor(e.currentTarget);
            } }),
        React.createElement(Menu, { open: Boolean(menuAnchor), anchorEl: menuAnchor, onClose: () => setMenuAnchor(null) },
            React.createElement(MenuItem, { "data-cy": "edit-team-btn", onClick: (e) => {
                    e.stopPropagation();
                    setEditTeamModalOpen(true);
                    setMenuAnchor(null);
                } }, t('teamsView.table.row.edit', 'Edit')),
            React.createElement(MenuItem, { "data-cy": "delete-team-btn", onClick: (e) => {
                    e.stopPropagation();
                    setDeleteModalOpen(true);
                    setMenuAnchor(null);
                } }, t('teamsView.table.row.delete', 'Delete'))),
        React.createElement(Dialog, { title: t('updateTeamView.title', 'Edit Team'), open: editTeamModalOpen, onClose: (e) => {
                // prevents event from bubbling up outside from the Portal to the Row
                e.stopPropagation();
                setEditTeamModalOpen(false);
            } },
            React.createElement(UpdateTeamView, { teamID: team.id, onSuccess: () => {
                    setEditTeamModalOpen(false);
                    getTeamsQuery();
                } })),
        React.createElement(Dialog, { title: t('deleteTeamModal.title', 'Delete Team'), helperText: `${t('deleteTeamModal.message', 'Are you sure you want to delete this team?')} ${t('deleteTeamModal.hint', 'This action will permanently remove the team from your organization. The team will still appear in saved reports and analytics.')}`, open: deleteModalOpen, onClose: (e) => {
                e.stopPropagation();
                setDeleteModalOpen(false);
            } },
            React.createElement(DeleteTeamView, { teamID: team.id, onClose: () => {
                    setDeleteModalOpen(false);
                    getTeamsQuery();
                } }))));
}
