var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useCortiUIContext } from 'lib/cortiUI/context';
export function DefaultColumnRenderer(props) {
    const { requiredProps, className } = props, rest = __rest(props, ["requiredProps", "className"]);
    const { theme } = useCortiUIContext();
    return (React.createElement("div", Object.assign({}, rest, requiredProps.props, { style: requiredProps.props.style, className: `${requiredProps.props.className} ${css({
            height: '100%',
            paddingLeft: theme.space[6],
            paddingRight: theme.space[6],
            marginLeft: '0 !important',
            marginRight: '0 !important',
            outline: 'none',
        }, className)}` }), requiredProps.props.children));
}
