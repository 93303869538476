import React from 'react';
import { intl } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css, getScrollerCss } from '@corti/style';
import { Base, List, ListItemButton, Typography, useCortiUIContext } from 'lib/cortiUI';
import { GroupOuterShell } from './GroupOuterShell';
import { Input } from './Input';
export function SingleSelectableList(props) {
    const { items, selectedItemId, onChange, withSearch = false } = props;
    const { theme } = useCortiUIContext();
    const [searchValue, setFilterValue] = React.useState('');
    function getFilteredItems() {
        return items.filter((i) => !searchValue || stringIncludes(i.text, searchValue));
    }
    return (React.createElement(React.Fragment, null,
        withSearch && (React.createElement(GroupOuterShell, { className: css({
                margin: 'auto',
                width: 200,
                height: 30,
            }) },
            React.createElement(Input, { value: searchValue, placeholder: intl.t('search', 'Search'), onChange: (e) => setFilterValue(e.target.value) }))),
        React.createElement(Base, { className: css(getScrollerCss({ theme: theme })), maxHeight: 150, overflow: "hidden", mt: 3 },
            React.createElement(List, null, getFilteredItems().map((i, idx) => (React.createElement(ListItemButton, { key: idx, selected: selectedItemId === i.value, onClick: () => onChange(i.value), classes: {
                    root: css({
                        height: 30,
                        backgroundColor: theme.palette.background.default,
                    }),
                } },
                React.createElement(Typography, { color: "default", variant: "caption" }, i.text))))))));
}
