import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useContext } from 'react';
import { ThemeProvider } from '@corti/theme';
import { ContextMenuProvider } from '../components/ContextMenu';
export const cortiUIContext = React.createContext(null);
export function useCortiUIContext() {
    const context = useContext(cortiUIContext);
    if (!context) {
        throw new Error('useCortiUIContext must be used within a CortiUIContextProvider');
    }
    return context;
}
export function CortiUIContextProvider({ theme, debug, children, }) {
    return (React.createElement(cortiUIContext.Provider, { value: {
            theme,
            debug,
        } },
        React.createElement(CacheProvider, { value: cache },
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(ContextMenuProvider, null,
                    React.createElement(CssBaseline, null, children))))));
}
