import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Chip } from 'lib/cortiUI';
export const PerformanceChip = ({ value, thresholds = { min: 20, max: 50 }, }) => {
    const { palette } = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.score' });
    const getColorAndLabel = (value, thresholds, palette) => {
        if (value <= thresholds.min) {
            return { color: palette.error.light, label: t('nonCompliant', 'Non Compliant') };
        }
        else if (value > thresholds.max) {
            return { color: palette.success.light, label: t('compliant', 'Compliant') };
        }
        else {
            return {
                color: palette.warning.light,
                label: t('partiallyCompliant', 'Partially Compliant'),
            };
        }
    };
    const { color, label } = getColorAndLabel(value, thresholds, palette);
    return (React.createElement(Chip, { variant: "outlined", borderColor: color, className: css({
            color: color,
        }), label: label }));
};
