import { useMutation, useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Autocomplete, Base, Box, Button, MenuItem, TextField, Typography } from 'lib/cortiUI';
import * as GQL from './graphql';
export function AddTeamMemberView(props) {
    var _a, _b;
    const { onSuccess, team } = props;
    const { t } = useTranslation('teams');
    const auth = useAuth();
    const theme = useTheme();
    const [selectedUsersIDs, setSelectedUsersIDs] = React.useState([]);
    const [addTeamMember] = useMutation(GQL.addTeamMemberMutation);
    const getUsers = useQuery(GQL.getUsersQuery, {
        variables: { orgID: auth.organization.id },
    });
    async function handleSubmit() {
        await Promise.all(selectedUsersIDs.map((id) => addTeamMember({
            variables: {
                userID: id,
                teamID: team.id,
            },
        })));
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
    }
    function handleUserSelect(value) {
        const result = value.map((v) => v.id);
        setSelectedUsersIDs(result);
    }
    const users = (_b = (_a = getUsers.data) === null || _a === void 0 ? void 0 : _a.users) !== null && _b !== void 0 ? _b : [];
    function getFilteredUsers() {
        const teamMembersIds = team.members.map((it) => it.user.id);
        return users.filter((it) => !!it && !teamMembersIds.includes(it.id));
    }
    const filteredUsers = getFilteredUsers();
    const selectedUsers = selectedUsersIDs
        .map((it) => filteredUsers.find((i) => i.id === it))
        .filter((it) => !!it);
    function filterOptions(options, { inputValue }) {
        return options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.email.toLowerCase().includes(inputValue.toLowerCase()));
    }
    return (React.createElement(Box, { "data-cy": "add-team-members-dialog", className: css({ gap: theme.space[6] }) },
        React.createElement(Autocomplete, { "data-cy": "team-members-multiple-select", multiple: true, disableCloseOnSelect: true, options: filteredUsers, getOptionLabel: (option) => option.name, value: selectedUsers, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: (_, value) => handleUserSelect(value), filterOptions: filterOptions, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('teamView.addMembers.selectPlaceholder', 'User name or email') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }),
                React.createElement(Typography, { noWrap: true }, option.name))), ChipProps: {
                size: 'small',
            } }),
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "flex-end" },
            React.createElement(Button, { "data-cy": "add-team-members-done-btn", color: "primary", onClick: handleSubmit, variant: "text" }, t('save', 'Save')))));
}
