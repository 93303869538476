var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { intl } from '@corti/i18n';
import { Avatar, Base, Typography } from 'lib/cortiUI';
import { useTriageSession } from '../TriageSessionContext';
export function UserItem(_a) {
    var _b;
    var { user, action } = _a, rest = __rest(_a, ["user", "action"]);
    const ctx = useTriageSession();
    const isCurrentUser = ctx.controller.currentUser.id === (user === null || user === void 0 ? void 0 : user.id);
    return (React.createElement(Base, Object.assign({ display: "grid", gridTemplateColumns: '1fr auto', alignItems: "center", gap: 3 }, rest),
        React.createElement(Base, { display: "grid", gridTemplateColumns: 'auto 1fr', alignItems: "center", gap: 5 },
            React.createElement(Avatar, { titleText: user === null || user === void 0 ? void 0 : user.name, size: "small" }),
            React.createElement(Typography, { color: 'default', noWrap: true, textAlign: "left", overflow: "hidden" }, (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : '-',
                " ",
                isCurrentUser && React.createElement(React.Fragment, null,
                    "(",
                    intl.t('realtime:you', 'You'),
                    ")"))),
        action && action));
}
