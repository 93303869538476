var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, darken } from '@corti/style';
import { Base } from 'lib/cortiUI/components/Base';
import { useCortiUIContext } from 'lib/cortiUI/context';
export function DefaultGhostRowRenderer(props) {
    const { requiredProps, className } = props, rest = __rest(props, ["requiredProps", "className"]);
    const { className: rowClassName, style } = requiredProps;
    const { theme } = useCortiUIContext();
    return (React.createElement("div", Object.assign({}, rest, { style: style, className: `${rowClassName} ${css({
            borderBottom: `1px solid ${theme.palette.background.divider}`,
        }, className)}` }),
        React.createElement(Base, { display: "grid", gridGap: theme.space[7], gridTemplateColumns: "auto 1fr 1fr 1fr", className: css({
                height: '100%',
                width: '100%',
                marginLeft: '0 !important',
                marginRight: '0 !important',
                padding: theme.space[6],
            }) },
            React.createElement(Base, { className: css({
                    height: 30,
                    width: 30,
                    borderRadius: '50%',
                    backgroundColor: darken(0.015, theme.palette.background.secondary),
                }) }),
            React.createElement(Base, { className: css({
                    height: '100%',
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: darken(0.015, theme.palette.background.secondary),
                }) }),
            React.createElement(Base, { className: css({
                    height: '100%',
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: darken(0.015, theme.palette.background.secondary),
                }) }),
            React.createElement(Base, { className: css({
                    height: '100%',
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: darken(0.015, theme.palette.background.secondary),
                }) }))));
}
