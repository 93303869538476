import { observer } from 'mobx-react';
import React from 'react';
import { intl, useTranslation } from '@corti/i18n';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { Base, FallbackView, Loading } from 'lib/cortiUI';
import { TriageSessionView as TriageSessionViewLib, } from 'lib/triageSession';
import { useRealTimeCtx } from '../RealTimeView/RealTimeContext';
import { useUIStateCache } from '../cache/UIStateCache';
export const TriageSessionView = observer(function TriageSessionView({ triageSession, }) {
    React.useEffect(() => {
        trackerService.startSessionReplayRecording();
        return () => {
            trackerService.stopSessionReplayRecording();
        };
    }, []);
    if (triageSession.status === 'initializing') {
        return (React.createElement(Base, { display: "flex", justifyContent: "center", alignItems: "center" },
            React.createElement(Loading, { text: intl.t('realtime:loading', 'Loading') })));
    }
    if (triageSession.status === 'error') {
        const error = triageSession.error;
        let errorMessage = '';
        switch (error.code) {
            case 'load-session-error': {
                errorMessage = intl.t('realtime:loadSessionError', 'Session failed to load');
                break;
            }
            case 'session-not-found': {
                errorMessage = intl.t('realtime:sessionNotFoundError', `Session doesn't exist`);
                break;
            }
            case 'graph-not-found': {
                errorMessage = intl.t('realtime:graphNotFoundError', `Graph doesn't exist`);
                break;
            }
            default: {
                errorMessage = intl.t('realtime:unknownError', 'Unknown error');
                break;
            }
        }
        return (React.createElement(Base, { display: "flex", justifyContent: "center", alignItems: "center" },
            React.createElement(FallbackView, { title: intl.t('realtime:loadSessionError', 'Session failed to load'), text: errorMessage, devMessage: triageSession.error.error ? String(triageSession.error.error) : undefined })));
    }
    return React.createElement(TriageSessionContainer, { controller: triageSession.controller });
});
function TriageSessionContainer({ controller }) {
    const realtime = useRealTimeCtx();
    const { t } = useTranslation();
    const { cache, setCache } = useUIStateCache(controller);
    const notificationIDs = React.useRef([]);
    React.useEffect(() => () => {
        const ref = notificationIDs.current;
        ref.forEach(coreStore.notifications.closeNotification);
    }, []);
    React.useEffect(() => {
        realtime.fireTriageSessionViewOpenedEvent(controller);
        return () => {
            realtime.fireTriageSessionViewClosedEvent(controller);
        };
    }, [controller, realtime]);
    function handleUIEvent(e) {
        switch (e.type) {
            case 'alert-action-button-clicked': {
                trackerService.track('[Triage] alert clicked', {
                    alertID: e.data.id,
                    alertTitle: e.data.title,
                    alertText: e.data.text,
                    sessionID: controller.id,
                    caseID: controller.caseID,
                    externalID: controller.externalID,
                    contextSource: 'in-app',
                });
                break;
            }
            case 'action-selector-item-clicked': {
                notificationIDs.current.push(coreStore.notifications.showNotification({
                    type: 'success',
                    message: t('realtime:actionBlockSelected', "'{{actionTitle}}' was selected", {
                        actionTitle: e.data.text,
                        interpolation: { escapeValue: false },
                    }),
                }));
                break;
            }
            case 'action-selector-closed': {
                //TODO: A hack!. Flow doesnt know what is a toolbar and it should not emit toolbar events.
                // Toolbar is a part of the session view.
                //
                // Update: (Vilius)
                // I have relocated this logic from flow library to a session library.
                // Now we need to find out who subscribes to this event and replace it with some other event.
                controller.flow.observer.fireEvent('toolbar-element-blurred', {
                    event: 'toolbar-element-blurred',
                    data: {
                        datetime: new Date().toUTCString(),
                    },
                });
                break;
            }
        }
    }
    return (React.createElement(TriageSessionViewLib, { controller: controller, initialUIState: cache, onUIStateChange: setCache, onUIEvent: handleUIEvent }));
}
