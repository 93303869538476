var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from '../../MissionControlService';
import { formatArrayQueryParams } from '../utils';
export async function getTimeBasedStats(templateID, _a) {
    var { reference_type = 'CALL', user_ids = [], status = [], acknowledgement_status = [] } = _a, params = __rest(_a, ["reference_type", "user_ids", "status", "acknowledgement_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['acknowledgement_status', acknowledgement_status]);
    return await api
        .get(`/stats/timeline/${templateID}`, {
        params: Object.assign(Object.assign({ reference_type }, formattedParams), params),
    })
        .then((response) => response.data);
}
export async function getQuestionStatsById(questionID, _a) {
    var { reference_type = 'CALL', user_ids = [], status = [], acknowledgement_status = [] } = _a, params = __rest(_a, ["reference_type", "user_ids", "status", "acknowledgement_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['acknowledgement_status', acknowledgement_status]);
    return await api
        .get(`/stats/question/${questionID}`, {
        params: Object.assign(Object.assign({ reference_type }, formattedParams), params),
    })
        .then((response) => response.data);
}
export async function getOverallStats(templateID, _a) {
    var { reference_type = 'CALL', user_ids = [], status = [], acknowledgement_status = [] } = _a, params = __rest(_a, ["reference_type", "user_ids", "status", "acknowledgement_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['acknowledgement_status', acknowledgement_status]);
    return await api
        .get(`/stats/${templateID}`, {
        params: Object.assign(Object.assign({ reference_type }, formattedParams), params),
    })
        .then((response) => response.data);
}
export async function getReviewStatusStats(templateID, _a) {
    var { reference_type = 'CALL', user_ids = [], status = [], acknowledgement_status = [] } = _a, params = __rest(_a, ["reference_type", "user_ids", "status", "acknowledgement_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['acknowledgement_status', acknowledgement_status]);
    return await api
        .get(`/stats/status/${templateID}`, {
        params: Object.assign(Object.assign({ reference_type }, formattedParams), params),
    })
        .then((response) => response.data);
}
export async function getAcknowledgementStatusStats(templateID, _a) {
    var { reference_type = 'CALL', user_ids = [], status = [], acknowledgement_status = [] } = _a, params = __rest(_a, ["reference_type", "user_ids", "status", "acknowledgement_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['acknowledgement_status', acknowledgement_status]);
    return await api
        .get(`/stats/acknowledgement-status/${templateID}`, {
        params: Object.assign(Object.assign({ reference_type }, formattedParams), params),
    })
        .then((response) => response.data);
}
