import { gql } from '@apollo/client';
export const getCaseMetadataQuery = gql `
  query case($id: String!) {
    case(id: $id) {
      id
      customProperties
      calls {
        id
        protocolName
        user {
          id
          name
        }
        start
        externalID
      }
    }
  }
`;
