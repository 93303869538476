import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Slider, SliderMark, Tooltip, useCortiUIContext } from 'lib/cortiUI';
import { getPerformanceStatusColor } from '../../../utils';
export const PerformanceIndicator = ({ value, target, threshold, }) => {
    const { theme: { palette }, } = useCortiUIContext();
    const { t } = useTranslation('performanceApp');
    const MIN_VALUE = 0;
    const MAX_VALUE = 100;
    const DEFAULT_MARKS = [
        { value: MIN_VALUE, label: `${MIN_VALUE}%` },
        {
            value: MAX_VALUE,
            label: `${MAX_VALUE}%`,
        },
    ];
    const { color, status } = getPerformanceStatusColor(palette, value, threshold, target);
    const getMarksList = () => {
        const marks = [...DEFAULT_MARKS];
        if (target) {
            marks.splice(1, 0, {
                value: target,
                label: '',
            });
            return marks;
        }
        return DEFAULT_MARKS;
    };
    const getHiddenMarkStyles = (markerIndex) => {
        const selectorKey = `&[data-index="${markerIndex}"]`;
        return {
            [selectorKey]: {
                backgroundColor: 'unset',
                height: 0,
                width: 0,
                border: 'unset',
            },
        };
    };
    const getMarkStyles = () => {
        let styles = getHiddenMarkStyles(0);
        if (target) {
            styles = Object.assign(Object.assign({}, styles), getHiddenMarkStyles(2));
        }
        else {
            styles = Object.assign(Object.assign({}, styles), getHiddenMarkStyles(1));
        }
        return styles;
    };
    return (React.createElement(Slider, { "data-cy": `active-${status}`, value: value, slots: {
            thumb: 'span',
            mark: (props) => (React.createElement(Tooltip, { placement: "top", title: target
                    ? t('targetConfiguration.performanceGoal', {
                        count: target,
                        defaultValue: `{{count}}% Goal`,
                    })
                    : '' },
                React.createElement(SliderMark, Object.assign({}, props)))),
        }, min: MIN_VALUE, max: MAX_VALUE, marks: getMarksList(), sx: {
            '& .MuiSlider-track': {
                height: 8,
                color,
            },
            '& .MuiSlider-rail': {
                height: 8,
                color: palette.grey[300],
            },
            cursor: 'default',
            '& .MuiSlider-mark': Object.assign({ backgroundColor: color, height: 12, width: 4, opacity: 1, border: `2px solid ${palette.background.card}`, borderRadius: 1, cursor: 'pointer' }, getMarkStyles()),
            '& .MuiSlider-markLabel': {
                color: palette.grey[600],
                fontSize: 14,
            },
        } }));
};
