import React, { useContext } from 'react';
import { intl } from '@corti/i18n';
import { Autocomplete, MenuItem, TextField, useCortiUIContext } from 'lib/cortiUI';
import { FilterBuilderContext } from '../FilterBuilderContext';
export const ComparisonRenderer = (props) => {
    const { getComparisonText } = useContext(FilterBuilderContext);
    const { value, comparisons, onChange } = props;
    const { theme } = useCortiUIContext();
    function getComparisonItems() {
        return comparisons.map((c) => ({
            value: c,
            text: getComparisonText ? getComparisonText(c) : c,
        }));
    }
    function getSelectedComparison() {
        if (value) {
            return getComparisonItems().find((e) => e.value === value);
        }
    }
    function onComparisonSelect(newItem) {
        onChange(newItem.value);
    }
    return (React.createElement(Autocomplete, { "data-cy": "comparison-select", size: "small", options: getComparisonItems(), getOptionLabel: (option) => option.text, value: getSelectedComparison(), onChange: (_, option) => {
            if (option) {
                onComparisonSelect(option);
            }
        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: intl.t('filterBuilder.selectFilter', 'Select Filter...') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "select-item", selected: state.selected, key: option.value }), option.text)), clearIcon: null, sx: { minWidth: 220, margin: `${theme.space[5]}px 0` } }));
};
