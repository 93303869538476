var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Mousetrap from 'mousetrap';
import React, { useEffect } from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
export const ValueTag = React.forwardRef((props, ref) => {
    const { children, className, disabled, valueType, onClick, onEmit, onSubmit, onContextMenu, registerRef, isEditable } = props, rest = __rest(props, ["children", "className", "disabled", "valueType", "onClick", "onEmit", "onSubmit", "onContextMenu", "registerRef", "isEditable"]);
    const valueTagRef = React.useRef(null);
    const theme = useTheme();
    React.useEffect(() => {
        valueTagRef.current && (registerRef === null || registerRef === void 0 ? void 0 : registerRef(valueTagRef.current));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueTagRef.current]);
    const onClickHandler = () => {
        if (disabled) {
            return;
        }
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    const tagClassName = css({
        position: 'relative',
        cursor: onClick ? 'pointer' : 'default',
        borderRadius: theme.shape.borderRadius,
        color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    }, className);
    return (React.createElement(Base, Object.assign({}, rest, { ref: ref, className: css({
            pointerEvents: disabled ? 'none' : 'visible',
            display: 'inline',
            '&:not(:last-of-type):after': {
                content: '"·"',
                margin: '0px 4px',
            },
        }) }), valueType === 'custom' ? (React.createElement(CustomValueTag, { onSubmit: onSubmit, className: tagClassName, ref: valueTagRef, onContextMenu: onContextMenu, disabled: disabled, isEditable: isEditable, onEmit: onEmit }, children)) : (React.createElement("span", { "data-cy": "value", ref: valueTagRef, className: tagClassName, onContextMenu: onContextMenu, onClick: onClickHandler }, children))));
});
function selectText(node) {
    if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
        selection === null || selection === void 0 ? void 0 : selection.addRange(range);
    }
    else {
        console.warn('Could not select text in node: Unsupported browser.');
    }
}
const CustomValueTag = React.forwardRef((props, ref) => {
    var _a;
    const [editState, setEditState] = React.useState((_a = props.isEditable) !== null && _a !== void 0 ? _a : false);
    const contentEditableRef = React.useRef(null);
    const keybinder = React.useRef(null);
    React.useEffect(() => {
        var _a;
        if ((contentEditableRef === null || contentEditableRef === void 0 ? void 0 : contentEditableRef.current) && !props.disabled) {
            contentEditableRef === null || contentEditableRef === void 0 ? void 0 : contentEditableRef.current.focus();
            enableKeyboardShortcuts();
            return;
        }
        (_a = keybinder.current) === null || _a === void 0 ? void 0 : _a.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editState, props.disabled]);
    function enableKeyboardShortcuts() {
        var _a;
        if (contentEditableRef === null || contentEditableRef === void 0 ? void 0 : contentEditableRef.current) {
            (_a = keybinder.current) === null || _a === void 0 ? void 0 : _a.reset();
            keybinder.current = new Mousetrap(contentEditableRef === null || contentEditableRef === void 0 ? void 0 : contentEditableRef.current);
            keybinder.current.bind('enter', (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (editState === true) {
                    update();
                }
                else {
                    onClickHandler();
                }
            });
            if (editState === true) {
                const stopPropagation = (event) => event.stopPropagation();
                keybinder.current.bind('right', stopPropagation);
                keybinder.current.bind('left', stopPropagation);
                keybinder.current.bind('esc', (event) => {
                    event.stopPropagation();
                    emitHandler();
                });
            }
        }
    }
    const emitHandler = () => {
        var _a, _b;
        setEditState(false);
        if (contentEditableRef.current) {
            contentEditableRef.current.textContent = (_a = props.children) !== null && _a !== void 0 ? _a : '';
        }
        (_b = props.onEmit) === null || _b === void 0 ? void 0 : _b.call(props);
    };
    const update = () => {
        var _a, _b, _c, _d, _e;
        if (props.disabled) {
            return;
        }
        setEditState(false);
        if (((_a = contentEditableRef.current) === null || _a === void 0 ? void 0 : _a.textContent) !== props.children) {
            (_b = props.onSubmit) === null || _b === void 0 ? void 0 : _b.call(props, (_e = (_d = (_c = contentEditableRef.current) === null || _c === void 0 ? void 0 : _c.textContent) === null || _d === void 0 ? void 0 : _d.trim()) !== null && _e !== void 0 ? _e : '');
        }
    };
    const onClickHandler = () => {
        if (props.disabled) {
            return;
        }
        setEditState(true);
        if (contentEditableRef.current) {
            selectText(contentEditableRef.current);
        }
    };
    const onBlurHandler = () => {
        update();
    };
    useEffect(() => {
        const element = contentEditableRef.current;
        if (!element)
            return;
        const handleBeforeInput = (event) => {
            var _a;
            const isElement = event.target instanceof HTMLElement;
            const isElementEmpty = isElement && ((_a = event.target.textContent) === null || _a === void 0 ? void 0 : _a.trim()) === '';
            const isInputEmpty = event.data === null || event.data.trim() === '';
            const isEmpty = isElementEmpty && isInputEmpty;
            const isInsertParagraph = event.inputType === 'insertParagraph';
            if (isEmpty || isInsertParagraph) {
                event.preventDefault();
            }
        };
        element.addEventListener('beforeinput', handleBeforeInput);
        return () => element.removeEventListener('beforeinput', handleBeforeInput);
    }, []);
    return (React.createElement("span", { "data-cy": "value", ref: (node) => {
            contentEditableRef.current = node;
            if (typeof ref === 'function') {
                ref(node);
            }
            else if (ref) {
                ref.current = node;
            }
        }, contentEditable: editState, suppressContentEditableWarning: true, className: props.className, onContextMenu: props.onContextMenu, onMouseDown: (event) => {
            // button 2 is right click.
            if (event.button === 2)
                return;
            onClickHandler();
        }, onBlur: onBlurHandler }, props.children));
});
