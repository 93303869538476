import { isEqual, startCase } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { ICaseReviewRequestSortDirection, ICaseReviewRequestSortPropertyName, getReadableCaseID, } from '@corti/lib/coreApiService';
import { css, getScrollerCss } from '@corti/style';
import { rbacService, trackerService } from 'browser/services/init';
import { Base, Chip, DataGrid, GridEvents, useCortiUIContext, useGridApiRef, } from 'lib/cortiUI';
import { useCaseReviewRequestsContext } from '../CaseReviewRequestsContext';
import { getCaseReviewRequestStatus, getCaseReviewRequestStatusDescriptor } from '../utils';
import { LoadingOverlay } from './LoadingOverlay';
import { NoRowsOverlay } from './NoRowsOverlay';
import { Toolbar } from './Toolbar';
const fields = [
    'caseID',
    'caseCreatedAt',
    'caseReviewRequestCreatedAt',
    'caseReviewRequestStatus',
    'callTaker',
    'assignee',
    'templateName',
    'protocolName',
];
export const CaseReviewRequestsTable = observer(function CaseReviewRequestsTable() {
    const { theme } = useCortiUIContext();
    const ctx = useCaseReviewRequestsContext();
    const history = useHistory();
    const dataGridRef = useGridApiRef();
    const [selectedRowIDs, setSelectedRowIDs] = React.useState([]);
    React.useEffect(() => {
        // promise never rejects
        void ctx.model.getCaseReviewRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        return dataGridRef.current.subscribeEvent(GridEvents.rowsScrollEnd, () => {
            // promise never rejects
            void ctx.model.getCaseReviewRequestsNextPage();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGridRef]);
    function getColumns() {
        const columns = [
            {
                field: 'caseReviewRequestCreatedAt',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.caseReviewRequestCreatedAt', 'Review requested')),
                editable: false,
                sortable: true,
                flex: 1.2,
            },
            {
                field: 'assignee',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.assignee', 'Reviewer')),
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'caseID',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.caseID', 'Case id')),
                editable: false,
                sortable: false,
                flex: 0.6,
            },
            {
                field: 'externalID',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.externalID', 'External ID')),
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'protocolName',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.protocolName', 'Protocol name')),
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'callTaker',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.callTaker', 'Call Taker')),
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'caseCreatedAt',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.caseCreatedAt', 'Case created')),
                editable: false,
                sortable: true,
                flex: 1.2,
            },
            {
                field: 'templateName',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.templateName', 'Form')),
                editable: false,
                sortable: false,
                flex: 0.6,
            },
            {
                field: 'caseReviewRequestStatus',
                headerName: startCase(intl.t('caseReviewRequestsModule:tableColumns.caseReviewRequestStatus', 'Status')),
                editable: false,
                sortable: false,
                flex: 1,
                renderCell: (props) => {
                    const des = props.value;
                    return (React.createElement(Chip, { "data-cy": "review-queue-status-chip", "data-cy-state": des.status, label: des.text, className: css({
                            backgroundColor: `${des.color} !important`,
                            color: '#fff',
                        }) }));
                },
            },
        ];
        return columns;
    }
    function getRows() {
        return ctx.model.caseReviewRequests.map((it) => {
            var _a, _b, _c, _d;
            return {
                id: it.id,
                caseID: getReadableCaseID(it.case.id),
                caseCreatedAt: formatDateTime(new Date(it.case.createdAt)),
                caseReviewRequestCreatedAt: formatDateTime(new Date(it.createdAt)),
                caseReviewRequestStatus: getCaseReviewRequestStatusDescriptor(getCaseReviewRequestStatus(it), theme),
                assignee: !it.assignee.user
                    ? intl.t('caseReviewRequestsModule:anonymous', 'Anonymous')
                    : (_a = it.assignee.user) === null || _a === void 0 ? void 0 : _a.name,
                templateName: (_c = (_b = it.submission) === null || _b === void 0 ? void 0 : _b.template.title) !== null && _c !== void 0 ? _c : (_d = it.suggestedTemplate) === null || _d === void 0 ? void 0 : _d.title,
                protocolName: it.case.calls
                    .map((c) => c.protocolName)
                    .filter((c) => !!c)
                    .join(', '),
                externalID: it.case.calls
                    .map((c) => c.externalID)
                    .filter((c) => !!c)
                    .join(', '),
                callTaker: it.case.calls
                    .map((c) => { var _a; return (_a = c.user) === null || _a === void 0 ? void 0 : _a.name; })
                    .filter((c) => !!c)
                    .join(', '),
                actions: [],
            };
        });
    }
    function getSortModel() {
        if (!ctx.model.sort.direction || !ctx.model.sort.propertyName) {
            return [];
        }
        let field;
        let sort;
        switch (ctx.model.sort.direction) {
            case ICaseReviewRequestSortDirection.Ascending:
                sort = 'asc';
                break;
            case ICaseReviewRequestSortDirection.Descending:
                sort = 'desc';
                break;
        }
        switch (ctx.model.sort.propertyName) {
            case ICaseReviewRequestSortPropertyName.RequestCreatedAt:
                field = 'caseReviewRequestCreatedAt';
                break;
            case ICaseReviewRequestSortPropertyName.AssigneeUserName:
                field = 'assignee';
                break;
            case ICaseReviewRequestSortPropertyName.CallTakerUserName:
                field = 'callTaker';
                break;
            case ICaseReviewRequestSortPropertyName.ProtocolName:
                field = 'protocolName';
                break;
            case ICaseReviewRequestSortPropertyName.CaseCreatedAt:
                field = 'caseCreatedAt';
                break;
        }
        return [{ sort, field }];
    }
    function handleRowsDelete() {
        selectedRowIDs.forEach((it) => {
            // TODO(@elvis): why do we rethrow on this promise?
            void ctx.model.removeCaseReviewRequest(it);
        });
    }
    function handleRowsSelect(rowIDs) {
        setSelectedRowIDs(rowIDs);
    }
    function handleRowClick(params) {
        var _a;
        const caseReviewRequest = ctx.model.caseReviewRequests.find((it) => it.id === params.id);
        trackerService.track('[Review Queue] case review request preview opened', caseReviewRequest);
        if (!caseReviewRequest)
            return null;
        let pathForCase = `/explore/${caseReviewRequest.case.id}/?sidebarView=feedback&feedbackView=feedback-create&templateID=${(_a = caseReviewRequest.suggestedTemplate) === null || _a === void 0 ? void 0 : _a.id}`;
        if (caseReviewRequest.submission) {
            pathForCase = `/explore/${caseReviewRequest.case.id}/?sidebarView=feedback&feedbackView=feedback-preview&feedbackID=${caseReviewRequest.submission.id}`;
        }
        history.push(pathForCase);
    }
    function handleSortModelChange(newSortModel) {
        const sortItem = newSortModel[0];
        if (isEqual(sortModel, newSortModel))
            return;
        if (sortItem) {
            switch (sortItem.field) {
                case 'caseReviewRequestCreatedAt':
                    ctx.model.sort.setPropertyName(ICaseReviewRequestSortPropertyName.RequestCreatedAt);
                    break;
                case 'assignee':
                    ctx.model.sort.setPropertyName(ICaseReviewRequestSortPropertyName.AssigneeUserName);
                    break;
                case 'callTaker':
                    ctx.model.sort.setPropertyName(ICaseReviewRequestSortPropertyName.CallTakerUserName);
                    break;
                case 'protocolName':
                    ctx.model.sort.setPropertyName(ICaseReviewRequestSortPropertyName.ProtocolName);
                    break;
                case 'caseCreatedAt':
                    ctx.model.sort.setPropertyName(ICaseReviewRequestSortPropertyName.CaseCreatedAt);
                    break;
            }
            switch (sortItem.sort) {
                case 'asc':
                    ctx.model.sort.setDirection(ICaseReviewRequestSortDirection.Ascending);
                    break;
                case 'desc':
                    ctx.model.sort.setDirection(ICaseReviewRequestSortDirection.Descending);
                    break;
            }
        }
        // promise never rejects
        void ctx.model.getCaseReviewRequests();
    }
    const rows = getRows();
    const columns = getColumns();
    const sortModel = getSortModel();
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "auto 1fr", gridGap: 6, width: "100%", height: "100%" },
        React.createElement(Toolbar, { showTools: Boolean(selectedRowIDs.length), onDelete: handleRowsDelete, totalRowCount: ctx.model.caseReviewRequestsTotalCount }),
        React.createElement(DataGrid, { rows: rows, columns: columns, apiRef: dataGridRef, pageSize: rows.length, checkboxSelection: rbacService === null || rbacService === void 0 ? void 0 : rbacService.caseReviewRequestsDelete(), disableColumnMenu: true, disableColumnFilter: true, disableColumnSelector: true, disableSelectionOnClick: true, hideFooter: true, sortingOrder: ['asc', 'desc'], sortingMode: "server", sortModel: sortModel, onSortModelChange: handleSortModelChange, onSelectionModelChange: (rowIDs) => handleRowsSelect(rowIDs), loading: ctx.model.isLoadingCaseReviewRequests, classes: {
                virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
            }, sx: {
                '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
            }, components: {
                LoadingOverlay: LoadingOverlay,
                NoRowsOverlay: NoRowsOverlay,
            }, onRowClick: handleRowClick })));
});
