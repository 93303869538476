var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from '../../MissionControlService';
import { formatArrayQueryParams } from '../utils';
export async function getReviewById(id) {
    return await api.get(`/reviews/${id}`).then((response) => response.data);
}
export async function getReviewsList(_a) {
    var { offset = 0, limit = 20, reference_type = 'CALL', user_ids = [], status = [], acknowledgement_status = [] } = _a, restParams = __rest(_a, ["offset", "limit", "reference_type", "user_ids", "status", "acknowledgement_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['acknowledgement_status', acknowledgement_status]);
    return await api
        .get('/reviews/list', {
        params: Object.assign(Object.assign({ offset,
            limit,
            reference_type }, formattedParams), restParams),
    })
        .then((response) => response.data);
}
export async function getReviewsByCall(callID) {
    const defaultStatuses = [
        'PENDING_TRANSCRIPT',
        'FAILED',
        'DONE',
        'FLAGGED',
        'ACKNOWLEDGED',
    ];
    return await api
        .get(`/reviews/by-reference`, {
        params: { reference_type: 'CALL', reference_id: callID, status: defaultStatuses.join(',') },
    })
        .then((response) => response.data);
}
export async function getReviewAnswers(id, params = { limit: 10, offset: 0 }) {
    return await api.get(`/reviews/${id}/answers`, { params }).then((response) => response.data);
}
export async function getReviewFeedback(id) {
    return await api.get(`/reviews/${id}/feedback`).then((response) => response.data);
}
export async function getAnswerByQuestion(reviewID, questionID) {
    return await api
        .get(`/reviews/${reviewID}/answers`, {
        params: { question_id: questionID },
    })
        .then((response) => response.data[0]);
}
export async function submitReview(id) {
    return await api.post(`/reviews/${id}/submit`).then((response) => response.data);
}
export async function createReview(data) {
    return await api.post(`/reviews/create`, data).then((response) => response.data);
}
export async function createAnswer(reviewID, questionID, data) {
    return await api
        .post(`/reviews/${reviewID}/questions/${questionID}/answer`, data)
        .then((response) => response.data);
}
export async function updateAnswer(reviewID, answerID, data) {
    return await api
        .put(`/reviews/${reviewID}/answer/${answerID}`, data)
        .then((response) => response.data);
}
