import { gql, useQuery } from '@apollo/client';
import { ceil } from 'lodash';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { api } from '@corti/lib/coreApiService';
import { trackerService } from 'browser/services/init';
import { Base, FallbackView, LinearProgress, Typography } from 'lib/cortiUI';
import { CoreBackendDeserializer } from 'lib/graphEditor/coreBackendSerializer';
import { RouteBreadcrumb } from 'lib/router';
import { GraphEditorView } from './GraphEditorView';
export function GraphEditorRoute() {
    var _a, _b;
    const match = useRouteMatch();
    const { versionID } = match.params;
    const [query, setQuery] = React.useState({
        loading: true,
    });
    const getGraphMetaQuery = useQuery(gql `
      query ProtocolGraphMeta($id: String!) {
        protocolGraphVersion(id: $id) {
          id
          name
          release {
            releasedAt
          }
          graph {
            id
            name
          }
        }
      }
    `, { variables: { id: versionID } });
    React.useEffect(() => {
        setQuery({ loading: true });
        api.protocolGraphs
            .getGraph(versionID)
            .then((g) => {
            setQuery({ loading: false, data: g });
            trackerService.startSessionReplayRecording();
        })
            .catch((err) => {
            console.error(err);
            setQuery({ loading: false, error: err });
        });
        return () => {
            trackerService.stopSessionReplayRecording();
        };
    }, [versionID]);
    const breadcrumbRenderer = (React.createElement(RouteBreadcrumb, { breadcrumb: getGraphMetaQuery.data &&
            `${getGraphMetaQuery.data.protocolGraphVersion.graph.name} - ${getGraphMetaQuery.data.protocolGraphVersion.name}` }));
    if (query.loading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(LoadingView, { loadType: "indeterminate", text: "Downloading data..." }),
            breadcrumbRenderer));
    }
    if (query.error) {
        return breadcrumbRenderer;
    }
    if (query.data) {
        const isReleased = Boolean((_b = (_a = getGraphMetaQuery.data) === null || _a === void 0 ? void 0 : _a.protocolGraphVersion.release) === null || _b === void 0 ? void 0 : _b.releasedAt);
        return (React.createElement(React.Fragment, null,
            React.createElement(GraphDataLoadedView, { graph: query.data, isReleased: isReleased }),
            breadcrumbRenderer));
    }
    return breadcrumbRenderer;
}
function GraphDataLoadedView({ graph, isReleased }) {
    const [error, setError] = React.useState();
    const [progress, setProgress] = React.useState(0);
    const [parsing, setParsing] = React.useState(true);
    const [graphModel, setGraphModel] = React.useState();
    React.useEffect(() => {
        void parseGraph();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function parseGraph() {
        try {
            const deserialized = await CoreBackendDeserializer.deserialize(graph, {
                onProgress: (event) => {
                    const parsedPercentage = (event.loaded / event.total) * 100;
                    const loaded = ceil(parsedPercentage);
                    setProgress(loaded);
                },
            });
            setGraphModel(deserialized);
        }
        catch (err) {
            console.error(err);
            setError(err);
        }
        finally {
            setParsing(false);
        }
    }
    if (parsing) {
        return React.createElement(LoadingView, { loadType: "determinate", text: "Parsing...", value: progress });
    }
    if (error) {
        return React.createElement(FallbackView, { title: 'Cannot parse graph data', devMessage: error === null || error === void 0 ? void 0 : error.message });
    }
    if (graphModel) {
        return (React.createElement(GraphEditorView, { graphVersion: graph.version, graphModel: graphModel, isReleased: isReleased }));
    }
    return null;
}
function LoadingView({ loadType, value, text, }) {
    return (React.createElement(Base, { style: { height: '100%', width: '100%' }, p: 6, display: "flex", alignItems: "center", justifyContent: "center" },
        React.createElement(Base, { minWidth: 240, width: '100%', maxWidth: 480, display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center" },
            React.createElement(LinearProgress, { animationTransition: false, variant: loadType, value: value }),
            React.createElement(Typography, { variant: "body2", mt: 4 }, text))));
}
