var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { intl } from '@corti/i18n';
import { withTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Typography } from 'lib/cortiUI/components/Typography';
import { useCortiUIContext } from 'lib/cortiUI/context';
/**
 * Component used to represent errors or empty state if theres no data. Content is wrapped in the Box and positioned in the center
 */
export const FallbackView = withTheme(function FallbackView(props) {
    const { title, text, devMessage, actionText, action, secondaryActionText, secondaryAction, theme } = props, boxProps = __rest(props, ["title", "text", "devMessage", "actionText", "action", "secondaryActionText", "secondaryAction", "theme"]);
    const cortiUICtx = useCortiUIContext();
    return (React.createElement(Box, Object.assign({ alignItems: "center", justifyContent: "center", height: "100%", width: "100%", px: 7 }, boxProps),
        React.createElement(Box, { maxWidth: 768, textAlign: "center" },
            React.createElement(Typography, { variant: "h3", my: 5 }, props.title || intl.t('error', 'Error')),
            text && (React.createElement(Typography, { variant: "body1", my: 4, color: "secondaryText" }, props.text)),
            cortiUICtx.debug && props.devMessage && (React.createElement(Typography, { variant: "body1", my: 4, color: "error" }, props.devMessage)),
            React.createElement(Box, { flexDirection: "row", justifyContent: "center", gap: 5, my: 4 },
                props.secondaryActionText && (React.createElement(Button, { onClick: props.secondaryAction, "data-cy": "fallback-secondary-action", color: "primary", variant: "text" }, props.secondaryActionText)),
                props.actionText && (React.createElement(Button, { onClick: props.action, "data-cy": "fallback-action", color: "primary" }, props.actionText))))));
});
