import { Observer } from 'mobx-react';
import React from 'react';
import { Route, useHistory } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { simpleIpc } from '@corti/ipc-renderer';
import { REDIRECT_ROUTE_QUERY_PARAM } from 'browser/containers';
import { AuthView } from 'browser/pages/AuthView';
import { EmergencyTriagePage } from 'browser/pages/EmergencyTriage';
import { LoginView } from 'browser/pages/LoginView';
import { SSO } from 'browser/pages/sso';
import { errorReportService } from 'browser/services/init';
import { observer } from 'browser/stores';
import { authStore } from 'core/auth';
import { Base, ErrorBoundary, FallbackView } from 'lib/cortiUI';
import { NotificationRoot } from './NotificationRoot';
import { UpdateStatusBanner } from './UpdateStatusBanner';
export const App = observer(function App(props) {
    const history = useHistory();
    React.useEffect(() => {
        // Handles routing requests from the main electron process.
        // e.g. for the deep linking functionality.
        simpleIpc.answerMain('app:redirect', (url) => {
            history.push(new URL(url).pathname.trim().replace(/^\/*/g, '/'));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Base, { "data-cy": "app", display: 'flex', flexDirection: "column", height: '100%', overflow: "hidden" },
        React.createElement(ErrorBoundary, { onError: (error) => errorReportService.captureException(error), fallbackComponent: (error) => (React.createElement(FallbackView, { style: { width: '100%', height: '100%' }, title: "Application crashed", text: "Try to reload", devMessage: error.message, actionText: "Reload", action: () => {
                    window.location.reload();
                } })) },
            props.swWrapper && React.createElement(UpdateStatusBanner, { swWrapper: props.swWrapper }),
            React.createElement(NotificationRoot, null),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/login", render: (r) => (React.createElement(Observer, null, () => {
                        if (!authStore.isAuthenticated) {
                            return React.createElement(LoginView, Object.assign({}, r));
                        }
                        return (React.createElement(Redirect, { to: {
                                pathname: '/',
                                search: r.location.search,
                            } }));
                    })) }),
                React.createElement(Route, { path: "/emergency-triage", component: EmergencyTriagePage }),
                React.createElement(Route, { path: "/sso", render: (props) => (React.createElement(Observer, null, () => {
                        if (!authStore.isAuthenticated) {
                            return React.createElement(SSO, null);
                        }
                        return (React.createElement(Redirect, { to: {
                                pathname: '/',
                                search: props.location.search,
                            } }));
                    })) }),
                React.createElement(Route, { path: "/", render: (r) => (React.createElement(Observer, null, () => {
                        if (!authStore.isAuthenticated) {
                            return (React.createElement(Redirect, { to: {
                                    pathname: '/login',
                                    // Reference to the initial route that is used to redirect user after sign in.
                                    search: `?${REDIRECT_ROUTE_QUERY_PARAM}=${encodeURIComponent(r.location.pathname + r.location.search)}`,
                                } }));
                        }
                        return React.createElement(AuthView, null);
                    })) })))));
});
