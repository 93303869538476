import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { queueMacrotask } from '@corti/timers';
import { Base, Box, useCortiUIContext } from 'lib/cortiUI';
import { useTriageSession } from '../TriageSessionContext';
import { CommentsListItem } from './CommentsListItem';
import { ContainerSection } from './ContainerSection';
export const CommentsList = observer(function CommentsList() {
    const ctx = useTriageSession();
    const { theme } = useCortiUIContext();
    const observableElRef = React.useRef(null);
    const scrollPosition = React.useRef(0);
    const containerRef = React.useRef(null);
    React.useEffect(() => {
        scrollToBottom();
        const subscriptions = [
            ctx.controller.comments.onCreate(() => {
                // Without queueing this cb is fired before new elements are rendered
                // (before mobx observer observes comment changes).
                void queueMacrotask().then(scrollToBottom);
            }),
        ];
        return () => {
            subscriptions.forEach((it) => it());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function scrollToBottom() {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }
    return (React.createElement(Box, { "data-cy": "comments-list", height: "100%", width: "100%", justifyContent: "flex-end" },
        React.createElement(Base, { ref: containerRef, position: "relative", className: css(getScrollerCss({ theme }), { overflow: 'overlay' }), onScroll: (e) => {
                scrollPosition.current = e.currentTarget.scrollTop;
            } },
            React.createElement(Base, { ref: observableElRef, width: "100%", height: 50, position: "absolute", top: 0, left: 0, zIndex: 1, className: css({
                    visibility: 'hidden',
                }) }),
            orderBy(ctx.controller.comments.all, (it) => it.datetime, 'asc').map((comment, idx) => {
                return (React.createElement(ContainerSection, { key: comment.id, pt: 4, pb: idx === ctx.controller.comments.all.length - 1 ? 6 : 4 },
                    React.createElement(CommentsListItem, { comment: comment })));
            }))));
});
