import React, { useState } from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { rbacService } from 'browser/services/init';
import { coreStore, observer } from 'browser/stores';
import { Base, useCortiUIContext } from 'lib/cortiUI';
import { useExploreViewContext } from '../ExploreViewContext';
import { TopToolbarActions } from './TopToolbarActions';
import { TopToolbarCaseProp } from './TopToolbarCaseProp';
import { TopToolbarInfo } from './TopToolbarInfo';
import { TopToolbarMergeModal } from './TopToolbarMergeModal';
import { TopToolbarRecordModal } from './TopToolbarRecordModal';
export const TopToolbar = observer(function TopToolbar({ caseViewState, className }) {
    const { theme } = useCortiUIContext();
    const { refreshCasesList } = useExploreViewContext();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [isCaseMergeViewOpen, setIsCaseMergeViewOpen] = useState(false);
    const [isScreenRecordingsViewOpen, setIsScreenRecordingsViewOpen] = useState(false);
    const onCreateFeedbackButtonClick = () => {
        handleCloseMenu();
        caseViewState.setActiveSidebarView('feedback');
        caseViewState.feedbackViewState.setActiveView('feedback-create');
    };
    const openCasesMergeView = () => {
        handleCloseMenu();
        setIsCaseMergeViewOpen(true);
    };
    const closeCasesMergeView = () => {
        setIsCaseMergeViewOpen(false);
    };
    const closeScreenRecordingsView = () => {
        setIsScreenRecordingsViewOpen(false);
    };
    const openScreenRecordingsView = () => {
        setIsScreenRecordingsViewOpen(true);
        handleCloseMenu();
    };
    const handleCasesMerge = async () => {
        var _a;
        if (caseViewState.caseEntity) {
            await caseViewState.loadCase((_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.id);
        }
        refreshCasesList();
    };
    const handleCallExcludingToggle = async () => {
        var _a, _b, _c;
        if ((_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.activeCall) {
            await caseViewState.setCallExcludedFromPerformanceMetrics(caseViewState.caseEntity.activeCall.id, !caseViewState.caseEntity.isActiveCallExcluded);
            void caseViewState.loadCase((_b = caseViewState.caseEntity) === null || _b === void 0 ? void 0 : _b.id);
            coreStore.notifications.showNotification({
                message: ((_c = caseViewState.caseEntity) === null || _c === void 0 ? void 0 : _c.isActiveCallExcluded)
                    ? intl.t('explore:caseView.includedCallNotification', 'Call included in performance tracking')
                    : intl.t('explore:caseView.excludedCallNotification', 'Call excluded from performance tracking'),
                type: 'success',
            });
        }
        refreshCasesList();
    };
    const handleOpenMenu = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };
    const { caseEntity } = caseViewState;
    if (!caseEntity) {
        return null;
    }
    const isCustomPropertyExist = Object.keys(caseEntity.customProperties).length !== 0;
    return (React.createElement(Base, { px: 8, pt: 7, pb: 5, display: "grid", gridTemplateColumns: isCustomPropertyExist ? '1fr 1fr 1fr' : '1fr auto', gap: theme.space[5], alignItems: "center", className: css(className) },
        isCustomPropertyExist && (React.createElement(TopToolbarCaseProp, { customProperties: caseEntity.customProperties })),
        React.createElement(TopToolbarInfo, { caseViewState: caseViewState }),
        React.createElement(TopToolbarActions, { caseEntity: caseEntity, handleOpenMenu: handleOpenMenu, menuAnchorEl: menuAnchorEl, handleCloseMenu: handleCloseMenu, handleCallExcludingToggle: handleCallExcludingToggle, openCasesMergeView: openCasesMergeView, onCreateFeedbackButtonClick: onCreateFeedbackButtonClick, openScreenRecordingsView: openScreenRecordingsView }),
        React.createElement(TopToolbarMergeModal, { caseEntity: caseEntity, isCaseMergeViewOpen: isCaseMergeViewOpen, closeCasesMergeView: closeCasesMergeView, handleCasesMerge: handleCasesMerge }),
        rbacService.appScreenRecorder() && (React.createElement(TopToolbarRecordModal, { caseEntity: caseEntity, isScreenRecordingsViewOpen: isScreenRecordingsViewOpen, closeScreenRecordingsView: closeScreenRecordingsView }))));
});
