import React from 'react';
import { intl } from '@corti/i18n';
import { Autocomplete, MenuItem, TextField } from 'lib/cortiUI';
export const EntitySelector = (props) => {
    const { entities, selectedEntityID, onChange } = props;
    const onSelect = (newItem) => {
        onChange(newItem.value);
    };
    const transformedEntities = entities.map((entity) => {
        return {
            value: entity.id,
            text: entity.text,
        };
    });
    const getSelectedEntity = () => {
        if (selectedEntityID) {
            return transformedEntities.find((e) => e.value === selectedEntityID);
        }
    };
    return (React.createElement(Autocomplete, { "data-cy": "entity-selector", options: transformedEntities, getOptionLabel: (option) => option.text, value: getSelectedEntity(), onChange: (_, option) => {
            if (option) {
                onSelect(option);
            }
        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: intl.t('filterBuilder.selectFilter', 'Select Filter...') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "select-item", selected: state.selected, key: option.value }), option.text)), clearIcon: null, sx: { minWidth: 220 } }));
};
