import { observer } from 'mobx-react';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { Box, useCortiUIContext } from 'lib/cortiUI';
import { FlowOverviewStep } from './FlowOverviewStep';
import { createStep } from './utils';
export const FlowOverview = observer(function FlowOverview({ flowStore, }) {
    const { theme } = useCortiUIContext();
    const containerEl = React.useRef(null);
    const [steps, setSteps] = React.useState([]);
    React.useEffect(() => {
        function generateSteps() {
            return flowStore.stepStore.activePath.map((it) => createStep(it, flowStore));
        }
        setSteps(generateSteps());
        const unsubscribers = [
            flowStore.stepStore.onActivePathChanged(() => setSteps(generateSteps())),
            flowStore.blockValueStore.onUpdate(() => setSteps(generateSteps())),
            flowStore.collectorStore.onCollectorValuesUpdated(() => setSteps(generateSteps())),
        ];
        return () => {
            unsubscribers.forEach((it) => it());
        };
    }, [flowStore]);
    React.useEffect(() => {
        if (containerEl.current) {
            containerEl.current.scrollTop = containerEl.current.scrollHeight;
        }
    }, [steps]);
    return (React.createElement(Box, { "data-cy": "overview-container", ref: containerEl, tabIndex: -1, width: "100%", height: "100%", alignItems: "center", className: css(getScrollerCss({ theme }), {
            backgroundColor: theme.palette.background.default,
            overflow: 'overlay',
        }) }, steps.map((it, index) => {
        var _a;
        return (React.createElement(FlowOverviewStep, { key: it.id, step: it, isActive: ((_a = flowStore.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.id) === it.id, className: css({
                maxWidth: 600,
                marginTop: index === 0 ? theme.space[10] : undefined,
                marginBottom: index === flowStore.stepStore.activePath.length - 1
                    ? theme.space[10]
                    : theme.space[6],
            }) }));
    })));
});
