import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getTemplateById } from '@corti/mission-control-api';
import { Base, Chip, Typography } from 'lib/cortiUI';
export const Template = ({ templateID }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.reviews' });
    const [isLoading, setIsLoading] = React.useState(false);
    const [name, setName] = React.useState('');
    React.useEffect(() => {
        void fetchTemplate(templateID);
    }, [templateID]);
    async function fetchTemplate(templateID) {
        setIsLoading(true);
        try {
            const response = await getTemplateById(templateID);
            setName(response.name);
            setIsLoading(false);
        }
        catch (error) {
            setName('');
            console.error('failed to fetch template:', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 3 },
        React.createElement(Typography, { variant: "body2" }, t('template', 'Template')),
        !isLoading && React.createElement(Chip, { label: name })));
};
