import CaseReviewRequestsApp from 'browser/pages/CaseReviewRequestsView';
import ExploreApp from 'browser/pages/ExploreApp';
import HomeApp from 'browser/pages/HomeApp';
import TheFinderApp from 'browser/pages/TheFinder';
import TranscriptSearchApp from 'browser/pages/TranscriptSearchView';
import AnalyticsApp from 'browser/pages/analytics';
import MissionControlApp from 'browser/pages/missionControl';
import PerformanceApp from 'browser/pages/performance';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { moduleRegistry } from 'core/moduleRegistry/browser';
import Editor2App from 'modules/EditorApp2/browser';
import RealTimeApp from 'modules/RealTimeApp';
const registeredAppIDs = [];
export function loadModules() {
    function tryRegisterModule(mod) {
        if ((!mod.requiresOrgFeature || authStore.isFeatureEnabled(mod.requiresOrgFeature)) &&
            (!mod.requiresUserPermission || rbacService.hasPermission(mod.requiresUserPermission))) {
            const id = moduleRegistry.registerModule(mod);
            registeredAppIDs.push(id);
        }
    }
    authStore.onLoaded(() => {
        // apps - the order of the modules matters
        // Group 0
        tryRegisterModule(RealTimeApp);
        tryRegisterModule(Editor2App);
        // Group 1
        tryRegisterModule(HomeApp);
        tryRegisterModule(ExploreApp);
        tryRegisterModule(CaseReviewRequestsApp);
        tryRegisterModule(PerformanceApp);
        tryRegisterModule(TranscriptSearchApp);
        tryRegisterModule(TheFinderApp);
        tryRegisterModule(AnalyticsApp);
        tryRegisterModule(MissionControlApp);
    });
    authStore.onLogout(() => {
        registeredAppIDs.forEach((id) => {
            moduleRegistry.unregisterModule(id);
        });
    });
}
