import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { withdrawInteractionReviewRequest } from '@corti/mission-control-api';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Base, Button, Card, ChipV2, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, } from 'lib/cortiUI';
import { useInteraction } from '../../context';
import { ManagerActions } from './ManagerActions';
export const ReviewRequested = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'acknowledgementStatus.reviewRequested' });
    const { interaction, refetchInteraction } = useInteraction();
    const [showReason, setShowReason] = React.useState(false);
    const theme = useTheme();
    const { currentUserID } = useAuth();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleWithdrawReviewRequest() {
        try {
            if (!interaction)
                return;
            setIsLoading(true);
            await withdrawInteractionReviewRequest(interaction.id);
            void refetchInteraction();
            coreStore.notifications.showNotification({
                message: t('withdrawReviewRequestSuccess', 'Review request Withdrawn'),
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('withdrawReviewRequestError', 'Failed to withdraw review request'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    if (!interaction)
        return null;
    return (React.createElement(Card, { display: "flex", flexDirection: "column", gap: 5, p: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(ChipV2, { color: "warning", variant: "outlined", label: t('chipLabel', 'Review Requested') }),
            currentUserID === interaction.interaction_owner && (React.createElement(Base, null,
                React.createElement(IconButton, { icon: React.createElement(MoreHorizRoundedIcon, null), size: "small", onClick: ({ currentTarget }) => setMenuAnchorEl(currentTarget) }),
                React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
                    React.createElement(MenuItem, { onClick: handleWithdrawReviewRequest, disabled: isLoading },
                        React.createElement(ListItemIcon, null,
                            React.createElement(KeyboardReturnRoundedIcon, null)),
                        React.createElement(ListItemText, { primary: t('withdrawReviewRequest', 'Withdraw Review Request') })))))),
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { variant: "body2", color: "default" }, currentUserID !== interaction.interaction_owner &&
                rbacService.missionControlOtherAssessmentsRead()
                ? t('agentRequestedReview', 'The Agent requested a review for this interaction.')
                : t('youRequestedReview', 'You’ve requested a review for this interaction.')),
            React.createElement(Button, { size: "small", variant: "text", onClick: () => setShowReason((prev) => !prev) }, showReason ? t('hideDetails', 'hide details') : t('details', 'details'))),
        showReason && (React.createElement(Card, { display: "flex", flexDirection: "column", gap: 5, background: theme.palette.background.default, style: { boxShadow: 'none', border: 'none' } },
            React.createElement(Typography, { variant: "body2", color: "default" }, interaction.request_review_reason),
            React.createElement(Typography, { variant: "body2" },
                t('sentOn', {
                    defaultValue: 'Sent on ',
                }),
                formatDateTime(new Date(interaction.acknowledgement_status_updated_at))))),
        currentUserID !== interaction.interaction_owner &&
            rbacService.missionControlOtherAssessmentsRead() && React.createElement(ManagerActions, null)));
};
