import React from 'react';
import { Base, Card, Typography } from 'lib/cortiUI';
import { ChoiceInput } from './ChoiceInput';
export const Question = ({ id, text, answer_type, required }) => {
    function renderInput() {
        switch (answer_type) {
            case 'SINGLE_LINE':
            case 'MULTI_LINE':
            // return <TextInput answer_type={answer_type} />;
            case 'DROPDOWN':
                return null;
            case 'MULTIPLE_CHOICE':
                return React.createElement(ChoiceInput, { question_id: id, answer_type: answer_type });
        }
    }
    return (React.createElement(Card, { key: id, p: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Typography, { variant: "body2", color: "default" },
                text,
                required && (React.createElement(Typography, { color: "error", pl: 1 }, '*'))),
            renderInput())));
};
