import { useLazyQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { orderBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useDelayedQuery } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { Button, Container, DefaultCellRenderer, DefaultRowRenderer, DefaultValueRenderer, Dialog, FallbackView, IconButton, LinearProgress, Page, PageHeader, PageHeaderActionsSection, PageHeaderDescription, PageHeaderTitle, State, Table, TableColumn, } from 'lib/cortiUI';
import { RouteBreadcrumb } from 'lib/router';
import { AddTeamMemberView as AddTeamMembersView } from './AddTeamMembers';
import { RemoveTeamMemberView } from './RemoveTeamMemberView';
import * as GQL from './graphql';
const TABLE_COL_USER = 'user';
const TABLE_COL_STATUS = 'status';
const TABLE_COL_ACTIONS = 'columnActions';
export function TeamView(props) {
    var _a, _b;
    const { teamID } = props;
    const theme = useTheme();
    const { t } = useTranslation('teams');
    const getTeamQuery = useDelayedQuery(GQL.getTeamQuery, {
        variables: {
            id: teamID,
        },
    });
    if (getTeamQuery.error) {
        return React.createElement(FallbackView, { title: "Error", text: getTeamQuery.error.message });
    }
    const columns = [
        { id: TABLE_COL_USER, label: t('teamView.table.user', 'Call-Taker') },
        { id: TABLE_COL_STATUS, label: t('teamView.table.status', 'Status') },
        { id: TABLE_COL_ACTIONS, label: '' },
    ];
    const team = (_a = getTeamQuery.data) === null || _a === void 0 ? void 0 : _a.team;
    const rowData = orderBy(((_b = team === null || team === void 0 ? void 0 : team.members) !== null && _b !== void 0 ? _b : []).map((member) => ({
        [TABLE_COL_USER]: member.user.name,
        [TABLE_COL_STATUS]: member.user.deletedAt
            ? t('teamView.table.statusDeleted', 'Archived')
            : t('teamView.table.statusActive', 'Active'),
        __model: member,
    })), TABLE_COL_USER, 'asc');
    if (!team) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(RouteBreadcrumb, { breadcrumb: team.name }),
        React.createElement(Page, { "data-cy": "team-view" },
            React.createElement(Container, null,
                React.createElement(PageHeader, null,
                    React.createElement(PageHeaderActionsSection, null,
                        React.createElement(State, { initialState: false }, ([open, setOpen]) => (React.createElement(React.Fragment, null,
                            React.createElement(Button, { sx: { color: theme.palette.text.primary }, "data-cy": "add-team-member-btn", disabled: !rbacService.teamsCRUD(), variant: "text", onClick: () => {
                                    setOpen(true);
                                }, startIcon: React.createElement(AddRoundedIcon, { fontSize: "small" }) }, t('teamView.addMemberBtn', 'Add Call-Taker')),
                            rbacService.teamsCRUD() && (React.createElement(Dialog, { open: open, onClose: () => setOpen(false), title: t('teamView.addMembers.title', 'Add user to team'), helperText: t('teamView.addMembers.helperText', 'Start typing a user name or e-mail to add them to the team. Adding a user to a team does not provide them access rights.') },
                                React.createElement(AddTeamMembersView, { onSuccess: () => {
                                        setOpen(false);
                                        void getTeamQuery.refetch();
                                    }, team: team }))))))),
                    React.createElement(PageHeaderDescription, null, team ? team.description : '...'),
                    React.createElement(PageHeaderTitle, null, team ? team.name : '...'))),
            getTeamQuery.delayedLoading && (React.createElement(LinearProgress, { variant: "indeterminate", style: { position: 'fixed', top: 0, left: 0, right: 0 } })),
            React.createElement(Container, null,
                React.createElement(Table, { rows: rowData, height: Infinity, columns: columns, disableSort: true, disableResize: true, rowRenderer: (p) => (React.createElement(DefaultRowRenderer, { "data-cy": "team-members-table-row", key: p.index, requiredProps: p })) }, ({ columns }) => {
                    return columns.map((col, idx) => {
                        return (React.createElement(TableColumn, { key: idx, label: col.label, dataKey: col.id, flexGrow: col.id === TABLE_COL_ACTIONS ? 0 : 1, width: 100, cellRenderer: (p) => {
                                const row = p.rowData;
                                const member = row.__model;
                                return (React.createElement(DefaultCellRenderer, { requiredProps: p, valueRenderer: (v) => {
                                        if (col.id === TABLE_COL_ACTIONS) {
                                            if (rbacService.teamsCRUD()) {
                                                return (React.createElement(ActionCellValueRenderer, { teamID: teamID, memberID: member.id }));
                                            }
                                            else {
                                                return null;
                                            }
                                        }
                                        return (React.createElement(DefaultValueRenderer, { value: v, color: member.user.deletedAt ? 'disabled' : undefined }));
                                    } }));
                            } }));
                    });
                })))));
}
function ActionCellValueRenderer(props) {
    const { t } = useTranslation('teams');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [getTeamQuery] = useLazyQuery(GQL.getTeamQuery, {
        variables: {
            id: props.teamID,
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { "data-cy": "remove-team-member-btn", className: css({ marginLeft: 'auto' }), icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), onClick: () => setModalOpen(true) }),
        React.createElement(Dialog, { "data-cy": "remove-team-member-dialog", title: t('removeUserView.title', 'Remove user?'), helperText: t('removeUserView.message', 'You are about to remove a user from the team. This will affect all related places where the team is being used. The action cannot be undone.'), open: modalOpen, onClose: () => setModalOpen(false) },
            React.createElement(RemoveTeamMemberView, { memberID: props.memberID, teamID: props.teamID, onSuccess: () => {
                    setModalOpen(false);
                    getTeamQuery();
                } }))));
}
