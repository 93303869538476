import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { Base, Container, Page } from 'lib/cortiUI';
import { Route } from 'lib/router';
import { CaseView } from '../CaseView';
import { AssessmentFeedback, Chart, GeneralPerformance, Parameters, QuestionsList, ReviewsDataGrid, } from './containers';
import { ParametersProvider, TemplateProvider } from './context';
export const MissionControlApp = () => {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: match.path, exact: true, render: () => (React.createElement(Page, null,
                React.createElement(Container, null,
                    React.createElement(TemplateProvider, null,
                        React.createElement(ParametersProvider, null,
                            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 },
                                React.createElement(Parameters, null),
                                React.createElement(Base, { display: "flex", gap: 6 },
                                    React.createElement(Base, { flex: 1 },
                                        React.createElement(GeneralPerformance, null)),
                                    React.createElement(Base, { flex: 2 },
                                        React.createElement(Chart, null))),
                                React.createElement(AssessmentFeedback, null),
                                React.createElement(QuestionsList, null),
                                React.createElement(ReviewsDataGrid, null))))))) }),
        React.createElement(Route, { path: `${match.path}/:caseID`, render: () => React.createElement(CaseView, null), breadcrumb: ({ match }) => {
                return `${match.params.caseID.substring(0, 8).toLocaleUpperCase()}`;
            } })));
};
