var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useMousetrap } from '@corti/react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Avatar, Base, Button, Popover, Typography } from 'lib/cortiUI';
import { ConnectedUsers } from './ConnectedUsers';
import { useTriageSession } from './TriageSessionContext';
const OPEN_CONNECTED_USERS_VIEW_KEY = 'u';
export const ConnectedUsersWidget = observer(function ConnectedUsersWidget(props) {
    var _a;
    const { className, keyboardShortcut } = props, rest = __rest(props, ["className", "keyboardShortcut"]);
    const theme = useTheme();
    const ctx = useTriageSession();
    const spectators = ctx.controller.connectedUsers.all.filter((user) => {
        return user.id !== ctx.controller.ownerUser.id;
    });
    const [buttonRef, setButtonRef] = React.useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    useMousetrap({
        keys: (_a = keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.open) !== null && _a !== void 0 ? _a : OPEN_CONNECTED_USERS_VIEW_KEY,
        handle: () => {
            if (!(keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.disabled)) {
                setIsPopoverOpen(true);
            }
        },
    }, [keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.disabled]);
    const closePopover = (event) => {
        setIsPopoverOpen(false);
        ctx.controller.flow.observer.fireEvent('toolbar-element-blurred', {
            event: 'toolbar-element-blurred',
            data: {
                datetime: new Date().toUTCString(),
                event,
            },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, Object.assign({ "data-cy": "connected-users-widget", ref: (ref) => setButtonRef(ref), onClick: () => setIsPopoverOpen(true), sx: {
                padding: 1,
                borderRadius: 100,
                border: `1px solid ${theme.palette.background.divider}`,
                alignItems: 'center',
                backgroundColor: isPopoverOpen
                    ? transparentize(0.92, theme.palette.primary.main)
                    : theme.palette.background.card,
                color: isPopoverOpen
                    ? theme.palette.primary.main
                    : transparentize(0.48, theme.palette.text.primary),
                '&:hover': {
                    backgroundColor: transparentize(0.92, theme.palette.primary.main),
                    color: theme.palette.primary.main,
                },
                boxShadow: 'none !important',
            }, className: css(className) }, rest),
            React.createElement(Base, { flex: 1, display: "grid", gridGap: theme.space[2], gridAutoFlow: "column" },
                React.createElement(Avatar, { size: "tiny", titleText: ctx.controller.ownerUser.name }),
                spectators.length > 0 && (React.createElement(Typography, { "data-cy": "connected-users-counter", color: "default", noWrap: true, variant: "button" },
                    "+",
                    spectators.length > 99 ? '99' : spectators.length)),
                React.createElement(ArrowDropDownRoundedIcon, { className: css({
                        marginLeft: -6,
                    }) }),
                ctx.controller.takeoverRequests.received.length > 0 && (React.createElement(Base, { className: css({
                        position: 'absolute',
                        top: '0%',
                        right: '0%',
                        zIndex: 1,
                        height: 8,
                        width: 8,
                        borderRadius: 4,
                        backgroundColor: theme.palette.primary.main,
                    }) })))),
        React.createElement(Popover, { open: isPopoverOpen, anchorEl: buttonRef, onClose: closePopover, elevation: 0, sx: {
                '& MuiPopover-paper': { borderRadius: 0 },
            }, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            } },
            React.createElement(ConnectedUsers, null))));
});
