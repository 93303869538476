import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { usePrevious } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { rbacService } from 'browser/services/init';
import { Base, Box, Button, Separator, Typography, useCortiUIContext } from 'lib/cortiUI';
import { AllColumnsList } from './AllColumnsList';
import { SelectedColumnsList } from './SelectedColumnsList';
export function CasesTableEditorView(props) {
    const { casesTableEditorState, onSave } = props;
    const { allColumnsDictionary } = casesTableEditorState;
    const selectedColumnsListScroller = React.useRef(null);
    const { theme } = useCortiUIContext();
    const [selectedColumnsIDs, setSelectedColumnsIDs] = React.useState([]);
    const prevSelectedColumnsIDs = usePrevious(selectedColumnsIDs);
    React.useEffect(() => {
        const tableColumnIDs = getTableColumnIDs();
        setSelectedColumnsIDs(tableColumnIDs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (selectedColumnsListScroller.current &&
            prevSelectedColumnsIDs.length < selectedColumnsIDs.length) {
            selectedColumnsListScroller.current.scrollTop =
                selectedColumnsListScroller.current.scrollHeight;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumnsIDs]);
    const onColumnSelect = (columnID) => {
        setSelectedColumnsIDs((ids) => [...ids, columnID]);
    };
    const onColumnRemove = (columnID) => {
        setSelectedColumnsIDs((ids) => ids.filter((id) => id !== columnID));
    };
    const handleSave = () => {
        const tableColumns = getTableColumns(selectedColumnsIDs);
        void casesTableEditorState.updateTableColumns(tableColumns);
        onSave && onSave();
    };
    const handleSaveAsDefault = () => {
        const tableColumns = getTableColumns(selectedColumnsIDs);
        void casesTableEditorState.updateDefaultTableColumns(tableColumns);
    };
    const handleReset = () => {
        void casesTableEditorState.resetTableColumnsToDefault();
        const tableColumnIDs = getTableColumnIDs();
        setSelectedColumnsIDs(tableColumnIDs);
        onSave && onSave();
    };
    const getTableColumns = (columnIDs) => {
        return columnIDs.map((id) => {
            const column = casesTableEditorState.tableColumns.find((col) => col.id === id);
            if (column) {
                return column;
            }
            else {
                return {
                    id: id,
                };
            }
        });
    };
    const getTableColumnIDs = () => {
        const { tableColumns } = props.casesTableEditorState;
        return tableColumns.map((col) => col.id);
    };
    return (React.createElement(Base, { display: "grid", "data-cy": "table-editor-view" },
        React.createElement(Typography, { variant: "body2" }, intl.t('explore:casesTableEditorView.text', 'Drag & drop the Data to adjust your personal table.')),
        React.createElement(Separator, { mt: 7, horizontal: true }),
        React.createElement(Base, { height: 320, display: "grid", gridTemplateColumns: "1fr auto 1fr" },
            React.createElement(Base, { mt: 6, display: "grid", gridTemplateRows: "auto 1fr", overflow: "hidden" },
                React.createElement(Box, { mx: 7, mb: 6, flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { color: "default", variant: "caption" }, intl.t('explore:casesTableEditorView.yourColumns', 'Your columns')),
                    React.createElement(Typography, { variant: "caption" }, `(${intl.t('explore:casesTableEditorView.dragToReorder', 'Drag to reorder')})`)),
                React.createElement(Base, { ref: (c) => (selectedColumnsListScroller.current = c), className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
                    React.createElement(Base, { mx: 7 },
                        React.createElement(SelectedColumnsList, { columnsDictionary: allColumnsDictionary, selectedColumnsIDs: selectedColumnsIDs, onOrderChange: setSelectedColumnsIDs, onRemove: onColumnRemove })))),
            React.createElement(Separator, null),
            React.createElement(Base, { mt: 6, display: "grid", gridTemplateRows: "auto 1fr", overflow: "hidden" },
                React.createElement(Box, { mx: 7, mb: 6, flexDirection: "row", alignItems: "center" },
                    React.createElement(Typography, { color: "default", variant: "caption" }, intl.t('explore:casesTableEditorView.data', 'Data'))),
                React.createElement(Base, { className: css(getScrollerCss({ theme: theme })) },
                    React.createElement(Base, { mx: 7 },
                        React.createElement(AllColumnsList, { columnsDictionary: allColumnsDictionary, selectedColumnsIDs: selectedColumnsIDs, onSelect: onColumnSelect, onRemove: onColumnRemove }))))),
        React.createElement(Box, { pt: 7, flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Button, { variant: "text", onClick: handleReset, "data-cy": "table-editor-reset-to-default-btn", startIcon: React.createElement(UndoRoundedIcon, { fontSize: "small" }) }, intl.t('explore:casesTableEditorView.resetDefault', 'Reset')),
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                rbacService.casesColumnLayoutCRUD() && (React.createElement(Button, { className: css({ marginRight: 3 }), variant: "text", onClick: handleSaveAsDefault, "data-cy": "table-editor-save-as-default-btn" }, intl.t('explore:casesTableEditorView.setDefault', 'Set as organization default'))),
                React.createElement(Button, { color: "primary", onClick: handleSave, "data-cy": "table-editor-save-btn" }, intl.t('explore:casesTableEditorView.save', 'Save columns'))))));
}
