import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getDefaultTemplate } from '@corti/mission-control-api';
import { Base, IconButton, Typography } from 'lib/cortiUI';
import { Template } from '../../Assessment/Template';
import { useNavigation } from '../../context';
import { DiscardDialog } from '../components';
import { useReview } from '../context';
export function Header() {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.manual' });
    const { templateID, setTemplateID, reviewID } = useReview();
    const { setView } = useNavigation();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function fetchTemplates() {
        setIsLoading(true);
        try {
            const res = await getDefaultTemplate();
            if (res && !templateID) {
                setTemplateID(res.id);
            }
        }
        catch (e) {
            console.error('[Case view]: Could not fetch template', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    function handleNavigateBack() {
        if (reviewID) {
            setIsDialogOpen(true);
        }
        else {
            setView('assessment');
        }
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
        React.createElement(Base, { display: "flex", gap: 2, alignItems: "center" },
            React.createElement(IconButton, { onClick: handleNavigateBack, icon: React.createElement(ArrowBackIosOutlinedIcon, null), sx: { m: 1 } }),
            React.createElement(Typography, { variant: "h6", color: "default" }, t('manualAssessment', 'Manual Assessment'))),
        !isLoading && templateID && React.createElement(Template, { templateID: templateID }),
        React.createElement(DiscardDialog, { isOpen: isDialogOpen, onClose: () => setIsDialogOpen(false), onDiscard: () => {
                setIsDialogOpen(false);
                setView('assessment');
            } })));
}
