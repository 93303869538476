import { intl } from '@corti/i18n';
export const getPerformanceStatusColor = (value, thresholds, palette) => {
    return value <= thresholds.min
        ? palette.error.light
        : value > thresholds.max
            ? palette.success.light
            : palette.warning.light;
};
export const getPerformanceStatusLabel = (value, thresholds) => {
    if (value <= thresholds.min) {
        return intl.t('missionControlApp:performanceStatus.nonCompliant', 'Non Compliant');
    }
    else if (value > thresholds.max) {
        return intl.t('missionControlApp:performanceStatus.compliant', 'Compliant');
    }
    else {
        return intl.t('missionControlApp:performanceStatus.partiallyCompliant', 'Partially Compliant');
    }
};
