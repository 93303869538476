import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box';
import { Chip } from 'lib/cortiUI/components/Chip';
import { useCortiUIContext } from 'lib/cortiUI/context';
import { Base } from '../Base';
export function MultipleValueRenderer(props) {
    const { theme } = useCortiUIContext();
    if (props.selectedValue.length) {
        return (React.createElement(Base, { "data-cy": "multiple-value-renderer", className: css({ maxHeight: 300 }, getScrollerCss({ theme: theme })) },
            React.createElement(Box, { flexDirection: "row", flexWrap: "wrap" }, props.selectedValue.map((item) => {
                return (React.createElement(Chip, { key: item.value, label: item.text, onDelete: () => {
                        const next = props.selectedValue.filter((i) => i !== item);
                        props.onChange(next);
                    }, m: 2 }));
            }))));
    }
    return null;
}
