import { useQuery } from '@apollo/client';
import React from 'react';
import { getReviewsByCall } from '@corti/mission-control-api';
import { useAuth } from 'core/auth/browser';
import { Base } from 'lib/cortiUI';
import { SectionWrapper } from '../../components';
import { ActiveReview } from './ActiveReview';
import { AssessmentLoading } from './AssessmentLoading';
import { Header } from './Header';
import { NoAssessment } from './NoAssessment';
import { Reviews } from './Reviews';
import { Template } from './Template';
import { getUsersQuery } from './graphql';
export const Assessment = ({ callID, caseOwner }) => {
    const auth = useAuth();
    const [selectedReview, setSelectedReview] = React.useState();
    const [reviews, setReviews] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const { data } = useQuery(getUsersQuery, {
        variables: { orgID: auth.organization.id },
    });
    React.useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.users.length) {
            void fetchReview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callID, data === null || data === void 0 ? void 0 : data.users.length]);
    async function fetchReview() {
        var _a;
        setIsLoading(true);
        try {
            const res = await getReviewsByCall(callID);
            const modifiedRes = res.map((review) => {
                var _a;
                return (Object.assign(Object.assign({}, review), { created_by: (_a = data === null || data === void 0 ? void 0 : data.users.find((user) => user.id === review.created_by)) !== null && _a !== void 0 ? _a : undefined }));
            });
            setReviews(modifiedRes);
            if (!!modifiedRes.length) {
                setSelectedReview((_a = modifiedRes.find((review) => review.latest)) !== null && _a !== void 0 ? _a : modifiedRes[0]);
            }
        }
        catch (e) {
            console.error('Could not fetch a review', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading)
        return React.createElement(AssessmentLoading, null);
    if (!reviews.length || !selectedReview)
        return React.createElement(NoAssessment, { caseOwnerID: caseOwner.id });
    return (React.createElement(SectionWrapper, { display: "flex", flexDirection: "column", gap: 6, overflow: "hidden" },
        React.createElement(Header, { caseOwnerID: caseOwner.id }),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, pt: 2, pr: 2 },
            React.createElement(Template, { templateID: selectedReview.template_id }),
            React.createElement(Reviews, { reviews: reviews, selectedReview: selectedReview, onSelectReview: setSelectedReview })),
        React.createElement(ActiveReview, { selectedReview: selectedReview })));
};
