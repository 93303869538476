import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import React from 'react';
import { mix } from '@corti/style';
import { useCortiUIContext } from 'lib/cortiUI';
import { getValueProgressBarColor } from '../utils';
highchartsMore(Highcharts);
solidGauge(Highcharts);
const DEFAULT_SIZE = 120;
export const ProgressBar = React.memo(function Progress(props) {
    const { size = DEFAULT_SIZE, value = 0, target, onValueMouseOut, onValueMouseOver, onTargetMouseOut, onTargetMouseOver, disableAnimation = false, } = props;
    const { theme } = useCortiUIContext();
    function getOptions() {
        const isDark = theme.palette.mode === 'dark';
        const paneColor = isDark ? theme.palette.grey[700] : theme.palette.grey[300];
        const seriesColor = getValueProgressBarColor({ value, target, theme });
        const options = {
            chart: {
                animation: !disableAnimation,
                type: 'solidgauge',
                height: size,
                width: size,
                backgroundColor: 'transparent',
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0,
                spacingTop: 0,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
            },
            tooltip: {
                enabled: false,
            },
            pane: {
                size: '100%',
                startAngle: 0,
                endAngle: 360,
                background: [
                    {
                        outerRadius: '96%',
                        innerRadius: '84%',
                        backgroundColor: paneColor,
                        borderWidth: 0,
                    },
                ],
            },
            yAxis: {
                min: 0,
                max: 1,
                lineWidth: 0,
                tickPositions: [],
            },
            plotOptions: {
                series: {
                    animation: {
                        defer: 0,
                        duration: disableAnimation ? 0 : 1000,
                    },
                },
                solidgauge: {
                    dataLabels: {
                        enabled: false,
                    },
                    rounded: true,
                },
            },
            series: [
                {
                    type: 'solidgauge',
                    zIndex: 1,
                    data: [
                        {
                            color: seriesColor,
                            radius: '96%',
                            innerRadius: '84%',
                            y: value,
                        },
                    ],
                    events: {
                        mouseOut() {
                            onValueMouseOut === null || onValueMouseOut === void 0 ? void 0 : onValueMouseOut();
                        },
                        mouseOver() {
                            onValueMouseOver === null || onValueMouseOver === void 0 ? void 0 : onValueMouseOver();
                        },
                    },
                },
            ],
        };
        if (target != undefined && options.series) {
            options.series.push({
                type: 'solidgauge',
                zIndex: 0,
                data: [
                    {
                        color: mix(0.26, seriesColor, theme.palette.background.card),
                        radius: '100%',
                        innerRadius: '80%',
                        y: target,
                    },
                ],
                events: {
                    mouseOut() {
                        onTargetMouseOut === null || onTargetMouseOut === void 0 ? void 0 : onTargetMouseOut();
                    },
                    mouseOver() {
                        onTargetMouseOver === null || onTargetMouseOver === void 0 ? void 0 : onTargetMouseOver();
                    },
                },
            });
        }
        return options;
    }
    return React.createElement(HighchartsReact, { highcharts: Highcharts, options: getOptions() });
});
