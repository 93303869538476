import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { css, transparentize } from '@corti/style';
import { Base } from 'lib/cortiUI/components/Base';
import { Card } from 'lib/cortiUI/components/Card';
import { IconButton } from 'lib/cortiUI/components/IconButton';
import { Popover } from 'lib/cortiUI/components/Popover';
import { useCortiUIContext } from 'lib/cortiUI/context';
import { DefaultMultiValueRenderer } from './DefaultMultiValueRenderer';
export function DefaultMultiValueController(props) {
    var _a;
    const { value = [], valueRenderer } = props;
    const { theme } = useCortiUIContext();
    const $container = React.useRef();
    const $content = React.useRef();
    const contentWidthSnapshots = React.useRef([]).current;
    const [hiddenValue, setHiddenValue] = React.useState([]);
    const [hiddenValueViewAnchor, setHiddenValueViewAnchor] = React.useState(null);
    React.useEffect(() => {
        if ($content.current && $container.current) {
            const containerWidth = $container.current.clientWidth;
            const contentWidth = $content.current.clientWidth;
            if (containerWidth < contentWidth) {
                contentWidthSnapshots.unshift(contentWidth);
                setHiddenValue((val) => {
                    val.push(getFilteredValue()[getFilteredValue().length - 1]);
                    return val;
                });
            }
            if (typeof contentWidthSnapshots[0] === 'number' &&
                containerWidth >= contentWidthSnapshots[0]) {
                contentWidthSnapshots.shift();
                setHiddenValue((val) => {
                    val.pop();
                    return val;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = $container.current) === null || _a === void 0 ? void 0 : _a.clientWidth]);
    const getFilteredValue = () => {
        return value.filter((val) => !hiddenValue.includes(val));
    };
    const showMoreButton = () => {
        return hiddenValue.length > 0;
    };
    const getValueRenderer = (value) => {
        if (valueRenderer) {
            return valueRenderer(value);
        }
        else {
            return React.createElement(DefaultMultiValueRenderer, { value: value });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { ref: (ref) => ($container.current = ref), width: "100%" },
            React.createElement(Base, { ref: (ref) => ($content.current = ref), display: "grid", gridTemplateColumns: "auto", gridAutoFlow: "column", width: "fit-content", alignItems: "center", gridGap: theme.space[2] },
                getFilteredValue().map((val, idx) => {
                    return (React.createElement(Base, { key: idx, width: "fit-content", className: css({ maxWidth: 180 }) }, getValueRenderer(val)));
                }),
                showMoreButton() && (React.createElement(IconButton, { className: css({
                        backgroundColor: transparentize(0.1, theme.palette.action.disabled),
                        '&:hover': {
                            backgroundColor: theme.palette.action.disabled,
                        },
                    }), icon: React.createElement(MoreHorizRoundedIcon, null), size: "small", onClick: (e) => {
                        e.stopPropagation();
                        setHiddenValueViewAnchor(e.currentTarget);
                    } })))),
        React.createElement(Popover, { anchorEl: hiddenValueViewAnchor, open: Boolean(hiddenValueViewAnchor), onClose: () => setHiddenValueViewAnchor(null), onBackdropClick: (e) => e.stopPropagation(), anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
            } },
            React.createElement(Card, { p: 5, elevation: 10, onClick: (e) => e.stopPropagation() },
                React.createElement(Base, { ref: (ref) => ($content.current = ref), display: "grid", gridTemplateRows: "auto", gridGap: theme.space[2] }, hiddenValue.map((val, idx) => {
                    return (React.createElement(Base, { key: idx, width: "fit-content", className: css({ maxWidth: 100 }) }, getValueRenderer(val)));
                }))))));
}
