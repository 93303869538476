import { useQuery } from '@apollo/client';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { Base, FallbackView, Separator } from 'lib/cortiUI';
import { Call } from './Call';
import { Metadata } from './Metadata';
import { RightSidePanel } from './RightSidePanel';
import { SectionWrapper } from './components';
import { getCaseCallValidationQuery, } from './graphql';
export const CaseView = () => {
    const { t } = useTranslation('caseView');
    const { params: { caseID }, } = useRouteMatch();
    if (!caseID) {
        throw Error('`caseID` must be provided as Route parameters to use `Case View` component ');
    }
    const { data, loading } = useQuery(getCaseCallValidationQuery, {
        variables: {
            id: caseID,
        },
    });
    if (loading) {
        // Request is really fast, so trying to avoid flushing
        return null;
    }
    if (!(data === null || data === void 0 ? void 0 : data.case)) {
        return React.createElement(FallbackView, { title: t('noCaseData', 'Could not load case') });
    }
    // It is product/design decision to show only first call if it exists
    const call = (data === null || data === void 0 ? void 0 : data.case.calls.length) ? data.case.calls[0] : undefined;
    return (React.createElement(Base, { display: "grid", gridTemplateColumns: call ? '1fr auto 3fr auto 2fr' : '1fr auto 5fr', overflow: "hidden" },
        React.createElement(SectionWrapper, null,
            React.createElement(Metadata, { caseID: caseID, callID: call === null || call === void 0 ? void 0 : call.id })),
        React.createElement(Separator, null),
        call ? (React.createElement(React.Fragment, null,
            React.createElement(SectionWrapper, null,
                React.createElement(Call, { callID: call.id, callServiceCallId: call.callServiceCallId })),
            React.createElement(Separator, null),
            React.createElement(RightSidePanel, { caseOwner: data.case.createdBy, call: call }))) : (React.createElement(FallbackView, { title: t('noCallData', 'No call data') }))));
};
