var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ModalMui from '@mui/material/Modal';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { Box } from 'lib/cortiUI/components/Box';
import { IconButton } from 'lib/cortiUI/components/IconButton';
import { Typography } from 'lib/cortiUI/components/Typography';
import { useCortiUIContext } from 'lib/cortiUI/context';
const sizes = {
    small: 540,
    medium: 720,
    large: 1100,
};
/**
 * Simple Material UI modal with overridden styles. They accept all Material UI props.
 */
export const Modal = (props) => {
    const { children, className, size = 'medium', hideCloseButton, open } = props, rest = __rest(props, ["children", "className", "size", "hideCloseButton", "open"]);
    const theme = useTheme();
    function handleCloseButtonClick(e) {
        props.onClose && props.onClose(e, 'closeButtonClick');
    }
    return (React.createElement(ModalMui, Object.assign({ "data-cy": "modal-container", open: open }, rest),
        React.createElement(Box, { borderRadius: "3px", className: css({
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                margin: '0 auto',
                maxWidth: sizes[size],
                maxHeight: '88vh',
                outline: 'none',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.secondary,
            }, className) },
            !hideCloseButton && (React.createElement(IconButton, { "data-cy": "modal-close-btn", onClick: handleCloseButtonClick, style: { position: 'fixed', top: 8, right: 8, cursor: 'pointer', zIndex: 1500 }, icon: React.createElement(CloseRoundedIcon, { fontSize: "small" }), size: "small" })),
            children)));
};
export function ModalHeader(props) {
    const { title } = props, rest = __rest(props, ["title"]);
    return (React.createElement(Box, Object.assign({ pt: 7, pb: 5, px: 7, flexDirection: "row", alignItems: "center" }, rest),
        React.createElement(Typography, { variant: "h6", color: "default", mr: 2 }, title)));
}
export function ModalContent(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    const { theme } = useCortiUIContext();
    return (React.createElement(Base, Object.assign({ pt: 5, pb: 7, px: 7 }, rest, { className: css(getScrollerCss({ theme }), className) }), children));
}
