import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Chip } from 'lib/cortiUI';
import { getPerformanceStatusColor, getPerformanceStatusLabel } from '../utils';
export const PerformanceChip = ({ value, thresholds = { min: 20, max: 50 }, }) => {
    const { palette } = useTheme();
    const getColorAndLabel = (value, thresholds, palette) => {
        return {
            color: getPerformanceStatusColor(value, thresholds, palette),
            label: getPerformanceStatusLabel(value, thresholds),
        };
    };
    const { color, label } = getColorAndLabel(value, thresholds, palette);
    return (React.createElement(Chip, { variant: "outlined", size: "small", borderColor: color, className: css({
            color: color,
        }), label: label }));
};
