import { observer } from 'mobx-react';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { css } from '@corti/style';
import { coreStore } from 'browser/stores';
import { Base, Slide, Snackbar, useCortiUIContext } from 'lib/cortiUI';
import { Notification } from './Notification';
export const NotificationRoot = observer(function NotificationRoot() {
    const DEFAULT_AUTO_HIDE_DURATION = 4000;
    const { theme } = useCortiUIContext();
    function handleActionClick(notification, action) {
        action.onClick();
        coreStore.notifications.closeNotification(notification.id);
    }
    function handleOnRequestClose(notification, reason) {
        if (reason !== 'clickaway') {
            coreStore.notifications.closeNotification(notification.id);
        }
    }
    function getDuration(notification) {
        var _a;
        if (!notification.permanent) {
            return (_a = notification.duration) !== null && _a !== void 0 ? _a : DEFAULT_AUTO_HIDE_DURATION;
        }
    }
    return (React.createElement(Base, { left: "50%", position: "absolute", overflow: "hidden", zIndex: theme.zIndex['snackbar'], bottom: theme.space[6], paddingLeft: theme.space[6], paddingRight: theme.space[6], width: ['100%', 'max-content'], className: css({
            transform: 'translateX(-50%)',
        }) },
        React.createElement(TransitionGroup, { className: css({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.space[5],
                alignItems: 'center',
            }) }, coreStore.notifications.notifications
            .slice()
            .reverse()
            .map((notification) => {
            return (React.createElement(Slide, { key: notification.id, direction: "up" },
                React.createElement(Snackbar, { open: true, autoHideDuration: getDuration(notification), disableWindowBlurListener: true, transitionDuration: 0, onClose: (_e, reason) => {
                        handleOnRequestClose(notification, reason);
                    }, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, sx: {
                        width: { xs: '100%', sm: 'unset' },
                        minWidth: { xs: 'unset', sm: 288 },
                        maxWidth: { xs: 'unset', sm: 568 },
                        bottom: { xs: 'unset' },
                        top: { xs: 'unset' },
                        left: { xs: 'unset' },
                        right: { xs: 'unset' },
                        transform: { xs: 'unset' },
                        position: 'relative',
                    } },
                    React.createElement(Base, { className: css({
                            height: '100%',
                            width: '100%',
                        }) },
                        React.createElement(Notification, { notification: notification, defaultDuration: DEFAULT_AUTO_HIDE_DURATION, onClose: () => coreStore.notifications.closeNotification(notification.id), onActionClick: (action) => handleActionClick(notification, action), onExpanded: () => coreStore.notifications.setNotificationPermanent(notification) })))));
        }))));
});
