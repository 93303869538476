var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useLazyQuery } from '@apollo/client';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import React from 'react';
import { endOfDay, subDays } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import userSessionStorage from '@corti/lib/userSessionStorage';
import { css, getScrollerCss } from '@corti/style';
import { trackerService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Avatar, Base, DataGrid, IconButton, Skeleton, Tooltip, Typography, useCortiUIContext, } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
import { calculatePerformanceScore, getPerformanceViewMetricsQuery, useDataContext, } from '../../../activeView/context/dataContext';
import { useGlobalContext } from '../../../context';
import { NoRowsOverlay } from '../../components/NoRowsOverlay';
import { ViewTitle } from '../../components/ViewTitle';
import { CallTakersDataGridLoader } from './CallTakersDataGridLoader';
import { CallTakersMetricsWidget } from './CallTakersMetricsWidget';
import { TrendWidget } from './TrendWidget';
import { getUserTrend } from './utils';
// This is the duplication key from admin-panel
const USER_SESSION_STORAGE_TOGGLE_TREND_KEY = 'adminPanel:toggleTrendValue';
export const CallTakersDataGrid = () => {
    var _a, _b;
    const { organization } = useAuth();
    const { t } = useTranslation('performanceApp');
    const { theme } = useCortiUIContext();
    const _c = useParametersContext(), _d = _c.appliedFilters, { trackers } = _d, filter = __rest(_d, ["trackers"]), { changeFilters } = _c;
    const { performanceViewMetrics: { userMetrics }, isPerformanceViewMetricsLoading, } = useDataContext();
    const { isEditMode, isDraft } = useGlobalContext();
    const isTrendEnabled = (_b = JSON.parse((_a = userSessionStorage.getItem(USER_SESSION_STORAGE_TOGGLE_TREND_KEY)) !== null && _a !== void 0 ? _a : 'null')) !== null && _b !== void 0 ? _b : false;
    const DEFAUlT_TREND_PERIOD = 30;
    const lastTrendPeriodDate = endOfDay(new Date());
    const breakingTrendPeriodDate = subDays(lastTrendPeriodDate, DEFAUlT_TREND_PERIOD);
    const firstTrendPeriodDate = subDays(lastTrendPeriodDate, DEFAUlT_TREND_PERIOD * 2);
    const readOnly = isEditMode || isDraft;
    const [getUserMetricsForLastTrendQuery, getUserMetricsForLastTrendResult] = useLazyQuery(getPerformanceViewMetricsQuery, {
        variables: {
            organizationID: organization.id,
            startTime: breakingTrendPeriodDate,
            endTime: lastTrendPeriodDate,
            filter,
            sources: trackers,
        },
        fetchPolicy: 'no-cache',
    });
    const [getUserMetricsForPreviousTrendQuery, getUserMetricsForPreviousTrendResult] = useLazyQuery(getPerformanceViewMetricsQuery, {
        variables: {
            organizationID: organization.id,
            startTime: firstTrendPeriodDate,
            endTime: breakingTrendPeriodDate,
            filter,
            sources: trackers,
        },
        fetchPolicy: 'no-cache',
    });
    React.useEffect(() => {
        if (isTrendEnabled) {
            getUserMetricsForLastTrendQuery();
            getUserMetricsForPreviousTrendQuery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTrendEnabled]);
    function getColumns() {
        return [
            {
                field: 'name',
                headerName: t('name', 'Name'),
                editable: false,
                sortable: !readOnly,
                flex: 3,
                renderCell: (props) => (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" },
                    React.createElement(Avatar, { mr: 5, size: "small", titleText: props.value }),
                    React.createElement(Typography, { color: "default", variant: "body2", noWrap: true }, props.value))),
            },
            {
                field: 'performanceBreakdown',
                headerName: t('averageDetections', 'Compliance'),
                editable: false,
                sortable: !readOnly,
                sortComparator: (v1, v2) => calculatePerformanceScore(v1) - calculatePerformanceScore(v2),
                flex: 2,
                renderCell: ({ value }) => {
                    if (!value)
                        return null;
                    return React.createElement(CallTakersMetricsWidget, { breakdown: value });
                },
            },
            {
                field: 'totalCalls',
                headerName: t('totalCalls', 'Calls'),
                editable: false,
                sortable: !readOnly,
                flex: 2,
            },
            {
                field: 'trend',
                headerName: t('trend', 'Trend'),
                editable: false,
                sortable: !readOnly,
                flex: 2,
                renderCell: ({ value }) => getUserMetricsForLastTrendResult.loading ||
                    getUserMetricsForPreviousTrendResult.loading ? (React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } })) : (React.createElement(TrendWidget, { value: value })),
                renderHeader: ({ colDef: { headerName } }) => (React.createElement(Base, { display: "flex", alignItems: "center", gap: 2 },
                    React.createElement(Typography, { variant: "subtitle2" }, headerName),
                    React.createElement(Tooltip, { title: t('trendDescription', 'Last {{daysCount}} days vs {{daysCount}} days prior', {
                            daysCount: DEFAUlT_TREND_PERIOD,
                        }) },
                        React.createElement(IconButton, { className: css({ color: theme.palette.text.hint, margin: 3 }), icon: React.createElement(InfoRoundedIcon, { fontSize: "small" }) })))),
            },
        ];
    }
    function getRows() {
        var _a;
        return ((_a = userMetrics.map((it) => {
            var _a, _b, _c, _d, _e, _f;
            return {
                id: it.user.id,
                name: it.user.name,
                performanceBreakdown: it.breakdown,
                totalCalls: it.numberOfCalls,
                trend: isTrendEnabled
                    ? getUserTrend((_c = (_b = (_a = getUserMetricsForLastTrendResult.data) === null || _a === void 0 ? void 0 : _a.performanceViewMetrics.userMetrics.find((u) => u.user.id === it.user.id)) === null || _b === void 0 ? void 0 : _b.breakdown) !== null && _c !== void 0 ? _c : [], (_f = (_e = (_d = getUserMetricsForPreviousTrendResult.data) === null || _d === void 0 ? void 0 : _d.performanceViewMetrics.userMetrics.find((u) => u.user.id === it.user.id)) === null || _e === void 0 ? void 0 : _e.breakdown) !== null && _f !== void 0 ? _f : [])
                    : undefined,
            };
        })) !== null && _a !== void 0 ? _a : []);
    }
    function onRowClick(params) {
        changeFilters({ userIDs: [params.id.toString()] });
        trackerService.track('[Performance] a user from call takers table clicked', {
            id: params.row.id,
            name: params.row.name,
        });
    }
    const rows = getRows();
    const columns = getColumns();
    return (React.createElement(Base, { "data-cy": "performance-call-takers-section", display: "grid", gap: 6, className: readOnly ? css({ opacity: 0.5, pointerEvents: 'none' }) : undefined },
        React.createElement(ViewTitle, { title: t('callTakers', 'Call-takers') }),
        isPerformanceViewMetricsLoading ? (React.createElement(CallTakersDataGridLoader, null)) : (React.createElement(DataGrid, { rows: rows, columns: columns, pageSize: rows.length, autoHeight: true, disableColumnFilter: true, disableColumnMenu: true, hideFooter: true, loading: isPerformanceViewMetricsLoading, onRowClick: readOnly ? undefined : (params) => onRowClick(params), initialState: {
                sorting: {
                    sortModel: [{ field: 'performanceBreakdown', sort: 'desc' }],
                },
                columns: {
                    columnVisibilityModel: {
                        trend: isTrendEnabled,
                    },
                },
            }, sortingOrder: ['asc', 'desc'], components: {
                NoRowsOverlay: NoRowsOverlay,
            }, classes: {
                virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
            }, sx: {
                border: 'hidden',
                '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
            } }))));
};
