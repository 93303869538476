import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { Base, Button, Container, Modal, Typography, useCortiUIContext } from 'lib/cortiUI';
import { TrackersConfigModalContent } from '../ui';
import img from './assets/image@1x.png';
import img2x from './assets/image@2x.png';
import img3x from './assets/image@3x.png';
export const InitialView = ({ onTrackersApply }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'initialView' });
    const { theme } = useCortiUIContext();
    const [isTrackersModalOpen, setIsTrackersModalOpen] = React.useState(false);
    return (React.createElement(Container, { sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 7,
        }, className: css(getScrollerCss({ theme: theme })) },
        React.createElement("img", { style: { maxInlineSize: '100%', blockSize: 'auto' }, srcSet: `${img} 1x, ${img2x} 2x, ${img3x} 3x`, src: img }),
        React.createElement(Base, { display: "flex", flexDirection: "column", alignItems: "center" },
            React.createElement(Typography, null, t('noComplianceData', 'No Compliance data to show.')),
            React.createElement(Typography, null, t('noComplianceInstruction', 'Please select trackers to see Compliance data.'))),
        React.createElement(Button, { "data-cy": "select-trackers-btn", variant: "contained", color: "primary", onClick: () => setIsTrackersModalOpen(true) }, t('selectTrackersBtn', 'Select trackers')),
        React.createElement(Modal, { open: isTrackersModalOpen, onClose: () => setIsTrackersModalOpen(false) },
            React.createElement(TrackersConfigModalContent, { onApply: onTrackersApply }))));
};
