import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getQuestionStatsById } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { PerformanceBar } from 'lib/cortiUI';
import { Card, ScoreLabel } from '../../components';
import { useParameters } from '../../context';
import { QuestionLoader } from './QuestionLoader';
import { mergeQuestionAndStats } from './utils';
export const QuestionCard = ({ question: questionProp, thresholds, }) => {
    const { t } = useTranslation('questionsList', { keyPrefix: 'questionsList' });
    const { dateRange, agents, teams, scoreRange, filterRequestParams } = useParameters();
    const [question, setQuestion] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, agents, teams, scoreRange]);
    const loadData = async () => {
        setIsLoading(true);
        try {
            const questionStats = await getQuestionStatsById(questionProp.id, filterRequestParams);
            setQuestion(mergeQuestionAndStats(questionProp, questionStats));
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchQuestionStatsError', 'Error fetching question metrics'),
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    if (isLoading) {
        return React.createElement(QuestionLoader, null);
    }
    if (!question) {
        return null;
    }
    React;
    return (React.createElement(Card, { title: question.title },
        React.createElement(ScoreLabel, { size: "medium", value: question.percentage, thresholds: thresholds }),
        React.createElement(PerformanceBar, { value: question.percentage, thresholds: thresholds })));
};
