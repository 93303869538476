import React from 'react';
import { intl } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css, getScrollerCss } from '@corti/style';
import { Base, Checkbox, FormControlLabel, List, ListItemButton, ListItemText, Typography, useCortiUIContext, } from 'lib/cortiUI';
import { GroupOuterShell } from './GroupOuterShell';
import { Input } from './Input';
export function MultipleSelectableList(props) {
    const { items, selectedItemsIds = [], onChange, withSearch = false } = props;
    const { theme } = useCortiUIContext();
    const [searchValue, setFilterValue] = React.useState('');
    function handleSelectAll() {
        const newItems = selectedItemsIds.length < items.length ? items.map((i) => i.value) : [];
        onChange(newItems);
    }
    function handleSelect(item) {
        let newItems = selectedItemsIds;
        if (newItems.includes(item.value)) {
            newItems = newItems.filter((i) => i !== item.value);
        }
        else {
            newItems = [...newItems, item.value];
        }
        onChange(newItems);
    }
    function getFilteredItems() {
        return items.filter((i) => !searchValue || stringIncludes(i.text, searchValue));
    }
    return (React.createElement(React.Fragment, null,
        withSearch && (React.createElement(GroupOuterShell, { className: css({
                margin: 'auto',
                width: 200,
                height: 30,
            }) },
            React.createElement(Input, { value: searchValue, placeholder: intl.t('search', 'Search'), onChange: (e) => setFilterValue(e.target.value) }))),
        React.createElement(Base, { className: css(getScrollerCss({ theme: theme })), maxHeight: 150, overflow: "hidden", my: 3, mx: 2 },
            React.createElement(Base, { mr: 3 },
                React.createElement(FormControlLabel, { label: intl.t('filterBuilder.selectAll', 'Select All'), control: React.createElement(Checkbox, { checked: items.length === (selectedItemsIds === null || selectedItemsIds === void 0 ? void 0 : selectedItemsIds.length), color: "primary", onChange: handleSelectAll, indeterminate: items.length !== (selectedItemsIds === null || selectedItemsIds === void 0 ? void 0 : selectedItemsIds.length) }) })),
            React.createElement(List, null, getFilteredItems().map((i, idx) => (React.createElement(ListItemButton, { key: idx, onClick: () => handleSelect(i), classes: {
                    root: css({
                        height: 30,
                        backgroundColor: theme.palette.background.default,
                    }),
                } },
                React.createElement(Base, { display: "flex", alignItems: "center" },
                    React.createElement(Base, { mr: 3 },
                        React.createElement(Checkbox, { checked: selectedItemsIds.includes(i.value), disableRipple: true })),
                    React.createElement(ListItemText, null,
                        React.createElement(Typography, { variant: "caption", color: "default", noWrap: true }, i.text))))))))));
}
