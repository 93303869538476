import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { Questions } from './Questions';
import { Score } from './Score';
import { Summary } from './Summary';
export const ActiveReview = ({ selectedReview }) => {
    const theme = useTheme();
    if (['FAILED', 'NONE', 'PENDING_TRANSCRIPT', 'PENDING_INTERACTION'].includes(selectedReview.status))
        return null;
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
        React.createElement(Score, { review: selectedReview }),
        !selectedReview.created_by && React.createElement(Summary, { reviewID: selectedReview.id }),
        React.createElement(Questions, { templateID: selectedReview.template_id, reviewID: selectedReview.id })));
};
