import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { isEqual, map } from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss, transparentize } from '@corti/style';
import { trackerService } from 'browser/services/init';
import { useCoreStores } from 'browser/stores';
import { Autocomplete, Base, Box, IconButton, TextField, useCortiUIContext } from 'lib/cortiUI';
import { useExploreViewContext } from '../../ExploreViewContext';
import { useCaseViewContext } from '../CaseViewContext';
import { FeedbackForm } from './FeedbackForm';
import { transformTemplate } from './transformers';
export function CreateCaseFeedbackView() {
    const { notifications } = useCoreStores();
    const { caseViewState } = useCaseViewContext();
    const { refreshCasesList } = useExploreViewContext();
    const { theme } = useCortiUIContext();
    const [submissionState, setSubmissionState] = React.useState({
        isSubmitting: false,
    });
    React.useEffect(() => {
        var _a;
        (_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.exitTimelinePreview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (!caseViewState.feedbackViewState.templates.length) {
            void caseViewState.feedbackViewState.loadTemplates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function getTemplateItem(template) {
        return {
            value: template.id,
            text: template.title,
        };
    }
    function getTemplateItems() {
        return caseViewState.feedbackViewState.templates.map(getTemplateItem);
    }
    function getActiveTemplateItem() {
        var _a;
        return ((_a = (caseViewState.feedbackViewState.activeTemplate &&
            getTemplateItem(caseViewState.feedbackViewState.activeTemplate))) !== null && _a !== void 0 ? _a : null);
    }
    function handleOnChange(val) {
        caseViewState.feedbackViewState.setFeedbackFormValue(val);
    }
    function handleTemplateChange(selectedValue) {
        caseViewState.feedbackViewState.setActiveTemplateID(selectedValue.value);
        caseViewState.feedbackViewState.resetFeedbackFormValue();
    }
    return (React.createElement(Observer, null, () => {
        const { feedbackViewState, caseEntity } = caseViewState;
        const template = feedbackViewState.activeTemplate;
        const resolvedTemplate = template && caseEntity ? transformTemplate(template, caseEntity) : undefined;
        return (React.createElement(Base, { "data-cy": "create-case-feedback-view", display: "grid", gridGap: 7, overflow: "hidden" },
            React.createElement(Box, { mx: 8, flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: theme.space[10] },
                React.createElement(IconButton, { "data-cy": "back-to-main-review-view-btn", icon: React.createElement(ArrowBackRoundedIcon, { fontSize: "small" }), sx: { border: 1 }, className: css({ borderColor: transparentize(0.5, theme.palette.text.primary) }), onClick: () => feedbackViewState.setActiveView('feedback-list') }),
                React.createElement(Autocomplete, { size: "small", "data-cy": "template-selector", options: getTemplateItems(), getOptionLabel: (option) => option.text, isOptionEqualToValue: (o, v) => isEqual(o, v), value: getActiveTemplateItem(), onChange: (_, option) => {
                        if (option) {
                            handleTemplateChange(option);
                        }
                    }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('explore:feedbackView.templateSelectorLabel', 'Review'), placeholder: intl.t('explore:feedbackView.templateSelectorPlaceholder', 'Select template...') }))), clearIcon: null, sx: { minWidth: 220 } })),
            React.createElement(Base, { className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
                React.createElement(Base, { mx: 8 }, template && resolvedTemplate && caseEntity && (React.createElement(FeedbackForm, { key: template.id, disabled: submissionState.isSubmitting, template: resolvedTemplate, onChoiceClick: (_, value) => {
                        const entry = caseEntity.timelineEditor.getTimelineEntry(value);
                        if (entry) {
                            caseEntity.timelineEditor.setActiveTimelineEntry(entry.id);
                            if (caseEntity.activeCall && caseEntity.activeCallPlayerState) {
                                const entryOffset = entry.getOffsetPosition(caseEntity.activeCall.start).start / 1000;
                                caseEntity.activeCallPlayerState.setCurrentTime(entryOffset);
                            }
                        }
                    }, onChoiceHintClick: (timelineEntry) => {
                        caseEntity.timelineEditor.setActiveTimelineEntry(timelineEntry.id);
                        trackerService.track('[Single case] timeline-entry focused in Feedback form choices hints', {
                            timelineEntry: {
                                id: timelineEntry.id,
                                callID: timelineEntry.callID,
                                definition: timelineEntry.definition,
                                createdBy: timelineEntry.createdBy,
                                createdAt: timelineEntry.createdAt,
                                title: timelineEntry.definition.title,
                                text: timelineEntry.definition.text,
                                color: timelineEntry.definition.style.color,
                            },
                        });
                        if (caseEntity.activeCall && caseEntity.activeCallPlayerState) {
                            const entryOffset = timelineEntry.getOffsetPosition(caseEntity.activeCall.start).start / 1000;
                            caseEntity.activeCallPlayerState.setCurrentTime(entryOffset);
                        }
                    }, formValue: caseViewState.feedbackViewState.activeFeedbackFormValue, caseEntity: caseViewState.caseEntity, onChange: handleOnChange, onSubmit: async (submission) => {
                        var _a, _b, _c;
                        try {
                            setSubmissionState({ isSubmitting: true });
                            await caseEntity.timelineEditor.saveAndExit();
                            await caseEntity.submitFeedback({
                                templateID: template.id,
                                caseID: caseEntity.id,
                                caseReviewRequestID: (_a = caseEntity.reviewRequestOfCurrentUser) === null || _a === void 0 ? void 0 : _a.id,
                                fields: map(submission.fields, (val, fieldID) => {
                                    function resolveValue() {
                                        if (typeof val.value === 'string') {
                                            const timelineEntry = caseEntity.timelineEditor.getTimelineEntry(val.value);
                                            if (timelineEntry) {
                                                return {
                                                    type: 'timeline-entry',
                                                    timelineEntrySourceID: timelineEntry.sourceID,
                                                    timelineEntryDefinitionSource: timelineEntry.definition.source,
                                                };
                                            }
                                            return {
                                                type: 'text',
                                                value: val.value,
                                            };
                                        }
                                        else {
                                            return {
                                                type: 'text',
                                                value: String(val.value),
                                            };
                                        }
                                    }
                                    return {
                                        fieldID: fieldID,
                                        values: val.value ? [resolveValue()] : [],
                                        comments: val.comment ? [{ text: val.comment }] : [],
                                    };
                                }),
                            }, (_c = (_b = caseEntity.reviewRequestOfCurrentUser) === null || _b === void 0 ? void 0 : _b.suggestedTemplate) === null || _c === void 0 ? void 0 : _c.id);
                            refreshCasesList();
                            notifications.showNotification({
                                type: 'success',
                                message: intl.t('feedbackForm.submissionSuccessMsg', 'Form submitted'),
                            });
                            trackerService.track('Feedback form submitted');
                            feedbackViewState.setActiveView('feedback-list');
                            caseViewState.feedbackViewState.resetFeedbackFormValue();
                        }
                        catch (err) {
                            setSubmissionState({ isSubmitting: false });
                            throw err;
                        }
                    }, onCreateTimelineEntry: (_, def) => {
                        const resolvedDefinition = caseViewState.timelineEntryDefinitions.find(({ source }) => isEqual(source, def.source));
                        if (!resolvedDefinition)
                            return;
                        caseEntity.timelineEditor.setNextTimelineEntryInitialInput({
                            definition: resolvedDefinition,
                        });
                    } }))))));
    }));
}
