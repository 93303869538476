var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import React from 'react';
import { css, transparentize } from '@corti/style';
import { Base } from 'lib/cortiUI/components/Base';
import { Typography } from 'lib/cortiUI/components/Typography';
import { useCortiUIContext } from 'lib/cortiUI/context';
/**
 * Component displays text inside in Tag
 */
export function Tag(props) {
    const { theme } = useCortiUIContext();
    const defaultColor = theme.palette.grey[500];
    const disabledColor = theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900];
    const { text, color = defaultColor, className, variant = 'square', disabled = false, onIconClick } = props, rest = __rest(props, ["text", "color", "className", "variant", "disabled", "onIconClick"]);
    const tagColor = disabled ? disabledColor : color;
    return (React.createElement(Base, Object.assign({ py: 2, px: 4, borderRadius: variant === 'square' ? 2 : 1000, className: css({
            backgroundColor: transparentize(0.8, tagColor),
            maxWidth: '100%',
            width: 'max-content',
        }, className), title: text, display: "inline-flex", alignItems: "center" }, rest),
        React.createElement(Typography, { variant: "body2", noWrap: true, noSelect: true, className: css({ color: tagColor }) }, text),
        onIconClick && (React.createElement(HighlightOffRoundedIcon, { "data-cy": "tag-close-btn", className: css({ color: tagColor }), fontSize: "small", onClick: (e) => {
                e.stopPropagation();
                !disabled && onIconClick && onIconClick();
            } }))));
}
