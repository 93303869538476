import React from 'react';
import { useTheme } from '@corti/theme';
import { Typography } from 'lib/cortiUI';
import { getPerformanceStatusColor } from '../utils';
export const ScoreLabel = ({ value, size = 'small', thresholds = { min: 20, max: 50 }, }) => {
    const { palette } = useTheme();
    const getLabelVariant = () => {
        switch (size) {
            case 'small':
                return 'body2';
            case 'medium':
                return 'subtitle1';
            case 'large':
                return 'h4';
            default:
                return 'h4';
        }
    };
    return (React.createElement(Typography, { variant: getLabelVariant(), sx: {
            color: getPerformanceStatusColor(value, thresholds, palette),
        } },
        value,
        "%"));
};
