import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { Route } from 'lib/router';
import { TeamView } from './TeamView';
import { TeamsView as TeamsViewInternal } from './TeamsView';
export function TeamsView() {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: match.path, exact: true },
            React.createElement(TeamsViewInternal, null)),
        React.createElement(Route, { path: `${match.path}/:teamID`, render: (p) => React.createElement(TeamView, { teamID: p.match.params.teamID }) })));
}
