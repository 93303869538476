import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { BuildInfo } from 'browser/components';
import { swWrapper } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { env } from 'core/environment';
import { Base, Box, Button, Dialog, Separator, Typography, useCortiUIContext } from 'lib/cortiUI';
import { passwordRequirementsText } from 'lib/passwordRequirementsTexter';
import { PasswordChangeForm } from './PasswordChangeForm';
import { ProfileSettingsForm } from './ProfileSettingsForm';
export const ProfileView = observer(() => {
    const { theme } = useCortiUIContext();
    const { remoteRequest } = coreStore;
    const [showPasswordChangeModal, setShowPasswordChangeModal] = React.useState(false);
    // this is not a reactive value but it's ok for now
    const [zoomLevel, _] = React.useState(getZoomLevel());
    React.useEffect(() => {
        remoteRequest.refreshApiInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function getZoomLevel() {
        return Math.round(window.devicePixelRatio * 100);
    }
    const currentSettings = {
        name: authStore.currentUser.name,
        email: authStore.currentUser.email,
        extension: authStore.currentUser.extension || '',
        externalID: authStore.currentUser.externalID || '',
        themeID: authStore.themeID,
        locale: authStore.currentUser.settings.locale,
    };
    async function onSettingsSave(input) {
        await authStore.updateUserProfile({
            name: input.name,
            email: input.email,
            extension: input.extension || '',
            externalID: input.externalID || undefined,
            settings: {
                themeID: input.themeID,
                locale: input.locale,
            },
        });
        if (input.locale) {
            await intl.changeLanguage(input.locale);
        }
    }
    return (React.createElement(Box, { "data-cy": "settings-view", p: 4, pl: 6, height: "100%", className: css(getScrollerCss({ theme: theme })), alignItems: "start" },
        React.createElement(Typography, { my: 4, variant: "h4" }, intl.t('settings:settings', 'Settings')),
        React.createElement(Box, { style: { width: '100%', maxWidth: 400 }, mt: 6 },
            React.createElement(ProfileSettingsForm, { onSubmit: onSettingsSave, settings: currentSettings })),
        React.createElement(Separator, { horizontal: true, width: '100%', my: 6 }),
        React.createElement(Base, { display: 'flex', flexDirection: 'column', alignItems: "start", gap: 4 },
            React.createElement(Typography, { variant: "h6" }, intl.t('settings:password', 'Password')),
            React.createElement(Button, { variant: "outlined", color: "primary", onClick: () => setShowPasswordChangeModal(true), className: css({ width: 200 }) }, intl.t('settings:changePassword', 'Change password')),
            React.createElement(Dialog, { open: showPasswordChangeModal, onClose: () => setShowPasswordChangeModal(false), title: intl.t('settings:changePassword', 'Change password'), helperText: passwordRequirementsText() },
                React.createElement(PasswordChangeForm, { userId: authStore.currentUserID, onSuccess: () => setShowPasswordChangeModal(false), isOldPasswordRequired: authStore.organization.organizationSecuritySettings
                        .oldPasswordRequiredForPasswordReset }))),
        React.createElement(Separator, { horizontal: true, width: '100%', my: 6 }),
        React.createElement(Base, null,
            React.createElement(BuildInfo, { buildInfo: env.buildInfo, serverInfo: remoteRequest.apiInfo }),
            React.createElement(Button, { variant: "outlined", color: "primary", onClick: () => {
                    swWrapper === null || swWrapper === void 0 ? void 0 : swWrapper.checkForUpdate();
                } }, intl.t('settings:checkForUpdatesBtn', 'Check for updates'))),
        React.createElement(Separator, { horizontal: true, width: '100%', my: 6 }),
        React.createElement(Box, { p: 3 },
            React.createElement(Typography, { variant: "caption" },
                intl.t('settings:currentZoomLevel', 'Current zoom level'),
                ": ",
                zoomLevel,
                "%"))));
});
