import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { saveFileToDisk } from '@corti/browser-file';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { Formatters } from '@corti/strings';
import { css } from '@corti/style';
import { Base, Box, Button, ConfirmPopover, Loading, Typography, useCortiUIContext, } from 'lib/cortiUI';
import { ExceptionCard } from './ExceptionCard';
import { transformRequestToCaseIDCellDescriptor, transformRequestToStatusCellDescriptor, transformRequestToTimeStampCellDescriptor, } from './TableDescriptorsTransformers';
import { UploadRequestTable } from './UploadRequestTable';
import { UploadRequestsState } from './UploadRequestsState';
export function CallsUploadView(props) {
    const { onClose, onUploadStart, onUploadEnd } = props;
    const { theme } = useCortiUIContext();
    const uploadRequestsState = React.useRef(new UploadRequestsState()).current;
    const $uploadCallInput = React.useRef(null);
    const $uploadCSVInput = React.useRef(null);
    const [warningModalAnchor, setWarningModalAnchor] = React.useState(null);
    async function handleUpload() {
        onUploadStart && onUploadStart();
        await uploadRequestsState.initializeUpload();
        onUploadEnd && onUploadEnd();
    }
    function handleRejectedRequestsUpload() {
        resetUploadRequests(getRejectedUploadRequestsForRetry());
        void handleUpload();
    }
    function onClickCallUploadButton() {
        if ($uploadCallInput.current) {
            $uploadCallInput.current.click();
        }
    }
    function onClickCSVUploadButton() {
        if ($uploadCSVInput.current) {
            $uploadCSVInput.current.click();
        }
    }
    function onSelectCallFiles(e) {
        if (e.currentTarget.files) {
            const files = Array.from(e.currentTarget.files);
            files.forEach((file, idx) => {
                uploadRequestsState.createUploadRequest(file, idx);
            });
        }
    }
    function onSelectCSVFile(e) {
        if (e.currentTarget.files) {
            const file = Array.from(e.currentTarget.files)[0];
            resetUploadRequests(getRejectedUploadRequestsForRetry());
            uploadRequestsState.eraseAllMetadata();
            uploadRequestsState.parseCSVFile(file);
        }
    }
    function getRejectedUploadRequestsForRetry() {
        return uploadRequestsState.rejectedUploadRequests.filter((req) => {
            var _a;
            return req.error && ((_a = req.error.domainErrors) === null || _a === void 0 ? void 0 : _a.some((e) => e.code !== 'FILE_ALREADY_EXISTS'));
        });
    }
    function resetUploadRequests(uploadRequests) {
        uploadRequests.forEach((req) => req.reset());
    }
    function openWarningModal(e) {
        setWarningModalAnchor(e.currentTarget);
    }
    function closeWarningModal() {
        setWarningModalAnchor(null);
    }
    function onWarningModalConfirmClick() {
        void handleUpload();
        closeWarningModal();
    }
    function getFormattedTimeStamp(timeStamp) {
        // CSV may contain invalid timeStamps so we have to handle possible formatting errors
        try {
            return formatDateTime(new Date(timeStamp)).replace(',', '');
        }
        catch (_a) {
            return timeStamp;
        }
    }
    function generateExportFileName() {
        const ms = new Date().getTime();
        const date = Formatters.msToFullYears(ms, { separator: '' });
        const time = Formatters.msToDayTime(ms, { separator: '' });
        const ext = '.csv';
        return 'Corti-Upload-calls-results-' + date + '-' + time + ext;
    }
    function onExportInCsv() {
        const header = `${intl.t('explore:callsUploadView.labelFilename', 'Filename')},${intl.t('explore:callsUploadView.labelSize', 'Size')},${intl.t('explore:callsUploadView.labelDate', 'Date/Time')},${intl.t('explore:callsUploadView.labelCaseID', 'Case ID')},${intl.t('explore:callsUploadView.protocolName', 'Protocol name')},${intl.t('explore:callsUploadView.labelStatus', 'Status')},${intl.t('explore:callsUploadView.labelComments', 'Comments')}\n`;
        const data = uploadRequestsState.uploadRequests.map((req) => {
            const timeStampCellDescriptor = transformRequestToTimeStampCellDescriptor(req);
            const caseIDCellDescriptor = transformRequestToCaseIDCellDescriptor(req);
            return `${req.file.name},${req.file.size},${getFormattedTimeStamp(timeStampCellDescriptor.text)},${caseIDCellDescriptor.text},${req.metadata.protocolName || ''},${transformRequestToStatusCellDescriptor(req).text},${timeStampCellDescriptor.error || ''} ${caseIDCellDescriptor.error || ''}\n`;
        });
        saveFileToDisk(new Blob([header.concat(data.join(''))], { type: 'text/csv' }), generateExportFileName());
    }
    // Allows selecting same files again
    function resetInputValue(e) {
        e.currentTarget.value = '';
    }
    return (React.createElement(Observer, null, () => {
        const { uploadRequests, fulfilledUploadRequests, isUploading, hasUploaded, CSVParseWarnings, } = uploadRequestsState;
        const noCSVFileSelected = uploadRequests.every((req) => {
            return Object.values(req.metadata).every((val) => !val) && CSVParseWarnings.length === 0;
        });
        const isWarningModalNeeded = noCSVFileSelected || CSVParseWarnings.length > 0;
        const rejectedUploadRequestsForRetry = getRejectedUploadRequestsForRetry();
        return (React.createElement(React.Fragment, null,
            React.createElement(Base, { display: "grid", overflow: "hidden", height: "100%", gridGap: theme.space[3], "data-cy": "calls-upload-view" },
                React.createElement(Typography, { color: "secondaryText", variant: "body2" }, intl.t('explore:callsUploadView.text', 'Get the Corti AI to process your historical calls by importing your call recordings in MP3 or WAV format.')),
                uploadRequests.length > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(Base, { display: "grid", overflow: "hidden", height: "100%", width: "100%" },
                        React.createElement(UploadRequestTable, { uploadRequestsState: uploadRequestsState }),
                        CSVParseWarnings.length > 0 && (React.createElement(ExceptionCard, { "data-cy": "exception-card-warning", color: "warning", text: React.createElement(Box, { py: 3 },
                                React.createElement(Typography, { ml: 5, variant: "body2", color: "inherit" }, intl.t('explore:callsUploadView.CSVExceptionTitle', 'Encountered issues while parsing metadata file')),
                                CSVParseWarnings.map((warning, idx) => (React.createElement(Typography, { key: idx, ml: 8, variant: "body2", color: "inherit" }, `- ${warning}`)))) })),
                        hasUploaded && rejectedUploadRequestsForRetry.length > 0 && (React.createElement(ExceptionCard, { "data-cy": "exception-card-error", text: intl.t('explore:callsUploadView.uploadErrorText', '{{ numOfRejectedUploads }} uploads failed because of an error. Resolve the issues or check your connection and try again.', {
                                numOfRejectedUploads: rejectedUploadRequestsForRetry.length,
                            }), actionText: intl.t('explore:callsUploadView.retry', 'Retry'), action: handleRejectedRequestsUpload }))),
                    React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end", margin: 2 },
                        hasUploaded ? (React.createElement(Button, { className: css({ marginRight: 8 }), color: "primary", size: "small", onClick: onExportInCsv, variant: "text" }, intl.t('explore:callsUploadView.exportToCSV', 'Export to CSV'))) : (React.createElement(Button, { "data-cy": "csv-file-upload-button", color: "primary", variant: "text", size: "small", disabled: isUploading, onClick: onClickCSVUploadButton, className: css({
                                marginRight: 8,
                                position: 'relative',
                                width: 'min-content',
                                whiteSpace: 'nowrap',
                            }) },
                            intl.t('explore:callsUploadView.CSVButtonTitle', 'Select metadata'),
                            React.createElement("input", { "data-cy": "csv-file-upload-input", ref: $uploadCSVInput, id: "upload", type: "file", accept: ".csv", onChange: onSelectCSVFile, onClick: resetInputValue, style: {
                                    position: 'absolute',
                                    width: '100%',
                                    visibility: 'hidden',
                                } }))),
                        hasUploaded ? (React.createElement(Button, { color: "primary", size: "small", onClick: onClose, "data-cy": "upload-complete-button" }, intl.t('explore:callsUploadView.complete', 'Complete'))) : (React.createElement(Button, { "data-cy": "submit-button", color: "primary", size: "small", disabled: isUploading, onClick: isWarningModalNeeded ? openWarningModal : handleUpload }, isUploading ? (React.createElement(React.Fragment, null,
                            React.createElement(Loading, { mr: 5, size: "small" }),
                            React.createElement(Typography, { variant: "button", color: "secondaryText" }, intl.t('explore:callsUploadView.loadingText', '{{ idxOfActiveFile }} of {{ totalNumOfFiles }}', {
                                idxOfActiveFile: fulfilledUploadRequests.length,
                                totalNumOfFiles: uploadRequests.length,
                            })))) : (intl.t('explore:callsUploadView.uploadCalls', 'Upload calls'))))))) : (React.createElement(Button, { "data-cy": "audio-files-upload-button", size: "large", color: "primary", onClick: onClickCallUploadButton, sx: { margin: 2 } },
                    React.createElement(FileUploadRoundedIcon, { fontSize: "medium", className: css({ marginRight: 5, marginLeft: 5 }) }),
                    intl.t('explore:callsUploadView.audioButtonTitle', 'Choose calls and upload them to Corti'),
                    React.createElement("input", { "data-cy": "audio-files-upload-input", ref: $uploadCallInput, id: "upload", type: "file", accept: ".mp3, .wav", multiple: true, onChange: onSelectCallFiles, onClick: resetInputValue, className: css({
                            position: 'absolute',
                            width: '100%',
                            visibility: 'hidden',
                        }) })))),
            React.createElement(ConfirmPopover, { onConfirm: onWarningModalConfirmClick, onClose: closeWarningModal, anchorEl: warningModalAnchor, color: "warning", children: React.createElement(Box, null,
                    noCSVFileSelected && (React.createElement(Typography, { mb: 2, variant: "subtitle2" }, intl.t('explore:callsUploadView.submitConfirmation.noCSVSelected', 'No metadata file was selected. Are you sure want to proceed?'))),
                    CSVParseWarnings.length > 0 && (React.createElement(Typography, { mb: 2, variant: "subtitle2" }, intl.t('explore:callsUploadView.submitConfirmation.malformedCSVFile', 'There are unsolved metadata parsing issues. Are you sure want to proceed?'))),
                    React.createElement(Typography, { mb: 2, variant: "body2", color: "secondaryText" }, `- ${intl.t('explore:callsUploadView.submitConfirmation.text1', 'New case will be created for each call if no case ID is provided.')}`),
                    React.createElement(Typography, { mb: 2, variant: "body2", color: "secondaryText" }, `- ${intl.t('explore:callsUploadView.submitConfirmation.text2', 'The date of creation for call and case will be set to this moment if no timestamp is provided.')}`)) })));
    }));
}
