import React from 'react';
import { useTranslation } from '@corti/i18n';
import { transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, Card, PerformanceBar, Typography } from 'lib/cortiUI';
import { useInteraction } from '../../context';
import { GradientStarIcon } from '../components';
import { getReviewScoreByThresholdsColor } from '../utils';
import { AvgComparison } from './AvgComparison';
import { PerformanceChip } from './PerformanceChip';
import { ScoreLabel } from './ScoreLabel';
const TEMPLATE_THRESHOLDS = {
    min: 20,
    max: 50,
};
export const Score = ({ review }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.score' });
    const { palette } = useTheme();
    const { interaction } = useInteraction();
    const { currentUserID } = useAuth();
    const scorePercentage = Math.floor(review.score_percentage);
    const gradientColor = transparentize(0.93, getReviewScoreByThresholdsColor(scorePercentage, TEMPLATE_THRESHOLDS, palette));
    return (React.createElement(Card, { p: 6, background: `linear-gradient(57deg, ${gradientColor} 0%, ${palette.background.card} 48%, ${palette.background.card} 100%)` },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 2, alignItems: "center" },
                !review.created_by && React.createElement(GradientStarIcon, { fontSize: "small" }),
                React.createElement(Typography, { color: "default", variant: "subtitle2" }, `${!review.created_by ? `${t('ai', 'AI')} ` : ''}${t('title', 'Score')}`)),
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 3, justifyContent: "space-between" },
                React.createElement(Base, null,
                    React.createElement(Base, { display: "flex", gap: 5, alignItems: "baseline" },
                        React.createElement(ScoreLabel, { value: scorePercentage, thresholds: TEMPLATE_THRESHOLDS }),
                        React.createElement(PerformanceChip, { value: scorePercentage, thresholds: TEMPLATE_THRESHOLDS })),
                    React.createElement(PerformanceBar, { value: scorePercentage, thresholds: TEMPLATE_THRESHOLDS })),
                ((interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) === currentUserID ||
                    rbacService.missionControlOtherAssessmentsRead()) && (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5, justifyContent: "flex-end" },
                    (interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) && (React.createElement(AvgComparison, { score: scorePercentage, templateID: review.template_id, label: t('agent', 'Agent Average'), userIDs: [interaction.interaction_owner] })),
                    rbacService.missionControlOtherAssessmentsRead() && (React.createElement(AvgComparison, { score: scorePercentage, templateID: review.template_id, label: t('organization', 'Org Average'), userIDs: [] }))))))));
};
