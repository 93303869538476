import { observer } from 'mobx-react';
import React from 'react';
import { stringifyRichText } from '@corti/richtext';
import { css, transparentize } from '@corti/style';
import { Base, Box, RichTextRenderer, Typography, useCortiUIContext } from 'lib/cortiUI';
export const FlowOverviewStep = observer(function FlowOverviewStep({ step, className, isActive, }) {
    const { theme } = useCortiUIContext();
    return (React.createElement(Base, { "data-cy": "overview-step", width: "100%", className: css(className) },
        step.title && React.createElement(Title, { title: step.title }),
        React.createElement(Base, { tabIndex: 0, px: 7, py: 7, display: "grid", gap: 6, gridAutoColumns: "100%", width: "100%", className: css({
                cursor: 'default',
                borderRadius: theme.space[3],
                backgroundColor: theme.palette.background.card,
                transition: '0.3s ease',
                border: `2px solid ${isActive ? theme.palette.primary.main : theme.palette.background.card}`,
            }), elevation: isActive ? 15 : 0 }, step.fields.map((it) => {
            switch (it.type) {
                case 'paragraph': {
                    return React.createElement(ParagraphField, { field: it, key: it.id });
                }
                case 'question': {
                    return React.createElement(QuestionField, { field: it, key: it.id });
                }
                default: {
                    return null;
                }
            }
        }))));
});
function Title({ title }) {
    return (React.createElement(Typography, { variant: "h6", mb: 3, width: "100%", color: "default", fontWeight: "bold" }, title));
}
function Label({ label }) {
    const plainTextLabel = typeof label === 'string' ? label : stringifyRichText(label);
    if (!Boolean(plainTextLabel)) {
        return null;
    }
    return (React.createElement(Base, { width: "100%" },
        React.createElement(RichTextRenderer, { value: label, className: css({
                cursor: 'default',
            }) })));
}
function QuestionField({ field }) {
    const { theme } = useCortiUIContext();
    if (!field.values.length) {
        return null;
    }
    return (React.createElement(Base, { width: "100%", display: 'grid', gap: 5 },
        field.label && React.createElement(Label, { label: field.label }),
        React.createElement(Box, { flexDirection: "row", flexWrap: "wrap", width: "100%" }, field.values.map((t, idx) => {
            return (React.createElement(Box, { key: idx, mr: 4, mb: 4, py: 4, px: 6, justifyContent: "center", alignItems: "center", maxWidth: "100%", className: css({
                    backgroundColor: transparentize(0.85, theme.palette.text.primary),
                }) },
                React.createElement(RichTextRenderer, { value: t, className: css({
                        cursor: 'default',
                        width: '100%',
                    }) })));
        }))));
}
function ParagraphField({ field }) {
    return (React.createElement(Base, { width: "100%", display: 'grid', gap: 5 },
        field.label && React.createElement(Label, { label: field.label }),
        React.createElement(RichTextRenderer, { value: field.text, className: css({
                cursor: 'default',
            }) })));
}
