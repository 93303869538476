import React from 'react';
import { useTheme } from '@corti/theme';
import { Base, Card, Collapse, Typography } from 'lib/cortiUI';
import { AnswerLoader } from './AnswerLoader';
import { Choice } from './Choice';
import { NoAnswer } from './NoAnswer';
import { NoChoices } from './NoChoices';
export const Answer = ({ isLoading, answer, isExpanded }) => {
    const theme = useTheme();
    if (isLoading) {
        return React.createElement(AnswerLoader, null);
    }
    if (!answer) {
        return React.createElement(NoAnswer, null);
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
        !answer.choices.length ? (React.createElement(NoChoices, null)) : (answer.choices.map((choice, idx) => React.createElement(Choice, Object.assign({}, choice, { key: idx })))),
        answer.text && (React.createElement(Collapse, { in: isExpanded, timeout: "auto", unmountOnExit: true },
            React.createElement(Card, { background: theme.palette.background.default, style: { boxShadow: 'none', border: 'none' } },
                React.createElement(Typography, { variant: "body2", color: "default", whiteSpace: "pre-wrap" }, answer.text))))));
};
