import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Chip, Typography } from 'lib/cortiUI';
export const Choice = ({ text, score }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.questions' });
    const theme = useTheme();
    return (React.createElement(Base, { display: "flex", flexDirection: "row", gap: 4, alignItems: "center", justifyContent: "space-between", key: text },
        React.createElement(Typography, { color: "default", variant: "subtitle2" }, text),
        React.createElement(Chip, { background: theme.palette.background.default, label: t('points', {
                count: score,
                defaultValue_one: `{{count}} point`,
                defaultValue_other: `{{count}} points`,
            }) })));
};
