import React from 'react';
import { useRouteMatch } from 'react-router';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Avatar, Base, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Tooltip, } from 'lib/cortiUI';
export const NavListItemRenderer = ({ item, isOpen, idx, onClick, }) => {
    var _a, _b, _c;
    const routeMatch = useRouteMatch((_a = item.path) !== null && _a !== void 0 ? _a : '');
    const isSelected = item.path && item.path !== '/' ? Boolean(routeMatch) : false;
    const content = (React.createElement(NavListItem, { key: (_b = item.path) !== null && _b !== void 0 ? _b : idx, isSelected: isSelected, onClick: () => {
            onClick(item);
        }, item: item }));
    return isOpen ? (content) : (React.createElement(Tooltip, { key: (_c = item.path) !== null && _c !== void 0 ? _c : idx, title: item.text, placement: "right" },
        React.createElement(Base, null, content)));
};
const NavListItem = ({ isSelected, item, onClick, }) => {
    const theme = useTheme();
    return (React.createElement(ListItemButton, { "data-cy": "system-menu-item", selected: isSelected, disabled: item.disabled, classes: {
            root: css({
                background: 'inherit',
                borderRadius: 'unset',
                color: theme.palette.text.primary,
                paddingLeft: item.useAvatarAsIcon ? '12px !important' : undefined,
                '&:not(:last-child)': { marginBottom: 'unset' },
            }),
            selected: css({
                //!important is needed because ListItemButton selected background has !important
                background: 'inherit !important',
                color: theme.palette.primary.main,
            }),
        }, onClick: onClick },
        item.useAvatarAsIcon ? (React.createElement(ListItemAvatar, { className: css({ minWidth: '40px' }) },
            React.createElement(Avatar, { titleText: item.text, size: "small", color: theme.palette.background.paper, bgColor: isSelected ? theme.palette.primary.main : theme.palette.action.active }))) : (React.createElement(ListItemIcon, { classes: {
                root: css({
                    minWidth: '36px',
                    color: isSelected ? theme.palette.primary.main : theme.palette.action.active,
                }),
            } }, item.icon && React.createElement(item.icon, null))),
        React.createElement(ListItemText, { primary: item.text, primaryTypographyProps: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
            } })));
};
