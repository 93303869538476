var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Column as VirtualizedColumn, Table as VirtualizedTable, } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { css, getScrollerCss } from '@corti/style';
import { uuid } from '@corti/uuid';
import { Base } from 'lib/cortiUI/components/Base';
import { cortiUIContext } from 'lib/cortiUI/context';
import { DefaultCellRenderer } from './DefaultCellRenderer';
import { DefaultHeaderCellRenderer } from './DefaultHeaderCellRenderer';
import { DefaultHeaderRowRenderer } from './DefaultHeaderRowRenderer';
import { DefaultRowRenderer } from './DefaultRowRenderer';
import { TableColumn } from './TableColumn';
import { TableController, } from './TableController';
export { VirtualizedTable };
/**
 * Component displays set of data in table layout
 *  @deprecated Use component DataGrid instead.
 */
export class Table extends React.Component {
    constructor() {
        super(...arguments);
        // In order to access the specific scrollable grid node element
        // in the Table using DOM selectors, we have to apply unique id to the Table node element.
        Object.defineProperty(this, "tableID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: `table-${uuid()}`
        });
        Object.defineProperty(this, "ROW_HEIGHT", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 64
        });
        Object.defineProperty(this, "OVERSCAN_ROW_COUNT", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 10
        });
        Object.defineProperty(this, "headerRowRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "scrollableGridRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "getScrollableGridElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                // The only way to get the node ref of the Grid element is using DOM API.
                // React Virtualized library doesnt provide its own API to get the access.
                return document.querySelector(`#${this.tableID} > .ReactVirtualized__Table__Grid`);
            }
        });
        Object.defineProperty(this, "handleHeaderRowScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                window.requestAnimationFrame(() => {
                    if (e.target && this.headerRowRef) {
                        //@ts-ignore
                        this.headerRowRef.scrollLeft = e.target.scrollLeft;
                    }
                });
            }
        });
        Object.defineProperty(this, "getTableContent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (tableRenderProps) => {
                const { children } = this.props;
                const childrenElements = children(tableRenderProps);
                return React.Children.map(childrenElements, (child, idx) => {
                    if (child) {
                        if (child.type !== TableColumn) {
                            throw new Error(`Table component children are invalid. Only TableColumn can be passed`);
                        }
                        else {
                            const isLast = React.Children.count(childrenElements) - 1 === idx;
                            const getCellRenderer = (cellProps) => {
                                if (child.props.cellRenderer) {
                                    return child.props.cellRenderer(cellProps);
                                }
                                else {
                                    return React.createElement(DefaultCellRenderer, { requiredProps: cellProps });
                                }
                            };
                            const getHeaderCellRenderer = (headerProps) => {
                                const newHeaderProps = Object.assign(Object.assign({}, headerProps), { tableControllerState: tableRenderProps });
                                if (child.props.headerRenderer) {
                                    return child.props.headerRenderer(newHeaderProps);
                                }
                                else {
                                    return React.createElement(DefaultHeaderCellRenderer, { requiredProps: newHeaderProps });
                                }
                            };
                            const getFlexGrow = () => {
                                return child.props.flexGrow !== undefined ? child.props.flexGrow : isLast ? 1 : 0;
                            };
                            return (React.createElement(VirtualizedColumn, Object.assign({}, child.props, { cellRenderer: getCellRenderer, headerRenderer: getHeaderCellRenderer, flexGrow: getFlexGrow() })));
                        }
                    }
                });
            }
        });
    }
    componentDidMount() {
        this.scrollableGridRef = this.getScrollableGridElement();
        if (this.scrollableGridRef) {
            this.scrollableGridRef.addEventListener('scroll', this.handleHeaderRowScroll);
        }
    }
    componentWillUnmount() {
        if (this.scrollableGridRef) {
            this.scrollableGridRef.removeEventListener('scroll', this.handleHeaderRowScroll);
        }
    }
    render() {
        const { theme } = this.context;
        const _a = this.props, { rows, columns, minColumnWidth, onColumnResizeEnd, width, height, disableSort, disableResize, rowHeight = this.ROW_HEIGHT, headerHeight = this.ROW_HEIGHT, overscanRowCount = this.OVERSCAN_ROW_COUNT, gridClassName, className, rowRenderer, headerRowRenderer, children, innerRef, ref } = _a, rest = __rest(_a, ["rows", "columns", "minColumnWidth", "onColumnResizeEnd", "width", "height", "disableSort", "disableResize", "rowHeight", "headerHeight", "overscanRowCount", "gridClassName", "className", "rowRenderer", "headerRowRenderer", "children", "innerRef", "ref"]);
        return (React.createElement(TableController, { rows: rows, columns: columns, minColumnWidth: minColumnWidth, onColumnResizeEnd: onColumnResizeEnd, width: width, height: height, disableSort: disableSort, disableResize: disableResize }, (tableControllerState) => {
            const { rows, tableHeight, tableWidth } = tableControllerState;
            const getDefaultTableStyles = () => ({
                overflow: 'hidden',
            });
            const getDefaultGridStyles = () => {
                const totalContentHeight = rows.length * rowHeight + headerHeight;
                const scrollableGridWidth = totalContentHeight >= tableHeight ? tableWidth - 10 : tableWidth;
                return {
                    outline: 'none',
                    width: '100% !important',
                    overflow: 'auto !important',
                    // By default react virtualized table doesnt support horizontal scrolling.
                    // To enable it we have to overwrite width of the inner grid container.
                    '.ReactVirtualized__Grid__innerScrollContainer': {
                        width: `${scrollableGridWidth}px !important`,
                    },
                };
            };
            const getHeaderRowRenderer = (props) => {
                return (React.createElement(Base, { ref: (ref) => (this.headerRowRef = ref), className: css({ overflow: 'hidden' }) }, headerRowRenderer ? (headerRowRenderer(props)) : (React.createElement(DefaultHeaderRowRenderer, { requiredProps: props }))));
            };
            const getRowRenderer = (props) => {
                if (rowRenderer) {
                    return rowRenderer(props);
                }
                else {
                    return React.createElement(DefaultRowRenderer, { key: props.index, requiredProps: props });
                }
            };
            const getLoadedRow = (params) => {
                return rows[params.index] || {};
            };
            return (React.createElement(VirtualizedTable, Object.assign({ id: this.tableID, height: tableHeight, width: tableWidth, rowHeight: rowHeight, headerHeight: headerHeight, overscanRowCount: overscanRowCount, rowCount: rows.length, rowGetter: getLoadedRow, headerRowRenderer: getHeaderRowRenderer, rowRenderer: getRowRenderer, className: css(getDefaultTableStyles(), className), gridClassName: css(getScrollerCss({ theme }), getDefaultGridStyles(), gridClassName), ref: innerRef }, rest), this.getTableContent(tableControllerState)));
        }));
    }
}
Object.defineProperty(Table, "contextType", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: cortiUIContext
});
