import React from 'react';
import { getInteractionByCallID } from '@corti/mission-control-api';
const initialState = {
    interaction: undefined,
    isLoading: false,
    refetchInteraction: () => { },
};
const InteractionContext = React.createContext(initialState);
export const InteractionProvider = ({ children, callID }) => {
    const [interaction, setInteraction] = React.useState();
    const [isInteractionLoading, setIsInteractionLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchInteraction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callID]);
    async function fetchInteraction() {
        try {
            setIsInteractionLoading(true);
            const res = await getInteractionByCallID(callID);
            setInteraction(res);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsInteractionLoading(false);
        }
    }
    const value = React.useMemo(() => ({
        interaction,
        isLoading: isInteractionLoading,
        refetchInteraction: fetchInteraction,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interaction, isInteractionLoading]);
    return React.createElement(InteractionContext.Provider, { value: value }, children);
};
export const useInteraction = () => {
    const context = React.useContext(InteractionContext);
    if (!context) {
        throw new Error('useInteraction must be used within a InteractionProvider');
    }
    return context;
};
