import React from 'react';
import { css, transparentize } from '@corti/style';
import { Base, Typography, useCortiUIContext } from 'lib/cortiUI';
import { AcceptedTimelineEntriesList } from './AcceptedTimelineEntriesList';
import { DropdownField } from './DropdownField';
import { FeedbackFormFieldActionBar } from './FeedbackFormFieldActionBar';
import { FieldTitle } from './FieldTitle';
import { LongTextField } from './LongTextField';
import { RadioField } from './RadioField';
import { ShortTextField } from './ShortTextField';
function ValueRenderer({ isPreviewMode, descriptor, onChange, onChoiceClick, onChoiceHintClick, value, }) {
    const commonProps = {
        name: descriptor.id,
        isPreviewMode,
    };
    function getRenderer() {
        switch (descriptor.type) {
            case 'dropdown':
                return (React.createElement(DropdownField, Object.assign({}, commonProps, { value: value, onChange: onChange, choices: descriptor.choices, onChoiceHintClick: onChoiceHintClick })));
            case 'long-text':
                return React.createElement(LongTextField, Object.assign({}, commonProps, { value: value, onChange: onChange }));
            case 'short-text':
                return React.createElement(ShortTextField, Object.assign({}, commonProps, { value: value, onChange: onChange }));
            case 'radio':
                return (React.createElement(RadioField, Object.assign({}, commonProps, { value: value, onChange: onChange, choices: descriptor.choices, onChoiceClick: onChoiceClick, onChoiceHintClick: onChoiceHintClick })));
            default:
                console.warn(`Unknown Form Field: ${descriptor.type}`);
                return null;
        }
    }
    return React.createElement(Base, { "data-cy": "feedback-form-value-renderer" }, getRenderer());
}
export function FeedbackFormField(props) {
    const { isPreviewMode, isHighlighted, descriptor, value, commentValue, onChoiceClick, onChange, onCommentChange, onCreateTimelineEntry, onChoiceHintClick, onFieldReset, } = props;
    const showMissingTimelineEntrySuggestions = !isPreviewMode &&
        descriptor.acceptedTimelineEntryDefinitions &&
        descriptor.acceptedTimelineEntryDefinitions.length > 0 &&
        descriptor.type === 'radio' &&
        !descriptor.choices.find((c) => c.type === 'timeline-entry');
    const { theme } = useCortiUIContext();
    return (React.createElement(Base, { display: "grid", gap: 4, "data-cy": "feedback-form-field", p: 5, className: css({
            borderRadius: theme.shape.borderRadius,
            backgroundColor: isHighlighted ? transparentize(0.7, theme.palette.error.light) : undefined,
        }) },
        React.createElement(FieldTitle, { title: descriptor.title, isRequired: descriptor.isRequired }),
        descriptor.description && React.createElement(Typography, null, descriptor.description),
        showMissingTimelineEntrySuggestions && (React.createElement(AcceptedTimelineEntriesList, { definitions: descriptor.acceptedTimelineEntryDefinitions, onEntryClick: onCreateTimelineEntry })),
        React.createElement(ValueRenderer, { isPreviewMode: isPreviewMode, descriptor: descriptor, onChange: onChange, onChoiceClick: onChoiceClick, onChoiceHintClick: onChoiceHintClick, value: value }),
        React.createElement(FeedbackFormFieldActionBar, { isPreviewMode: isPreviewMode, onResetField: onFieldReset, onCommentChange: onCommentChange, commentValue: commentValue, fieldValue: value })));
}
