var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { createReview } from '@corti/mission-control-api';
const initialState = {
    callID: '',
    reviewID: undefined,
    isReviewLoading: false,
    templateID: undefined,
    setTemplateID: () => { },
    createNewReview: async () => '',
};
const ReviewContext = React.createContext(initialState);
export const ReviewProvider = (_a) => {
    var { children } = _a, callProperties = __rest(_a, ["children"]);
    const [reviewID, setReviewID] = React.useState();
    const [isReviewLoading, setIsReviewLoading] = React.useState(false);
    const [templateID, setTemplateID] = React.useState();
    const createNewReview = async () => {
        setIsReviewLoading(true);
        const { id } = await createReview({
            call_reference: {
                call_started_at: callProperties.callStart,
                call_ended_at: callProperties.callEnd,
                user_ids: callProperties.callUserIDs,
            },
            template_id: templateID !== null && templateID !== void 0 ? templateID : '',
            reference_type: 'CALL',
            reference_id: callProperties.callID,
        });
        setReviewID(id);
        setIsReviewLoading(false);
        return id;
    };
    const value = React.useMemo(() => ({
        callID: callProperties.callID,
        reviewID,
        isReviewLoading,
        templateID,
        setTemplateID,
        createNewReview,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callProperties.callID, reviewID, isReviewLoading, templateID]);
    return React.createElement(ReviewContext.Provider, { value: value }, children);
};
export const useReview = () => {
    const context = React.useContext(ReviewContext);
    if (!context) {
        throw new Error('useReview must be used within a ReviewProvider');
    }
    return context;
};
