import { convertZonedToUtcTime, isValid } from '@corti/date';
import { uuid } from '@corti/uuid';
const CASE_FILTER_STARTED_AT = 'startedAt';
const CASE_FILTER_EXTERNAL_ID = 'externalId';
/**
 * Curently this supports only two query parameters - 'startedAt' and 'externalId'.
 * This is a business decision and was approved as a long term solution.
 *
 * @param search
 */
export function getCaseFilterFromSearchParams(search) {
    var _a;
    const searchParams = new URLSearchParams(search);
    const predicates = [];
    // Parse and generate 'startedAt' predicate
    if (searchParams.has(CASE_FILTER_STARTED_AT)) {
        const startedAtPredicate = generateStartedAtFilterPredicate((_a = searchParams.get(CASE_FILTER_STARTED_AT)) !== null && _a !== void 0 ? _a : '');
        startedAtPredicate && predicates.push(startedAtPredicate);
    }
    // Parse and generate 'externalId' predicate
    if (searchParams.has(CASE_FILTER_EXTERNAL_ID)) {
        const externalID = searchParams.get(CASE_FILTER_EXTERNAL_ID);
        externalID &&
            predicates.push({
                id: uuid(),
                attributeID: 'triageSession.externalID',
                comparison: 'eq',
                value: [externalID],
            });
    }
    // Check if any valid predicates were added and returing Filter structure
    if (predicates.length > 0) {
        return {
            junction: 'and',
            entities: [
                {
                    id: 'case',
                    junction: 'and',
                    predicates,
                },
            ],
        };
    }
}
function generateStartedAtFilterPredicate(value) {
    const [start, end] = value.split(',');
    const startDate = start ? convertZonedToUtcTime(new Date(start)) : undefined;
    const endDate = end ? convertZonedToUtcTime(new Date(end)) : undefined;
    if (isValid(startDate) && isValid(endDate)) {
        return {
            id: uuid(),
            attributeID: 'case.startedAt',
            comparison: 'between',
            value: [{ startDate: startDate, endDate: endDate }],
        };
    }
    if (isValid(startDate)) {
        return { id: uuid(), attributeID: 'case.startedAt', comparison: 'after', value: [startDate] };
    }
    if (isValid(endDate)) {
        return { id: uuid(), attributeID: 'case.startedAt', comparison: 'before', value: [endDate] };
    }
}
