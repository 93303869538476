import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { css, darken, getScrollerCss, lighten } from '@corti/style';
import { Base, IconButton, Text, useCortiUIContext } from 'lib/cortiUI';
export function NotificationBody({ bgColor, text = '' }) {
    const { theme } = useCortiUIContext();
    return (React.createElement(Base, { py: 3, px: 6, "data-cy": "notification-details", background: lighten(0.4, bgColor), borderBottomLeftRadius: 4, borderBottomRightRadius: 4, maxHeight: [240, 480], className: css(getScrollerCss({ theme }), {
            color: darken(0.3, bgColor),
        }) },
        React.createElement(Text, { color: "inherit", fontSize: 12, style: { whiteSpace: 'pre-wrap' } }, text),
        React.createElement(IconButton, { size: "small", "data-cy": "notification-copy-to-clipboard-btn", className: css({
                color: 'inherit',
                position: 'absolute',
                bottom: 10,
                right: 10,
            }), onClick: () => {
                void navigator.clipboard.writeText(text);
            }, icon: React.createElement(ContentCopyRoundedIcon, { fontSize: "small" }) })));
}
