var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, runInAction } from 'mobx';
export class RbacService {
    constructor() {
        Object.defineProperty(this, "user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
    }
    setUser(user) {
        runInAction(() => {
            this.user = user;
        });
    }
    hasPermission(permission) {
        return Boolean(this.user && this.user.permissions.includes(permission));
    }
    appReview() {
        return this.hasPermission('app:review');
    }
    appInbox() {
        return this.hasPermission('app:inbox');
    }
    appTranscriptQueries() {
        return this.hasPermission('app:transcript_queries');
    }
    appScreenRecorder() {
        return this.hasPermission('app:screen_recorder');
    }
    feedbackSubmissionCRUD(d = {}) {
        return (this.hasPermission('case_feedback_form_submissions:crud') ||
            (this.user && this.user.id === d.caseOwnerID));
    }
    feedbackSubmissionRead(d = {}) {
        return (this.feedbackSubmissionCRUD(d) ||
            this.hasPermission('case_feedback_form_submissions:read') ||
            (this.user && this.user.id === d.caseOwnerID));
    }
    casesExport() {
        return this.hasPermission('cases:export');
    }
    casesMerge() {
        return this.hasPermission('cases:merge');
    }
    casesCallAudioDownload() {
        return this.hasPermission('cases:call_audio:download');
    }
    casesPublicFiltersCRUD() {
        return this.hasPermission('cases:public_filters:crud');
    }
    caseReviewRequestsDelete() {
        return this.hasPermission('case_review_requests:delete');
    }
    casesOtherUsersRead() {
        return this.hasPermission('cases:other_users:read');
    }
    usersAndRolesCRUD() {
        return this.hasPermission('users:crud');
    }
    casesColumnLayoutCRUD() {
        return this.hasPermission('organization_settings:case_column_layout:crud');
    }
    timelineEntryDefinitionsCRUD() {
        return this.hasPermission('timeline_entry_definitions:crud');
    }
    aiModelDetectSubscriptionsRead() {
        return (this.hasPermission('ai_model:detect_subscriptions:read') ||
            this.hasPermission('ai_model:detect_subscriptions:crud'));
    }
    aiModelDetectSubscriptionsCRUD() {
        return this.hasPermission('ai_model:detect_subscriptions:crud');
    }
    realtimeAppRecentSessionsView() {
        return this.hasPermission('realtime_app:recent_triage_sessions_view');
    }
    mediaLibraryCRUD() {
        return this.hasPermission('media:crud');
    }
    transcriptQueriesCRUD() {
        return this.hasPermission('transcript_queries:crud');
    }
    teamsCRUD() {
        return this.hasPermission('teams:crud');
    }
    feedbackTemplatesCRUD() {
        return this.hasPermission('feedback_form_templates:crud');
    }
    performanceMetricsExcludeCalls() {
        return this.hasPermission('performance_metrics:exclude_calls');
    }
    anonymousReviewersRead() {
        return this.hasPermission('case_feedback_form_submissions:identify_anonymous_reviewers');
    }
    caseTranscriptRead() {
        return this.hasPermission('cases:transcript:read');
    }
    performanceViewsCRUD() {
        return this.hasPermission('performance_views:crud');
    }
    performanceViewsRead() {
        return (this.hasPermission('performance_views:read') || this.hasPermission('performance_views:crud'));
    }
    analyticsViewRead() {
        return this.hasPermission('custom_analytics:read');
    }
    missionControlOtherAssessmentsRead() {
        return this.hasPermission('mission_control_assessments:other_users:read');
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], RbacService.prototype, "user", void 0);
