import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { endOfDay, startOfDay } from '@corti/date';
import { intl } from '@corti/i18n';
import { isValidRegExp } from '@corti/strings';
import { css } from '@corti/style';
import { uuid } from '@corti/uuid';
import { trackerService } from 'browser/services/init';
import { Autocomplete, Base, Button, DateRangePicker, IconButton, MenuItem, Popover, TextField, Tooltip, useCortiUIContext, } from 'lib/cortiUI';
import { SearchBar } from 'lib/cortiUI/components/SearchBar/SearchBar';
export const QuickFiltering = observer((props) => {
    const { model } = props;
    const { casesQuickFilterState, casesFilterDescriptor } = model;
    const { theme } = useCortiUIContext();
    const searchableFilters = casesQuickFilterState.getSearchableFiltersFromDescriptor(model.casesFilterDescriptor);
    const dateRange = casesQuickFilterState.getDateRangeFromAdvancedFilter(model.advancedFilter);
    const [popoverAnchor, setPopoverAnchor] = React.useState(null);
    function handleRelativeDateRangeSelect(_, absoluteDateRange) {
        if (absoluteDateRange) {
            handleDateRangePickerChange({
                startDate: absoluteDateRange.start,
                endDate: absoluteDateRange.end,
            });
        }
    }
    function handleDateRangePickerChange(dateRange) {
        if (dateRange !== undefined && dateRange !== casesQuickFilterState.selectedDateRange) {
            casesQuickFilterState.setSelectedDateRange(dateRange);
            if (casesQuickFilterState.searchValue && casesQuickFilterState.selectedSearchTypeId) {
                setAdvancedFilter(casesQuickFilterState.searchValue, casesQuickFilterState.selectedSearchTypeId, dateRange);
            }
            else {
                handleExistingAdvanceFilter(dateRange, model.advancedFilter);
            }
        }
    }
    function handleExistingAdvanceFilter(draftDateRange, advancedFilter) {
        const advancedFilterClone = cloneDeep(advancedFilter);
        if (casesQuickFilterState.isCaseStartedAtSelected(advancedFilter)) {
            advancedFilterClone.entities
                .filter((e) => e.id === 'case')
                .forEach((e) => {
                e.predicates.forEach((p) => {
                    if (p.attributeID === 'case.startedAt' && p.comparison === 'between') {
                        p.value = [draftDateRange];
                    }
                });
            });
            model.setAdvancedFilter(advancedFilterClone);
        }
        else {
            if (advancedFilterClone.entities.length && advancedFilterClone.entities[0].id !== '') {
                advancedFilterClone.entities.push(createCaseStartedAtEntity(draftDateRange));
                model.setAdvancedFilter(advancedFilterClone);
            }
            else {
                model.setAdvancedFilter({
                    junction: 'and',
                    entities: [createCaseStartedAtEntity(draftDateRange)],
                });
            }
        }
    }
    function createCaseStartedAtEntity(dateRange) {
        return {
            id: 'case',
            junction: 'and',
            predicates: [
                {
                    id: uuid(),
                    attributeID: 'case.startedAt',
                    comparison: 'between',
                    value: [dateRange],
                },
            ],
        };
    }
    function onSearchValueChange(e) {
        const searchValue = e.currentTarget.value;
        casesQuickFilterState.setSearchValue(searchValue);
        if (casesQuickFilterState.selectedSearchTypeId) {
            setAdvancedFilter(searchValue, casesQuickFilterState.selectedSearchTypeId, casesQuickFilterState.selectedDateRange);
        }
    }
    function onSearchTypeChange(item) {
        void casesQuickFilterState.setSelectedSearchTypeId(item.value.toString());
        if (casesQuickFilterState.searchValue) {
            setAdvancedFilter(casesQuickFilterState.searchValue, item.value.toString(), casesQuickFilterState.selectedDateRange);
        }
    }
    function setAdvancedFilter(searchInputValue, selectedSearchTypeId, dateRange) {
        let newFilterInput = undefined;
        if (dateRange) {
            newFilterInput = {
                junction: 'and',
                entities: [createCaseStartedAtEntity(dateRange)],
            };
        }
        const selectedSearchableFilter = searchableFilters.find((f) => getSearchableFilterItemID(f) === selectedSearchTypeId);
        if (selectedSearchableFilter && searchInputValue) {
            const attributes = selectedSearchableFilter.attributeIds;
            const entity = selectedSearchableFilter.entity;
            const predicates = [];
            selectedSearchableFilter.data.forEach((c) => {
                predicates.push({
                    id: uuid(),
                    attributeID: attributes[0],
                    comparison: c.comparison,
                    value: [searchInputValue && c.comparison === 'contains' ? searchInputValue : c.value],
                });
                attributes.shift();
            });
            if (newFilterInput) {
                if (newFilterInput.entities[0].id === entity) {
                    predicates.forEach((p) => {
                        newFilterInput === null || newFilterInput === void 0 ? void 0 : newFilterInput.entities[0].predicates.push(p);
                    });
                }
                else {
                    newFilterInput.entities.push({
                        id: entity,
                        junction: 'and',
                        predicates: predicates,
                    });
                }
            }
            else {
                newFilterInput = {
                    junction: 'and',
                    entities: [
                        {
                            id: entity,
                            junction: 'and',
                            predicates: predicates,
                        },
                    ],
                };
            }
        }
        newFilterInput ? model.setAdvancedFilter(newFilterInput) : model.resetAllFilters();
    }
    function initialDateRange() {
        return {
            startDate: startOfDay(new Date()),
            endDate: endOfDay(new Date()),
        };
    }
    function getSearchableFilterItemID(filter) {
        return filter.entity + filter.attributeIds.toString();
    }
    function getSectionId(itemId) {
        return itemId.includes('customProperty')
            ? intl.t('explore:filters.customProperties', 'Custom properties').toUpperCase()
            : intl.t('explore:filters.caseProperties', 'Case properties').toUpperCase();
    }
    function getSearchableFiltersItems() {
        return searchableFilters.map((f) => {
            const itemId = getSearchableFilterItemID(f);
            return {
                value: itemId,
                text: f.text || '',
                sectionId: getSectionId(itemId),
            };
        });
    }
    function getSelectedSearchableFilterItem() {
        var _a;
        return ((_a = getSearchableFiltersItems().find((i) => i.value === casesQuickFilterState.selectedSearchTypeId)) !== null && _a !== void 0 ? _a : null);
    }
    function onDateRangePickerClose() {
        setPopoverAnchor(null);
        model.applyAdvancedFilter();
        trackerService.track('[Explore] date-range cases filter applied', {
            overall_junction: model.advancedFilter.junction,
            queries: model.advancedFilter.entities.map((it) => ({
                id: it.id,
                junction: it.junction,
                predicates: it.predicates.map((p) => ({
                    attributeID: p.attributeID,
                    comparison: p.comparison,
                    value: p.value,
                })),
            })),
        });
    }
    function onSearch() {
        model.applyAdvancedFilter();
        trackerService.track('[Explore] search-bar cases filter applied', {
            overall_junction: model.advancedFilter.junction,
            queries: model.advancedFilter.entities.map((it) => ({
                id: it.id,
                junction: it.junction,
                predicates: it.predicates.map((p) => ({
                    attributeID: p.attributeID,
                    comparison: p.comparison,
                    value: p.value,
                })),
            })),
        });
    }
    return (React.createElement(Base, { display: "flex", alignItems: "center" },
        React.createElement(Base, { display: "flex", alignItems: "center", className: css({
                borderRadius: theme.shape.borderRadius,
            }) },
            React.createElement(Base, { display: "flex", flex: 1, alignItems: "center" },
                React.createElement(SearchBar, { autoComplete: "off", onChange: onSearchValueChange, value: casesQuickFilterState.searchValue, fullWidth: true, name: "search", disabled: !casesFilterDescriptor, sx: {
                        mr: 2,
                    } }),
                !isValidRegExp(casesQuickFilterState.searchValue) && (React.createElement(Tooltip, { disableInteractive: true, title: intl.t('filterBuilder.invalidRegex', 'The regular expression is not valid') },
                    React.createElement(ErrorRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.error.dark }) }))),
                React.createElement(Base, { width: 400, mr: 4 },
                    React.createElement(Autocomplete, { "data-cy": "search-type", size: "small", options: getSearchableFiltersItems(), groupBy: (option) => { var _a; return (_a = option.sectionId) !== null && _a !== void 0 ? _a : ''; }, getOptionLabel: (option) => option.text, value: getSelectedSearchableFilterItem(), onChange: (_, option) => {
                            if (option) {
                                onSearchTypeChange(option);
                            }
                        }, renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "select-item", selected: state.selected, key: option.value }), option.text)), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('explore:filters.searchType', 'Search type'), InputProps: Object.assign(Object.assign({}, params.InputProps), { classes: {
                                    input: css({
                                        fontSize: theme.typography.fontSize,
                                    }),
                                } }) }))), clearIcon: null, disabled: !casesFilterDescriptor }))),
            React.createElement(Button, { size: "small", variant: "outlined", onClick: onSearch }, intl.t('explore:filters.searchBtn', 'Search'))),
        React.createElement(Tooltip, { disableInteractive: true, title: intl.t('explore:filters.selectDate', 'Select date') },
            React.createElement(Base, null,
                React.createElement(IconButton, { className: css({ marginLeft: 8 }), onClick: (e) => setPopoverAnchor(e.currentTarget), color: dateRange ? 'primary' : undefined, icon: React.createElement(CalendarTodayRoundedIcon, { fontSize: "small" }), disabled: !casesFilterDescriptor }))),
        React.createElement(Popover, { open: Boolean(popoverAnchor), anchorEl: popoverAnchor, onClose: onDateRangePickerClose },
            React.createElement(DateRangePicker, { minDate: new Date(2000, 0), range: casesQuickFilterState.selectedDateRange || dateRange || initialDateRange(), onChange: handleDateRangePickerChange, withTime: true, relativeDateRangePickerProps: {
                    enabled: true,
                    onSelect: handleRelativeDateRangeSelect,
                    dateRanges: (ranges) => {
                        return [
                            ranges.LAST_YEAR,
                            ranges.THIS_YEAR,
                            ranges.LAST_MONTH,
                            ranges.THIS_MONTH,
                            ranges.LAST_WEEK,
                            ranges.LAST_30_DAYS,
                            ranges.YESTERDAY,
                        ];
                    },
                } }))));
});
