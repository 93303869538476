export function convertData(input) {
    const splittedInput = splitServerInput(input);
    const groupedByOneSecondDiff = groupPhrasesByMillisecondsDifference(splittedInput);
    const phrasesWithSeconds = groupedByOneSecondDiff.map((it) => ({
        time: Math.floor(it.time / 1000),
        text: it.text,
    }));
    return phrasesWithSeconds;
}
function splitServerInput(input) {
    const startTimes = input.start.split(/\s+/);
    const texts = input.text.split(/(?<=\S)\s+(?=\S)/);
    //Log for easier debugging in production environment
    if (startTimes.length !== texts.length) {
        console.error('Error - different lengths of parsed data.', 'startTimes - ', startTimes.length, 'texts - ', texts.length);
    }
    return startTimes.map((time, index) => ({
        time: parseMillisecondsFromTimeString(time),
        text: texts[index],
    }));
}
function groupPhrasesByMillisecondsDifference(words, diff = 1000) {
    const phrases = [];
    let currentPhrase = null;
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const prevWord = words[i - 1];
        if (i === 0 || word.time - prevWord.time > diff) {
            if (currentPhrase !== null) {
                phrases.push(currentPhrase);
            }
            currentPhrase = word;
        }
        else {
            if (currentPhrase !== null) {
                currentPhrase.text += ' ' + word.text;
            }
        }
    }
    if (currentPhrase !== null) {
        phrases.push(currentPhrase);
    }
    return phrases;
}
function parseMillisecondsFromTimeString(timeString) {
    const [hours, minutes, seconds, milliseconds = '0'] = timeString.split(/[:.]/).map(Number);
    const paddedMilliseconds = milliseconds.toString().replace(/0+$/, '').padEnd(3, '0');
    return hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000 + parseInt(paddedMilliseconds);
}
