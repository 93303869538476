import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { transparentize } from '@corti/style';
import { Base, useCortiUIContext } from 'lib/cortiUI';
import { getChartDataExtremes } from '../utils';
import { ChartLegend } from './ChartLegend';
export const TimeSeriesChart = observer(function TimeSeriesChart(props) {
    var _a;
    const RANGE_CHANGE_DEBOUNCE = 500;
    const DEFAULT_COLOR = '#b5b5b5';
    let initiatedFirstRender = false;
    const { data, onDateRangeChange } = props;
    const seriesOptions = data.map((timeSeries) => {
        var _a;
        return ({
            type: 'line',
            color: (_a = timeSeries.termGroupColor) !== null && _a !== void 0 ? _a : DEFAULT_COLOR,
            showInNavigator: true,
            showInLegend: true,
            name: timeSeries.title,
            data: timeSeries.dataPoints.map((it, idx, arr) => {
                let isMarkerEnabled = false;
                if (arr.length === 1) {
                    isMarkerEnabled = true;
                }
                else if (idx === 0) {
                    if (arr[idx + 1].callCount == null)
                        isMarkerEnabled = true;
                }
                else if (idx === arr.length - 1) {
                    if (arr[idx - 1].callCount == null)
                        isMarkerEnabled = true;
                }
                else {
                    if (arr[idx + 1].callCount == null && arr[idx - 1].callCount == null)
                        isMarkerEnabled = true;
                }
                return {
                    x: it.startOfPeriod.getTime(),
                    y: it.callCount != null ? Number(it.callCount.toFixed(1)) : null,
                    marker: {
                        enabled: isMarkerEnabled,
                        radius: 4,
                    },
                };
            }),
        });
    });
    const { theme } = useCortiUIContext();
    const containerRef = React.useRef(null);
    const containerSizeObserver = React.useRef();
    const options = React.useRef({
        chart: {
            backgroundColor: 'transparent',
            style: {
                fontFamily: theme.typography.fontFamily,
            },
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        scrollbar: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        time: {
            useUTC: false,
        },
        navigator: {
            handles: {
                symbols: ['circle', 'circle'],
                borderColor: '#BDBDBD',
            },
            height: 64,
            outlineColor: 'transparent',
            maskFill: `${transparentize(0.75, theme.palette.grey[200])}`,
            series: {
                type: 'line',
            },
            xAxis: {
                lineColor: theme.palette.background.divider,
                tickColor: 'transparent',
                gridLineColor: theme.palette.background.divider,
                labels: {
                    align: 'center',
                    y: 12,
                    style: {
                        color: theme.palette.text.secondary,
                    },
                    format: '{value:%b %e, %Y}',
                },
            },
        },
        rangeSelector: {
            inputEnabled: false,
            buttons: [],
        },
        xAxis: {
            lineColor: theme.palette.background.divider,
            tickColor: theme.palette.background.divider,
            type: 'datetime',
            maxPadding: 0,
            minTickInterval: 86400000,
            crosshair: true,
            labels: {
                format: '{value:%b %e, %Y}',
                style: {
                    color: theme.palette.text.secondary,
                },
            },
            events: {
                afterSetExtremes: debounce(handleDateRangeChange, RANGE_CHANGE_DEBOUNCE),
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            min: 0,
            gridLineColor: transparentize(0.15, theme.palette.background.divider),
            labels: {
                align: 'center',
                style: {
                    color: theme.palette.text.secondary,
                },
            },
            plotLines: [],
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                clip: false,
            },
        },
        tooltip: {
            split: true,
            shared: true,
            backgroundColor: theme.palette.background.card,
            borderColor: theme.palette.background.divider,
            borderRadius: 5,
            shadow: false,
            padding: 8,
            shape: 'square',
            useHTML: true,
            pointFormat: `<div style="display: flex; align-items: center; justify-content: space-between; width: 200px; overflow: hidden"><span style="color: ${theme.palette.text.secondary}; line-height: 166%; font-size: 12px; letter-spacing: 0.4px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{series.name}</span><span style="color: {series.color}; font-weight: bold;">{point.y}</span></div>`,
            style: {
                color: theme.palette.text.secondary,
                borderRadius: 5,
                fontSize: '12px',
                height: 38,
            },
            xDateFormat: '%b %e, %Y',
        },
        series: [],
    });
    const chartComponent = React.useRef(null);
    const [chartSeries, setChartSeries] = React.useState([]);
    React.useEffect(() => {
        return () => {
            var _a;
            (_a = containerSizeObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
            containerSizeObserver.current = undefined;
        };
    }, []);
    React.useEffect(() => {
        if (chartComponent.current)
            setChartSeries([...chartComponent.current.chart.series]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartComponent.current, (_a = chartComponent.current) === null || _a === void 0 ? void 0 : _a.chart.series, data]);
    function toggleSeriesVisibility(currentSeries) {
        currentSeries.setVisible(!currentSeries.visible, false);
        const extremes = getChartDataExtremes(data.map(({ dataPoints }) => ({
            min: dataPoints[0].startOfPeriod.getTime(),
            max: dataPoints.slice(-1)[0].startOfPeriod.getTime(),
        })));
        currentSeries.xAxis.setExtremes(extremes.min, extremes.max);
        setChartSeries((current) => current.map((i) => (i.index === currentSeries.index ? currentSeries : i)));
        currentSeries.chart.redraw(true);
    }
    function initContainerSizeObserver(chart) {
        var _a, _b;
        (_a = containerSizeObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        containerSizeObserver.current = undefined;
        containerSizeObserver.current = new ResizeObserver(() => chart.reflow());
        if (containerRef.current) {
            (_b = containerSizeObserver.current) === null || _b === void 0 ? void 0 : _b.observe(containerRef.current);
        }
    }
    function setContainerRef(ref) {
        var _a, _b;
        if (containerRef.current) {
            (_a = containerSizeObserver.current) === null || _a === void 0 ? void 0 : _a.unobserve(containerRef.current);
        }
        containerRef.current = ref;
        if (containerRef.current) {
            (_b = containerSizeObserver.current) === null || _b === void 0 ? void 0 : _b.observe(containerRef.current);
        }
    }
    function handleDateRangeChange(e) {
        if (!initiatedFirstRender) {
            initiatedFirstRender = true;
            return;
        }
        if (!e.trigger)
            return;
        onDateRangeChange === null || onDateRangeChange === void 0 ? void 0 : onDateRangeChange({
            start: new Date(e.min),
            end: new Date(e.max),
        });
    }
    return (React.createElement(Base, { ref: setContainerRef, height: "100%", width: "100%" },
        React.createElement(HighchartsReact, { ref: chartComponent, constructorType: 'stockChart', highcharts: Highcharts, options: Object.assign(Object.assign({}, options.current), { series: seriesOptions }), callback: initContainerSizeObserver }),
        React.createElement(ChartLegend, { chartSeries: chartSeries, onToggleSeriesVisibility: toggleSeriesVisibility })));
});
