import React from 'react';
import { css, mix } from '@corti/style';
import { LinearProgress, useCortiUIContext } from 'lib/cortiUI';
export function NotificationProgress({ bgColor, progress }) {
    const { theme } = useCortiUIContext();
    return (React.createElement(LinearProgress, { classes: {
            root: css({
                height: 5,
            }),
            colorPrimary: css({
                backgroundColor: mix(0.7, bgColor, theme.palette.background.default),
            }),
            barColorPrimary: css({
                backgroundColor: mix(0.3, bgColor, theme.palette.background.default),
            }),
        }, value: 100 - progress, variant: "determinate", className: css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
        }) }));
}
