var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, transparentize } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box';
import { Text } from 'lib/cortiUI/components/Text';
const getInitials = (title) => {
    title = title.replace(/[^a-zA-Z0-9 ]/g, '').trim();
    let names = title.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};
const getColor = (str) => {
    const colors = [
        '#548AFF',
        '#00b894',
        '#00cec9',
        '#6c5ce7',
        '#e17055',
        '#e84393',
        '#6ab04c',
        '#4834d4',
        '#22a6b3',
    ];
    let index = 0;
    if (str) {
        index = str.length % colors.length;
    }
    return colors[index];
};
function getSize(size) {
    switch (size) {
        case 'tiny': {
            return 24;
        }
        case 'small': {
            return 32;
        }
        case 'regular': {
            return 48;
        }
        case 'large': {
            return 64;
        }
    }
}
/**
 * Component that displays initials of provided title text and applies random colors
 */
export const Avatar = React.forwardRef(function Avatar(props, ref) {
    const { titleText, className, size = 'regular', borderType = 'none', sizeInPx, color, bgColor } = props, rest = __rest(props, ["titleText", "className", "size", "borderType", "sizeInPx", "color", "bgColor"]);
    const _color = color !== null && color !== void 0 ? color : getColor(titleText);
    const borderColor = transparentize(0.5, _color);
    const _sizeInPx = sizeInPx !== null && sizeInPx !== void 0 ? sizeInPx : getSize(size);
    return (React.createElement(Box, Object.assign({ ref: ref, justifyContent: "center", alignItems: "center", className: css({
            backgroundColor: bgColor !== null && bgColor !== void 0 ? bgColor : transparentize(0.85, _color),
            height: _sizeInPx,
            width: _sizeInPx,
            boxSizing: borderType === 'inside' ? 'border-box' : 'content-box',
            borderRadius: '50%',
            borderStyle: borderType !== 'none' ? 'solid' : undefined,
            borderWidth: borderType !== 'none' ? 3 : undefined,
            borderColor: borderColor,
            outline: 0,
            flexShrink: 0,
        }, className) }, rest), titleText && (React.createElement(Text, { noWrap: true, noSelect: true, color: _color, fontSize: _sizeInPx / 2.6, fontWeight: 500 }, getInitials(titleText)))));
});
