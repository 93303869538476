var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { errorReportService } from 'browser/services/init';
import { ErrorBoundary, FallbackView } from 'lib/cortiUI';
import { Route as RRRoute } from 'lib/router';
export const REDIRECT_ROUTE_QUERY_PARAM = 'redirect';
/**
 * A wrapper around react-router's Route with some extras:
 * - error boundaries
 * - permission checks
 */
export function Route(_a) {
    var { component: Component, render, isAllowed, withErrorBoundary } = _a, rest = __rest(_a, ["component", "render", "isAllowed", "withErrorBoundary"]);
    return (React.createElement(RRRoute, Object.assign({}, rest, { render: (routeProps) => {
            const routeElement = Component ? (React.createElement(Component, Object.assign({}, routeProps))) : render ? (render(routeProps)) : null;
            const renderedContent = isAllowed === undefined
                ? routeElement
                : isAllowed === true
                    ? routeElement
                    : 'Not Allowed';
            if (withErrorBoundary) {
                return (React.createElement(ErrorBoundary
                // key is needed to invalidate react-router cache
                // when Route is rendered inside Switch component
                , { 
                    // key is needed to invalidate react-router cache
                    // when Route is rendered inside Switch component
                    key: Array.isArray(rest.path) ? rest.path[0] : rest.path, fallbackComponent: (error) => (React.createElement(FallbackView, { title: "Application crashed", text: "Try to navigate to another application in the sidebar.", devMessage: error.message })), onError: (error) => errorReportService.captureException(error) }, renderedContent));
            }
            else {
                return renderedContent;
            }
        } })));
}
