import React from 'react';
import { intl } from '@corti/i18n';
import { useDebouncedEffect } from '@corti/react';
import { css } from '@corti/style';
import { Base, Box, Text, useCortiUIContext } from 'lib/cortiUI';
import { ProgressBar } from './ProgressBar';
const DEFAULT_SIZE = 120;
const MOUSE_DEBOUNCE = 400;
export function DetectionsWidget(props) {
    const { size = DEFAULT_SIZE, value, target, disableAnimation = false, label = `${Math.round((value !== null && value !== void 0 ? value : 0) * 100)}%`, } = props;
    const { theme } = useCortiUIContext();
    const [isMouseOver, setIsMouseOver] = React.useState(false);
    const [showTarget, setShowTarget] = React.useState(false);
    useDebouncedEffect(() => {
        if (isMouseOver) {
            setShowTarget(true);
        }
    }, MOUSE_DEBOUNCE, [isMouseOver]);
    const handleTargetMouseOver = React.useCallback(() => {
        setIsMouseOver(true);
    }, []);
    const handleTargetMouseOut = React.useCallback(() => {
        setIsMouseOver(false);
        setShowTarget(false);
    }, []);
    if (value == undefined) {
        return (React.createElement(Text, { fontSize: 32, fontWeight: 400, lineHeight: 1.3, noWrap: true, color: theme.palette.text.hint }, intl.t('notApplicable', 'N/A')));
    }
    return (React.createElement(Base, { position: "relative", width: size, height: size },
        React.createElement(ProgressBar, { value: value, target: target, size: size, onTargetMouseOut: handleTargetMouseOut, onTargetMouseOver: handleTargetMouseOver, disableAnimation: disableAnimation }),
        React.createElement(Box, { position: "absolute", top: "50%", left: "50%", justifyContent: "center", alignItems: "center", className: css({
                transform: 'translate(-50%, -50%)',
            }) }, target != undefined && showTarget ? (React.createElement(React.Fragment, null,
            React.createElement(Text, { width: "100%", textAlign: "center", noWrap: true, color: theme.palette.text.secondary, className: css({
                    fontSize: size / 10,
                    lineHeight: 1,
                    fontVariantNumeric: 'tabular-nums',
                }) }, intl.t('performanceApp:target', 'Target')),
            React.createElement(Text, { width: "100%", textAlign: "center", noWrap: true, color: theme.palette.text.primary, className: css({ fontSize: size / 4 }) }, `${Math.round(target * 100)}%`))) : (React.createElement(Text, { width: "100%", textAlign: "center", noWrap: true, color: theme.palette.text.primary, className: css({ fontSize: size / 4 }) }, label)))));
}
