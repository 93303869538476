import { gql } from '@apollo/client';
export const getCaseCallValidationQuery = gql `
  query case($id: String!) {
    case(id: $id) {
      id
      calls {
        id
        callServiceCallId
        start
        stop
        user {
          id
          name
        }
      }
      createdBy {
        id
        name
      }
    }
  }
`;
