import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { css, getScrollerCss, transparentize } from '@corti/style';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Avatar, Base, Box, Button, IconButton, Typography, useCortiUIContext } from 'lib/cortiUI';
import { useCaseViewContext } from '../CaseViewContext';
import { CaseFeedbackDetailPrint } from './CaseFeedbackDetailPrint';
import { FeedbackForm } from './FeedbackForm';
import { submissionToFormValue, transformTemplate } from './transformers';
export function CaseFeedbackDetailsView() {
    const { caseViewState } = useCaseViewContext();
    const { caseEntity } = caseViewState;
    const { activeFeedbackID } = caseViewState.feedbackViewState;
    const { theme } = useCortiUIContext();
    const [remoteData, setRemoteData] = React.useState({
        isLoading: false,
        data: null,
    });
    React.useEffect(() => {
        if (activeFeedbackID) {
            setRemoteData((d) => (Object.assign(Object.assign({}, d), { isLoading: true })));
            api.caseFeedback
                .getFeedbackFormSubmission(activeFeedbackID)
                .then((data) => {
                setupTimeline(data);
                setRemoteData({ isLoading: false, data });
            })
                .catch((err) => {
                setRemoteData((d) => (Object.assign(Object.assign({}, d), { isLoading: false })));
                throw err;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function setupTimeline(submission) {
        if (!caseEntity) {
            console.warn(`Case is not loaded before setting up the timeline.
          Timeline preview of the feedback submission will not be displayed
      `);
            return;
        }
        caseEntity.enterTimelinePreview(submission.id);
    }
    function acknowledgeSubmission() {
        if (caseEntity && remoteData.data) {
            void caseEntity.acknowledgeFeedbackFormSubmission(remoteData.data.id);
        }
    }
    return (React.createElement(Observer, null, () => {
        var _a;
        const { feedbackViewState } = caseViewState;
        const isAcknowledged = caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.getIsFeedbackFormSubmissionAcknowledged(activeFeedbackID || '');
        return (React.createElement(Base, { display: "grid", gridGap: 7, overflow: "hidden" },
            React.createElement(Base, { mx: 8 },
                React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                    React.createElement(IconButton, { "data-cy": "back-to-feedback-list-btn", icon: React.createElement(KeyboardArrowLeftRoundedIcon, { fontSize: "small" }), onClick: () => {
                            caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.exitTimelinePreview();
                            feedbackViewState.setActiveView('feedback-list');
                        }, sx: { border: 1, margin: 1 }, className: css({ bordercolor: transparentize(0.5, theme.palette.text.primary) }) }),
                    React.createElement(Typography, { variant: "h6", ml: 2, fontWeight: "normal" }, (_a = remoteData.data) === null || _a === void 0 ? void 0 : _a.template.title),
                    remoteData.data && caseEntity && (React.createElement(CaseFeedbackDetailPrint, { feedbackFormSubmission: remoteData.data })))),
            remoteData.data && caseEntity && (React.createElement(React.Fragment, null,
                remoteData.data.createdBy ? (React.createElement(Box, { flexDirection: "row", mx: 8, "data-cy": "review-info-section" },
                    React.createElement(Avatar, { titleText: remoteData.data.createdBy.name }),
                    React.createElement(Box, { ml: 5, justifyContent: "center" },
                        React.createElement(Typography, { variant: "caption", color: "secondaryText", mb: 1 }, formatDateTime(new Date(remoteData.data.createdAt))),
                        React.createElement(Typography, { variant: "body2" }, remoteData.data.createdBy.name)))) : (React.createElement(Box, { display: "inline-flex", flexDirection: "row", mx: 8, "data-cy": "review-info-section" },
                    React.createElement(Typography, { variant: "body2", mr: 1 },
                        intl.t('explore:feedbackView.createdAt', 'Created At'),
                        ":"),
                    React.createElement(Typography, { variant: "body2", color: "secondaryText", mb: 1 }, formatDateTime(new Date(remoteData.data.createdAt))))),
                React.createElement(Base, { className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
                    React.createElement(Base, { mx: 8 },
                        React.createElement(FeedbackForm, { isPreviewMode: true, template: transformTemplate(remoteData.data.template, caseEntity), formValue: submissionToFormValue(remoteData.data, caseEntity), onChoiceClick: (_, val) => {
                                const entry = caseEntity.timelineEditor.getTimelineEntry(String(val));
                                if (entry) {
                                    caseEntity.timelineEditor.setActiveTimelineEntry(entry.id);
                                    if (caseEntity.activeCall && caseEntity.activeCallPlayerState) {
                                        const entryOffset = entry.getOffsetPosition(caseEntity.activeCall.start).start / 1000;
                                        caseEntity.activeCallPlayerState.setCurrentTime(entryOffset);
                                    }
                                }
                            }, onChoiceHintClick: (timelineEntry) => {
                                caseEntity.timelineEditor.setActiveTimelineEntry(timelineEntry.id);
                                trackerService.track('[Single case] timeline-entry focused in Feedback form choices hints', {
                                    timelineEntry: {
                                        id: timelineEntry.id,
                                        callID: timelineEntry.callID,
                                        definition: timelineEntry.definition,
                                        createdBy: timelineEntry.createdBy,
                                        createdAt: timelineEntry.createdAt,
                                        title: timelineEntry.definition.title,
                                        text: timelineEntry.definition.text,
                                        color: timelineEntry.definition.style.color,
                                    },
                                });
                                if (caseEntity.activeCall && caseEntity.activeCallPlayerState) {
                                    const entryOffset = timelineEntry.getOffsetPosition(caseEntity.activeCall.start).start /
                                        1000;
                                    caseEntity.activeCallPlayerState.setCurrentTime(entryOffset);
                                }
                            } }),
                        caseEntity.createdBy.id === authStore.currentUser.id && (React.createElement(Button, { "data-cy": "acknowledge-btn", sx: { mt: 7 }, color: "primary", disabled: isAcknowledged, onClick: acknowledgeSubmission, startIcon: isAcknowledged && React.createElement(CheckCircleRoundedIcon, { fontSize: "small" }) }, isAcknowledged
                            ? intl.t('explore:feedbackView.submissionAcknowledged', 'Acknowledged')
                            : intl.t('explore:feedbackView.acknowledgeSubmission', 'Acknowledge')))))))));
    }));
}
