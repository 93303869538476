import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { getReviewsList } from '@corti/mission-control-api';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Base, Card, DataGrid } from 'lib/cortiUI';
import { useParameters, useTemplate } from '../../context';
import { NoRowsOverlay, ReviewsDataGridLoader } from './components';
import { getDataGridColumnsData, getDataGridRowsData, useIntersectionObserver } from './utils';
import { mergeReviewsListWithCallsMetadata } from './utils/data';
export const ReviewsDataGrid = () => {
    const theme = useTheme();
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'callsDataGrid' });
    const history = useHistory();
    const { dateRange, teams, agents, filterRequestParams } = useParameters();
    const { threshold } = useTemplate();
    const [offset, setOffset] = React.useState(0);
    const [items, setItems] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const rows = getDataGridRowsData(items);
    const columns = getDataGridColumnsData(threshold);
    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRequestParams]);
    const handleObserver = React.useCallback(async (entries) => {
        const [target] = entries;
        if (target.isIntersecting && !isDataLoading && offset) {
            await loadData(offset);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset, isDataLoading, dateRange, teams, agents]);
    const { observerAnchorRef } = useIntersectionObserver(handleObserver);
    const loadData = async (newOffset = 0) => {
        if (newOffset === null)
            return;
        const ITEMS_LIMIT = 100;
        setIsDataLoading(true);
        try {
            const reviewsListResponse = await getReviewsList(Object.assign(Object.assign({ offset: newOffset, limit: ITEMS_LIMIT }, filterRequestParams), { latest_only: true }));
            const callMetadata = reviewsListResponse.length !== 0
                ? await api.transcripts.getCoreCallsMetadata(reviewsListResponse.map((review) => review.reference_id))
                : [];
            const mergedData = mergeReviewsListWithCallsMetadata(reviewsListResponse, callMetadata);
            if (newOffset) {
                setItems((prev) => [...prev, ...mergedData]);
            }
            else {
                setItems(mergedData);
            }
            if (reviewsListResponse.length < ITEMS_LIMIT) {
                setOffset(null);
            }
            else {
                setOffset(newOffset + ITEMS_LIMIT);
            }
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchCallsError', 'Failed to fetch calls. Please try again later.'),
            });
        }
        finally {
            setIsDataLoading(false);
        }
    };
    function handleRowClick(caseID) {
        history.push(`${history.location.pathname}/${caseID}`);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { p: 0 },
            React.createElement(DataGrid, { rows: rows, columns: columns, pageSize: rows.length, autoHeight: true, disableColumnMenu: true, hideFooter: true, onRowClick: (params) => handleRowClick(params.row.caseID), loading: isDataLoading, components: {
                    LoadingOverlay: !rows.length ? ReviewsDataGridLoader : Base,
                    NoRowsOverlay: !rows.length && isDataLoading ? Base : NoRowsOverlay,
                }, classes: {
                    virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
                }, sx: {
                    border: 'hidden',
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                        display: 'none',
                    },
                } }),
            !!rows.length && isDataLoading && React.createElement(ReviewsDataGridLoader, null)),
        React.createElement(Base, { ref: observerAnchorRef, width: "100%", zIndex: 1, className: css({
                visibility: 'hidden',
            }) })));
};
