import { api } from '../../MissionControlService';
export async function getTemplatesList(params = { limit: 100, offset: 0 }) {
    return await api.get(`/templates/list`, { params }).then((response) => response.data);
}
export async function getTemplateById(id) {
    return await api.get(`/templates/${id}`).then((response) => response.data);
}
/**
 * Temporary endpoint until we have functionality for selecting needed template
 */
export async function getDefaultTemplate() {
    return await api.get('/templates/default').then((response) => response.data);
}
export async function createTemplate(data) {
    return await api.post('/templates/create', data).then((response) => response.data);
}
export async function updateTemplate(templateID, data) {
    return await api.post(`/templates/${templateID}`, data).then((response) => response.data);
}
export async function deleteTemplate(id) {
    return await api.delete(`/templates/${id}`).then((response) => response.data);
}
export async function archiveTemplate(id) {
    return await api.post(`/templates/${id}/archive`).then((response) => response.data);
}
export async function publishTemplate(id) {
    return await api.post(`/templates/${id}/publish`).then((response) => response.data);
}
export async function duplicateTemplate(id) {
    return await api.post(`/templates/${id}/duplicate`).then((response) => response.data);
}
export async function getQuestions(id, params = { limit: 100, offset: 0 }) {
    return await api.get(`/templates/${id}/questions`, { params }).then((response) => response.data);
}
export async function createQuestion(templateID, data) {
    return await api
        .post(`/templates/${templateID}/questions`, data)
        .then((response) => response.data);
}
export async function updateQuestion(templateID, questionID, data) {
    return await api
        .put(`/templates/${templateID}/question/${questionID}`, data)
        .then((response) => response.data);
}
export async function deleteQuestion(templateID, questionID) {
    return await api
        .delete(`/templates/${templateID}/question/${questionID}`)
        .then((response) => response.data);
}
export async function getChoices(templateID, questionID) {
    return await api
        .get(`/templates/${templateID}/question/${questionID}/choices`)
        .then((response) => response.data);
}
export async function createChoice(templateID, questionID, data) {
    return await api
        .post(`/templates/${templateID}/question/${questionID}/choice`, data)
        .then((response) => response.data);
}
export async function updateChoice(templateID, questionID, choiceID, data) {
    return await api
        .put(`/templates/${templateID}/question/${questionID}/choice/${choiceID}`, data)
        .then((response) => response.data);
}
export async function deleteChoice(templateID, questionID, choiceID) {
    return await api
        .delete(`/templates/${templateID}/question/${questionID}/choice/${choiceID}`)
        .then((response) => response.data);
}
