import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from 'lib/cortiUI';
import { GradientStarIcon } from '../components';
import { getReviewScoreByThresholdsColor } from '../utils';
const TEMPLATE_THRESHOLDS = {
    min: 20,
    max: 50,
};
export const ReviewItem = ({ selected = false, showScore = false, onClick, created_by, status_updated_at, status, score_percentage, }) => {
    var _a;
    const theme = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.review' });
    const scorePercentage = Math.floor(score_percentage);
    function getReviewDetails() {
        switch (status) {
            case 'PENDING_TRANSCRIPT':
            case 'PENDING_INTERACTION':
            case 'NONE':
                return {
                    label: t('pendingTranscript', 'Processing...'),
                    icon: React.createElement(TimelapseRoundedIcon, { fontSize: "small" }),
                };
            case 'FAILED':
                return {
                    label: t('failed', 'Failed to process.'),
                    icon: React.createElement(ErrorRoundedIcon, { fontSize: "small" }),
                };
            default:
                return {
                    label: formatDateTime(new Date(status_updated_at)),
                };
        }
    }
    const reviewDetails = getReviewDetails();
    return (React.createElement(ListItemButton, { selected: selected, sx: { pointerEvents: selected ? 'none' : 'auto' }, onClick: onClick, border: `1px solid ${theme.palette.background.divider}`, dense: true },
        React.createElement(ListItemIcon, null, created_by ? React.createElement(PersonRoundedIcon, null) : React.createElement(GradientStarIcon, null)),
        React.createElement(ListItemText, { primary: React.createElement(Typography, { color: "default", variant: "body2" }, (_a = created_by === null || created_by === void 0 ? void 0 : created_by.name) !== null && _a !== void 0 ? _a : 'Corti AI'), secondary: React.createElement(Typography, { display: "flex", alignItems: "center", gap: 2 },
                !!reviewDetails.icon && reviewDetails.icon,
                React.createElement(Typography, { variant: "body2" }, reviewDetails.label)) }),
        showScore && (React.createElement(ListItemIcon, null,
            React.createElement(BarChartRoundedIcon, { sx: {
                    color: getReviewScoreByThresholdsColor(scorePercentage, TEMPLATE_THRESHOLDS, theme.palette),
                } }),
            React.createElement(Typography, null, `${scorePercentage}%`)))));
};
