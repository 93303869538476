import React from 'react';
import { Base } from 'lib/cortiUI';
import { SectionWrapper } from '../../components';
import { Footer } from './Footer';
import { Header } from './Header';
import { Questions } from './Questions';
import { ReviewProvider, ValidationProvider } from './context';
export const ManualAssessment = ({ callID, callStart, callEnd, callUserIDs, }) => {
    return (React.createElement(ReviewProvider, { callID: callID, callStart: callStart, callEnd: callEnd, callUserIDs: callUserIDs },
        React.createElement(ValidationProvider, null,
            React.createElement(Base, { overflow: "hidden", display: "flex", flexDirection: "column" },
                React.createElement(SectionWrapper, { display: "flex", flexDirection: "column", overflow: "hidden", gap: 6 },
                    React.createElement(Header, null),
                    React.createElement(Questions, null)),
                React.createElement(Footer, null)))));
};
