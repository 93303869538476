import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, Checkbox, SettingsCheckInput } from 'lib/cortiUI';
import { useInteraction, useNavigation } from '../../context';
import { Discard } from './Discard';
import { Submit } from './Submit';
export const Footer = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.footer' });
    const theme = useTheme();
    const { setView } = useNavigation();
    const { interaction } = useInteraction();
    const { currentUserID } = useAuth();
    const [isRequestAcknowledgement, setIsRequestAcknowledgement] = React.useState((interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) !== currentUserID &&
        rbacService.missionControlOtherAssessmentsRead());
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    return (React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", background: theme.palette.background.card, p: 7, borderTop: `1px solid ${theme.palette.background.divider}`, gap: 5 },
        (interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) !== currentUserID &&
            rbacService.missionControlOtherAssessmentsRead() && (React.createElement(SettingsCheckInput, { label: t('requestAcknowledgement', 'Request Acknowledgment'), labelInfo: t('requestAcknowledgementInfo', 'When checked, the interaction owner will be notified and acknowledgment will be requested for this interaction.'), inputComponent: React.createElement(Checkbox, { checked: isRequestAcknowledgement, onChange: (_, checked) => setIsRequestAcknowledgement(checked) }), disabled: isSubmitting })),
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 5, ml: "auto" },
            React.createElement(Discard, { onDiscard: () => setView('assessment') }),
            React.createElement(Submit, { onSubmit: () => setView('assessment'), setIsSubmitting: setIsSubmitting, isSubmitting: isSubmitting, isRequestAcknowledgement: isRequestAcknowledgement }))));
};
