import React, { useEffect, useState } from 'react';
import { queueMacrotask } from '@corti/timers';
import { useCortiUIContext } from 'lib/cortiUI';
import { NotificationBody } from './NotificationBody';
import { NotificationHeader } from './NotificationHeader';
import { NotificationProgress } from './NotificationProgress';
import { NotificationWrapper } from './NotificationWrapper';
export function Notification({ notification, defaultDuration, onClose, onActionClick, onExpanded, }) {
    var _a, _b, _c;
    const { theme } = useCortiUIContext();
    const [expanded, setExpanded] = useState(false);
    const [durationProgress, setDurationProgress] = useState(0);
    const showDurationTimer = !notification.permanent ? notification.showDurationTimer : false;
    const duration = !notification.permanent ? (_a = notification.duration) !== null && _a !== void 0 ? _a : defaultDuration : 0;
    const timerIterationCount = duration ? (duration / 1000) * 3 : 0;
    useEffect(() => {
        if (!showDurationTimer || !duration) {
            return;
        }
        const interval = duration / timerIterationCount;
        if (interval <= 0) {
            return;
        }
        const calcDurationProgress = () => {
            const max = 100;
            const increment = max / timerIterationCount;
            setDurationProgress((progress) => Math.min(max, progress + increment));
        };
        calcDurationProgress();
        void queueMacrotask().then(calcDurationProgress);
        const durationTimerId = setInterval(calcDurationProgress, interval);
        return () => clearInterval(durationTimerId);
    }, [duration, showDurationTimer, timerIterationCount]);
    const bgColors = {
        error: theme.palette.error.main,
        info: theme.palette.background.snackbar,
        success: theme.palette.success.main,
        warning: theme.palette.warning.main,
    };
    const textColors = {
        error: theme.palette.error.contrastText,
        info: theme.palette.common.white,
        success: theme.palette.success.contrastText,
        warning: theme.palette.warning.contrastText,
    };
    const bgColor = bgColors[(_b = notification.type) !== null && _b !== void 0 ? _b : 'info'];
    const textColor = textColors[(_c = notification.type) !== null && _c !== void 0 ? _c : 'info'];
    return (React.createElement(React.Fragment, null,
        React.createElement(NotificationWrapper, { type: notification.type, bgColor: bgColor, textColor: textColor, isExpanded: expanded },
            showDurationTimer && (React.createElement(NotificationProgress, { bgColor: bgColor, progress: durationProgress })),
            React.createElement(NotificationHeader, { notification: notification, onClose: onClose, onActionClick: onActionClick, textColor: textColor, isExpanded: expanded, toggleExpand: () => {
                    setExpanded((flag) => !flag);
                    onExpanded();
                } })),
        expanded && React.createElement(NotificationBody, { bgColor: bgColor, text: notification.detailText })));
}
