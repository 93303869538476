import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box, Button } from 'lib/cortiUI';
import * as GQL from './graphql';
export function RemoveTeamMemberView(props) {
    const theme = useTheme();
    const { t } = useTranslation('teams');
    const [deleteTeamMemberMutation, deleteTeamMemberResult] = useMutation(GQL.deleteTeamMemberMutation, {
        variables: { id: props.memberID },
    });
    async function handleDelete() {
        var _a;
        await deleteTeamMemberMutation({
            refetchQueries: [
                {
                    query: GQL.getTeamQuery,
                    variables: {
                        id: props.teamID,
                    },
                },
            ],
        });
        (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
    }
    return (React.createElement(Box, { className: css({ display: 'flex', flexDirection: 'column', gap: theme.space[6] }) },
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 6 },
            React.createElement(Button, { variant: "text", color: "error", onClick: props.onSuccess }, t('removeUserView.cancelBtn', 'Cancel')),
            React.createElement(Button, { "data-cy": "remove-team-member-btn", color: "error", onClick: handleDelete, disabled: deleteTeamMemberResult.loading }, t('removeUserView.removeBtn', 'Remove')))));
}
